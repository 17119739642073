/**
 * CHANGE HOSTNAME AS PER REQUIREMENT
 */
// const hostName = "http://127.0.0.1:4000/"; // localhost
// const hostName = "http://20.185.42.238/captive/"; // Server
// const hostName = "http://20.231.240.75/captive/"; // Docker Container
// const hostName = "http://20.121.111.125:4000/"; //  Docker Container
// const hostName = "https://api.dev.k4mobility.com/captive/"; // Dev URL
 const hostName = "https://api.k4mobility.com/captive/demo/"; // Prod URL
// const hostName = "http://localhost:4000/";


export const SHIP_BASE_URL = hostName + "user_management";

export const API_BASE_URL = SHIP_BASE_URL + "/";

// LOGIN
export const API_LOGIN = "https://api.k4mobility.com/iam/v3/user/login";
export const API_SSO_LOGIN = "https://api.k4mobility.com/iam/v4/user/login";
export const API_GENERATE_OTP = "https://api.k4mobility.com/iam/emailOtp/generate";
export const API_VALIDATE_OTP = "https://api.k4mobility.com/iam/emailOtp/validate";
export const API_ADMIN_ACCESS = "https://api.k4mobility.com/iam/policy/getUserPolicies";
// export const API_LOGIN = hostName + "adminLogin";
export const API_SAVE_ANSWERS_LIST = API_BASE_URL + "securityAnswers";
export const API_FORGOT_PASSWORD = API_BASE_URL + "forgotPassword";
export const API_RESET_PASSWORD = API_BASE_URL + "resetPassword";
export const API_AUTH_LOGIN = hostName + "userLogin";
export const API_AUTH_LOGOUT = hostName + "userLogout";
export const API_ADMIN_RESET_PASSWORD = hostName + "adminResetPassword";
export const API_IS_USER_LOGGED_IN = hostName + "isUserLoggedIn";
export const API_USER_RESET_PASSWORD = hostName + "user/v2/passwordReset";
export const API_RESET_SITE = hostName + "user/v2/resetSite";
export const API_USER_CHANGE_PASSWORD = hostName + "updatePassword"

// SESSIONS CRUD
export const API_GET_SESSIONS = hostName + "device/v2/getSessions";
export const API_GET_USER_SESSION_ANALYTICS = hostName + "device/v2/getActiveDevices";

// USER CRUD
export const API_ADD_EDIT_USER = hostName + "user/v2/manageUsers";
export const API_GET_USER = hostName + "user/v2/getUsersV2";
export const API_DELETE_USER = hostName + "user/v2/deleteUserV2";
export const API_BULK_IMPORT_USERS = hostName + "user/v2/importUsers";
export const API_BULK_IMPORT_PLANS = hostName + "plan/v2/importPlans";
export const API_BULK_DOWNLOAD_USERS = hostName + "user/v2/downloadUsers";
export const API_GET_USER_ALERT = hostName + "device/v2/pendingActions";

//SELF SIGN UP USER
export const API_FETCH_SELF_SIGN_UP_DETAILS = hostName + "user/v2/getAnonymousUserConfiguration";
export const API_SET_SELF_SIGN_UP_DETAILS = hostName + "user/v2/anonymousUserConfiguration";

//USER SIGNUP AND FORGOT PASSWORD
export const API_AUTH_SIGNUP = hostName + "userSignUp";
export const API_GET_SECURITY_QUESTIONS = hostName + "getSecurityQuestions";
export const API_SET_SECURITY_QUESTIONS = hostName + "updateSecurityQuestions";
export const API_AUTH_FORGOT_PASSWORD = hostName + "identityVerification";
export const API_AUTH_PASSWORD_RECOVERY = hostName + "passwordRecovery";
export const API_USER_TRIGGER_RESET_PASSWORD = hostName + "passwordResetRequest";


// PLAN CRUD
export const API_GET_PLANS = hostName + "plan/v2/getPlans";
export const API_ADD_EDIT_PLANS = hostName + "plan/v2/managePlans ";
export const API_DELETE_PLAN = hostName + "plan/v2/deletePlan";

export const API_GET_PLANS_HISTORY = hostName + "userPlan/v2/getPlanHistory";

//export const API_GET_VLANS = "http://10.0.254.1/user_management/getVlanGateways";
export const API_GET_VLANS =
  "http://10.0.254.1/user_management/getVesselDetails";

export const API_GET_USER_DEVICES = hostName + "device/v2/getUserDevices";
export const API_SET_USER_ACCOUNT = hostName + "user/v2/manageLoginAccess";

// INACTIVITY
export const API_GET_GLOBAL_TIMER = hostName + "captiveConfiguration/v2/getGlobalTimer";
export const API_SET_GLOBAL_TIMER = hostName + "captiveConfiguration/v2/setGlobalTimer";
export const API_SET_USER_INACTIVITY_STATUS = hostName + "captiveConfiguration/v2/setUserInactiveStatus";
export const API_SET_GOBAL_DP_TIMER = hostName + "captiveConfiguration/v2/setInactivityTimer";
export const API_GET_DP_INACTIVITY = hostName + "captiveConfiguration/v2/getInactivityTimer";

// Session
export const API_GET_SESSION_DETAILS = hostName + "getSessionDetails";
export const API_GET_SESSION_DETAILS_V2 = hostName + "getSessionDetails_v2";
export const API_GET_GLOBAL_SESSION_REPORT = hostName + "device/v2/userReport";
export const API_GET_REPORT_V3 = hostName + "report/v2/generateReport";

//Report
export const API_GET_REPORTS = hostName + "report/v2/getReport"

// Top-up
export const API_MANAGE_TOP_UP_PLANS = hostName + "plan/v2/manageTopupPlans";

//Reset daily limit
export const API_RESET_DAILY_LIMIT = hostName + "user/v2/resetDailyLimit";
export const API_RESET_PLAN = hostName + "user/v2/resetPlan";
export const API_EXPIRE_PLAN= hostName + "user/v2/expirePlan"

//EVENT TAB APIs
export const API_GET_EVENT = hostName + "user/v2/getNotifications"

//Release Notes
export const API_RELEASE_NOTES = hostName + "releaseNote/fetchReleaseNotes"