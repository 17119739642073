import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./components/auth/Login";
import UserAuthentication from "./components/auth/UserAuthentication";
import HomeScreen from "./components/public/HomeScreen";
import PlanHistory from "./components/public/PlanHistoryPublic";
import { IS_LOGGED_IN } from "./constants/SessionKeys";
import {
  getSessionData,
  saveSessionData,
} from "./managers/session/SessionManager";

import CryptoAES from "crypto-js/aes";
import CryptoENC from "crypto-js/enc-utf8";
import { API_BASE_URL } from "./constants/ApiEndPoints";
// import { API_FETCH_SELF_SIGN_UP_DETAILS } from "./constants/ApiEndPoints";
// import { callGetApi } from "./managers/api/ApiManager";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // faviconSrc: `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData("ADMIN_DP_ID") ? getSessionData("ADMIN_DP_ID") : getSessionData("USER_LOGIN_DP_ID")}/edge/CANfavicon.png?`
      faviconSrc: getSessionData("ADMIN_DP_ID") ? `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData("ADMIN_DP_ID")}/edge/CANfavicon.png?` : API_BASE_URL + "edgeIcon/CANfavicon.png"
      // faviconSrc: API_BASE_URL + "edgeIcon/CANfavicon.png"
    };
    this.fnDisableConsoles();
  }

  isImage = (url) => new Promise((resolve, reject) => {
    const image = new Image();
    image.src = url;
    image.onload = resolve;
    image.onerror = reject;
  });

  componentDidMount() {
    this.fnGetUrlParams();
    if (this.state.faviconSrc != "") {
    const favicon = document.getElementById("favicon");
    this.isImage(this.state.faviconSrc) 
    .then(() => {
      console.log("favIcon-then", this.state.faviconSrc)
      favicon.href = this.state.faviconSrc
    })
    .catch((err) => {
      this.setState({
        faviconSrc: `/public/favicon.ico`
      },()=>{
        favicon.href = this.state.faviconSrc
        console.log("favIcon-catch", this.state.faviconSrc, err)
      })
    })
    console.log("favIcon-final", favicon.href);
    }
  }

  fnDisableConsoles = () => {
    //window.console.log = () => null;
    window.console.table = () => null;
    window.console.warn = () => null;
    window.console.error = () => null;
  };

  fnGetUrlParams = () => {
    let decryptObj = {};
    const url = window.location.href;
    let newUrlString = "";
    let paramString = url.split("?")[1];
    // let queryString = new URLSearchParams(paramString);
    // if (paramString) {
    //   if (queryString.has("dev_ip")) {
    //     localStorage.setItem("DeviceIp", queryString.get("dev_ip"));
    //   }
    //   if (queryString.has("device_id")) {
    //     localStorage.setItem("DeviceID", queryString.get("device_id"));
    //   }
    // }
    if (paramString && url.includes("/authentication")) {
      var _ciphertext = CryptoAES.decrypt(
        paramString.split("details=")[1].toString(),
        "k4mobility_edge"
      );

      let encryptedKey = paramString.split("details=")[1];
      var _ciphertext = CryptoAES.decrypt(
        encryptedKey.toString(),
        "k4mobility_edge"
      );
      let decryptKey = _ciphertext.toString(CryptoENC);
      decryptKey = decryptKey.split("&");
      decryptKey.map((obj) => {
        obj = obj.split("=");
        decryptObj[obj[0]] = obj[1];
      });
      // this._checkCanUserSignUp(decryptObj);
      saveSessionData("DecryptObj", decryptObj);
      console.log("decryptKey", decryptObj);
      newUrlString = url.split("?")[0];
      window.location.replace(newUrlString);
    }
  };

  // _checkCanUserSignUp = (decryptObj) => {
  //   // this.setState({
  //   //   fullPageLoader: true,
  //   // })
  //   let Url = API_FETCH_SELF_SIGN_UP_DETAILS + `?k4Id=${decryptObj ? decryptObj.k4id : ""}`
  //   callGetApi(
  //     "App.js", 
  //     "TAG_FETCH_SELF_SIGN_UP_DETAILS", 
  //     Url
  //   )
  //   .then((res) => {
  //     // this.setState({
  //     //   fullPageLoader: false,
  //     // })
  //     if (res && res.status === 1) {
  //       if (res.data) {
  //         if (res.data.selfSignUp === false) {
  //           saveSessionData("SELF_SIGN_UP_ACCESS", false);
  //         } else {
  //           saveSessionData("SELF_SIGN_UP_ACCESS", true);
  //         }
  //       }
  //     } else {
  //       saveSessionData("SELF_SIGN_UP_ACCESS", false);
  //     }
  //   })
  //   .catch((err)=> {
  //     // this.setState({
  //     //   fullPageLoader: false
  //     // })
  //     saveSessionData("SELF_SIGN_UP_ACCESS", false);
  //     this.fnShowSnackbar("", "error");
  //   })
  // }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/authentication" component={UserAuthentication} />
          <Route exact path="/planHistory" component={PlanHistory} />

          <PrivateRoute path="/" component={HomeScreen} />
          <Route
            path="*"
            render={() => {
              return (
                <div style={{ width: "100vw", height: "100vh" }}>Not Found</div>
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getSessionData(IS_LOGGED_IN) ? (
          props.location.pathname === "/" ? (
            <Redirect
              to={{
                pathname: "/portal",
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
