import { fade } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
// import BG from "../../assets/images/ship-bg-1.png";

export default withStyles((theme) => ({
  root: {
    height: "100vh",
    overflowY: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: `linear-gradient(0deg, rgb(0 0 0 / 60%), rgb(0 0 0 / 60%)), url(${BG})`,
    background: "rgba(20,28,50,255)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",
  },
  cardRoot: {
    width: "674px",
    background: "#FFFFFF",
    boxShadow: "4px 4px 50px rgba(0, 0, 0, 0.5)",

    "@media (max-width:450px)": {
      width: "350px",
    }
  },
  header: {
    background:
      "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
    display: "flex",
    padding: "10px 23px",
    position: "relative",
    "@media (max-width:450px)": {
      padding: "10px 10px",
    }
  },
  headerText: {
    color: "#FFFFFF",
    fontSize: "16px",
    // fontWeight: 600,
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "60%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    "@media (max-width:450px)": {
      marginLeft: "30px",
      fontSize: "14px",
      width: "80%",
    }
  },
  imgStyle: {
    width: "12%",
    "@media (max-width:450px)": {
      width: "17%",
    }
  },
  bodyContainer: {
    padding: "41px 142px",
    "@media (max-width:450px)": {
      padding: "41px 20px",
    }
  },
  bodyContainerSQ: {
    padding: "41px 100px",
    "@media (max-width:450px)": {
      padding: "41px 20px",
    }
  },
  innerDialogBody: {
    padding: "22px 0px",
  },
  inputBox: {
    marginBottom: "12px",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between"
  },
  bodyContainerSignUp: {
    padding: "31px 142px 10px",
    "@media (max-width:450px)": {
      padding: "31px 20px",
    }
  },
  paperSignIn: {
    minWidth: "450px",
    maxWidth: "534px",
    "@media (max-width:450px)": {
      minWidth: "200px",
      maxWidth: "400px",
    }
    // minHeight: "258px",
  },
  dialogBody: {
    padding: "20px 25px",
  },
  boldText: {
    color: "#3E468C",
    fontWeight: 600,
  },
  lightText: {
    color: "#3E468C",
    marginTop: "10px"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    marginTop: "24px",
  },
  darkBtn: {
    background: "#384089",
    borderRadius: "5px",
    padding: "4px 15px",
    cursor: "pointer",
  },
  darkBtnText: {
    color: "#ffffff",
    fontSize: "12px",
  },
  innerSignUpDialogBody: {
    padding: "10px 0px",
  },
  inputContainerSignUp: {
    background: "#F5F5F5",
    borderBottom: "1px solid #2E51D2",
    width: "100%",
  },
  inputLabel: {
    color: "#384089",
    fontSize: "12px",
    fontWeight: 600,
  },
  bodyHeading: {
    color: "#384089",
    fontSize: "16px",
    fontWeight: 600,
  },
  bodySection: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    textDecoration: "underline",
    textDecorationColor: "#384089"
  },
  credentialsBox: {
    marginTop: "39px",
    background: "#C4C4C426",
    padding: "31px 66px",
    borderRadius: "8px",
  },
  inputContainer: {
    // background: "#F5F5F5",
    borderBottom: "1px solid #384089",
    width: "100%",
    marginTop: "15px"
  },
  errorMessage: {
    color: "#FF0808",
    fontSize: "10px",
  },
  label: {
    color: "#384089",
    fontSize: "0.875rem"
  },
  termsConditionsBox: {
    border: "1px solid #D3D3D3",
    borderRadius: "4px",
    marginTop: "20px",
    padding: "10px 15px",
    height: "87px",
    overflowY: "auto",
  },
  termsHeader: {
    color: "#000000CC",
    fontSize: "10px",
    fontWeight: 600,
  },
  term: {
    fontSize: "10px",
    color: "#000000",
    marginTop: "16px",
  },
  connectContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "32px",
    position: "relative"
  },
  connectButton: {
    background: "#384089",
    borderRadius: "5px",
    padding: "8px 18px",
    cursor: "pointer",
  },
  connectText: {
    color: "#FFFFFF",
    fontSize: "12px",
  },
  disabledConnectBtn: {
    background: "#C4C4C4",
    borderRadius: "5px",
    padding: "8px 18px",
    cursor: "not-allowed",
  },
  loginLoader: {
    color: fade(theme.palette.primary.main, 1),
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
}));
