import axios from "axios";
import { logData } from "./LogManager";
import { ACCESS_TOKEN, IS_LOGGED_IN, USER_DATA } from "../../constants/SessionKeys";
import * as SessionManager from "../session/SessionManager";

require("dotenv").config();

axios.defaults.timeout = 50000;

const crypto = require('crypto');
// const secretKey = process.env.SECRET_KEY;
const secretKey = 'd}#bMiD72zI#o3Y';

console.log("Checking crypto", secretKey);

export const callPostApi = async (
  componentName,
  API_TAG,
  URL,
  bodyParams,
  timeout = null,
  LTEParentChildFlag = false,
  cancelFunc = null
) => {

  let tempBodyParams = JSON.stringify(bodyParams);
  const hmac = crypto.createHmac('sha256', secretKey);
  hmac.update((tempBodyParams));
  let hash = hmac.digest('hex');

  let returnObj = await refreshTokenIfRequired(URL).then( async resp => {
  try {
    let NEW_URL = URL;
    if (LTEParentChildFlag === true) {
      let LTEContSelectedComp = SessionManager.getSessionData(
        "LTE_CONTROLLER_SELECTED_COMP"
      )
        ? SessionManager.getSessionData("LTE_CONTROLLER_SELECTED_COMP")
        : 0;
      if (
        (LTEContSelectedComp && LTEContSelectedComp === 1) ||
        LTEContSelectedComp === "1"
      ) {
        // NEW_URL = NEW_URL.substring(0, GetSubstringIndex(NEW_URL,'/',2)+1) + "192.168.2.1" + NEW_URL.substring(GetSubstringIndex(NEW_URL,'/',3)); // SECONDARY 1
        if (NEW_URL.includes("user_management")) {
          NEW_URL = NEW_URL.replace("user_management", "user_management_c1");
        } else if (NEW_URL.includes("lte_management")) {
          NEW_URL = NEW_URL.replace("lte_management", "lte_management_c1");
        }
      } else if (
        (LTEContSelectedComp && LTEContSelectedComp === 2) ||
        LTEContSelectedComp === "2"
      ) {
        // NEW_URL = NEW_URL.substring(0, GetSubstringIndex(NEW_URL,'/',2)+1) + "192.168.3.1" + NEW_URL.substring(GetSubstringIndex(NEW_URL,'/',3)); // SECONDARY 2
        if (NEW_URL.includes("user_management")) {
          NEW_URL = NEW_URL.replace("user_management", "user_management_c2");
        } else if (NEW_URL.includes("lte_management")) {
          NEW_URL = NEW_URL.replace("lte_management", "lte_management_c2");
        }
      }
    }
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: NEW_URL,
      HEADER: getHeader(hash),
      BODY: JSON.stringify(bodyParams),
    });

    console.log(JSON.stringify(bodyParams));
    let config = {
      // withCredentials: true
      headers: getHeader(hash),
      // withCredentials: true
    };
    if (timeout) {
      config.timeout = Number(timeout);
    }
    let apiResponse = await axios.post(NEW_URL, JSON.stringify(bodyParams), {
      ...config,
      cancelToken: cancelFunc,
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (
      apiResponse.data.success === false &&
      (apiResponse.data.message === "timeout" ||
        apiResponse.data.message === "Token is not valid")
    ) {
      SessionManager.clearSession();
      alert(`Session timed out. Please sign-in again`);
      window.location.reload();
      return;
    }
    return apiResponse.data;
  } catch (e) {
    console.log("POST Request Cancelling::::", e);

    // if (e.response && e.response.status === 401) {
    //   SessionManager.saveSessionData(IS_LOGGED_IN, false);
    //   SessionManager.clearSession();
    //   window.location.replace("/login");
    //   return;
    // }

    if (axios.isCancel(e)) {
      console.log("Request Cancelled::::", e);
      return { status: "Cancelled", message: "Request Cancelled" };
    } else if (e.code === "ECONNABORTED") {
      return { status: "TimeOut", message: "Request Timed Out" };
    } else {
      logData(
        API_TAG,
        JSON.stringify({
          error: e,
        })
      );

      return e.response ? e.response.data : null;
    }
  }
  }).catch( (err_resp) => {
    console.log("Err in res", err_resp)
    SessionManager.saveSessionData(IS_LOGGED_IN, false);
    window.location.replace("/login");
    localStorage.setItem('logout-event', 'logout' + Math.random());
    return;
  })

  return returnObj;
};

export const callPostApiWithoutToken = async (
  componentName,
  API_TAG,
  URL,
  bodyParams
) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    console.log(JSON.stringify(bodyParams));

    let apiResponse = await axios.post(URL, JSON.stringify(bodyParams), {
      // withCredentials: true
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    if (
      apiResponse.data.success === false &&
      (apiResponse.data.message === "timeout" ||
        apiResponse.data.message === "Token is not valid")
    ) {
      SessionManager.clearSession();
      alert(`Session timed out. Please sign-in again`);
      window.location.reload();
      return;
    }
    return apiResponse.data;
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return null;
  }
};

export const callPutApi = async (componentName, API_TAG, URL, bodyParams) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    console.log(JSON.stringify(bodyParams));

    let apiResponse = await axios.put(URL, JSON.stringify(bodyParams), {
      // withCredentials: true
      headers: getHeader(),
      // withCredentials: true
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    console.log("******RESPOND", apiResponse);

    if (
      apiResponse.data.success === false &&
      (apiResponse.data.message === "timeout" ||
        apiResponse.data.message === "Token is not valid")
    ) {
      SessionManager.clearSession();
      alert(`Session timed out. Please sign-in again`);
      window.location.reload();
      return;
    }
    return apiResponse.data;
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );

    return null;
  }
};

export const callDeleteApi = async (
  componentName,
  API_TAG,
  URL,
  bodyParams
) => {
  try {
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: URL,
      HEADER: getHeader(),
      BODY: JSON.stringify(bodyParams),
    });

    console.log(JSON.stringify(bodyParams));

    let apiResponse = await axios.delete(URL, {
      headers: getHeader(),
      data: JSON.stringify(bodyParams),
    });

    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    console.log("******RESPOND", apiResponse);

    if (
      apiResponse.data.success === false &&
      (apiResponse.data.message === "timeout" ||
        apiResponse.data.message === "Token is not valid")
    ) {
      SessionManager.clearSession();
      alert(`Session timed out. Please sign-in again`);
      window.location.reload();
      // return;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    logData(
      API_TAG,
      JSON.stringify({
        error: e,
      })
    );
    return null;
  }
};

export const callGetApi = async (
  componentName,
  API_TAG,
  URL,
  LTEParentChildFlag = false,
  cancelFunc = null
) => {
  let base_url = "https://api.k4mobility.com/captive/demo";
  let tempBodyParams = URL.toString();
  tempBodyParams = tempBodyParams.replace(base_url,"");
  const hmac = crypto.createHmac('sha256', secretKey);
  hmac.update((tempBodyParams));
  let hash = hmac.digest('hex');
  
  let returnObj = await refreshTokenIfRequired(URL).then( async resp => {
  try {
    let NEW_URL = URL;
    if (LTEParentChildFlag === true) {
      let LTEContSelectedComp = SessionManager.getSessionData(
        "LTE_CONTROLLER_SELECTED_COMP"
      )
        ? SessionManager.getSessionData("LTE_CONTROLLER_SELECTED_COMP")
        : 0;
      if (
        (LTEContSelectedComp && LTEContSelectedComp === 1) ||
        LTEContSelectedComp === "1"
      ) {
        // NEW_URL = NEW_URL.substring(0, GetSubstringIndex(NEW_URL,'/',2)+1) + "192.168.2.1" + NEW_URL.substring(GetSubstringIndex(NEW_URL,'/',3)); // SECONDARY 1
        if (NEW_URL.includes("user_management")) {
          NEW_URL = NEW_URL.replace("user_management", "user_management_c1");
        } else if (NEW_URL.includes("lte_management")) {
          NEW_URL = NEW_URL.replace("lte_management", "lte_management_c1");
        }
      } else if (
        (LTEContSelectedComp && LTEContSelectedComp === 2) ||
        LTEContSelectedComp === "2"
      ) {
        // NEW_URL = NEW_URL.substring(0, GetSubstringIndex(NEW_URL,'/',2)+1) + "192.168.3.1" + NEW_URL.substring(GetSubstringIndex(NEW_URL,'/',3)); // SECONDARY 2
        if (NEW_URL.includes("user_management")) {
          NEW_URL = NEW_URL.replace("user_management", "user_management_c2");
        } else if (NEW_URL.includes("lte_management")) {
          NEW_URL = NEW_URL.replace("lte_management", "lte_management_c2");
        }
      }
    }
    logData(API_TAG, {
      COMPONENT_NAME: componentName,
      API_URL: NEW_URL,
      HEADER: getHeader(hash),
      BODY: {},
    });
    let apiResponse = await axios.get(NEW_URL, {
      headers: getHeader(hash),
      cancelToken: cancelFunc,
    });
    logData(
      API_TAG,
      JSON.stringify({
        response: apiResponse.data,
      })
    );
    console.log("******RESPOND", apiResponse);

    if (
      apiResponse.data.success === false &&
      (apiResponse.data.message === "timeout" ||
        apiResponse.data.message === "Token is not valid")
    ) {
      SessionManager.clearSession();
      alert(`Session timed out. Please sign-in again`);
      window.location.reload();
      // return;
    } else {
      return apiResponse.data;
    }
  } catch (e) {
    console.log("GET Request Cancelling::::", e);

    // if (e.response && e.response.status === 401) {
    //   SessionManager.saveSessionData(IS_LOGGED_IN, false);
    //   SessionManager.clearSession();
    //   window.location.replace("/login");
    //   return;
    // }

    if (axios.isCancel(e)) {
      console.log("Request Cancelled::::", e);
      return { status: "Cancelled", message: "Request Cancelled" };
    } else {
      logData(
        API_TAG,
        JSON.stringify({
          error: e,
        })
      );
      return null;
    }
  }
  }).catch( (err_resp) => {
    console.log("Err in res", err_resp)
    SessionManager.saveSessionData(IS_LOGGED_IN, false);
    window.location.replace("/login");
    localStorage.setItem('logout-event', 'logout' + Math.random());
    return;
  })

  return returnObj;
};

export const getHeader = (hashToken = "") => {
  let accessToken = SessionManager.getSessionData(ACCESS_TOKEN);
  //console.log("****ACCESS_TOKEN", accessToken);
  let authToken = accessToken ? "Bearer " + accessToken : "Bearer ";
  let payloadSignature = hashToken ? hashToken : ""
  if (accessToken === null || accessToken === undefined) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: authToken,
      // "payload-signature": payloadSignature
    };
  }
};

export const GetSubstringIndex = (str, substring, n) => {
  var times = 0,
    index = null;

  while (times < n && index !== -1) {
    index = str.indexOf(substring, index + substring.length);
    times++;
  }

  return index;
};

const refreshTokenIfRequired = (URL = "") => {
  return new Promise( async ( resolve, reject ) => {
    let userData = SessionManager.getSessionData(USER_DATA);
    let RejectCaseCheck = SessionManager.getSessionData("HANDLE_OTHER_REJECT_CASE")
    let tokenCaseCheck = SessionManager.getSessionData("TOKEN_EXPIRED")
    console.log("Userdata on: ", userData)

    // debugger
    // console.log("Reject CASE", RejectCaseCheck, URL)
    if ( userData && userData.username ) {
      console.log("Userdata on: ", userData)
      userData = userData.username;
      let re = /\S+@\S+\.\S+/;
      if ( !re.test(userData) ) {
        console.log("Not Email")
        resolve()
      } else if (RejectCaseCheck === null) {
        let idToken = SessionManager.getSessionData("ID_TOKEN");
        // let idToken = "eyJ4NXQiOiJNell4TW1Ga09HWXdNV0kwWldObU5EY3hOR1l3WW1NNFpUQTNNV0kyTkRBelpHUXpOR00wWkdSbE5qSmtPREZrWkRSaU9URmtNV0ZoTXpVMlpHVmxOZyIsImtpZCI6Ik16WXhNbUZrT0dZd01XSTBaV05tTkRjeE5HWXdZbU00WlRBM01XSTJOREF6WkdRek5HTTBaR1JsTmpKa09ERmtaRFJpT1RGa01XRmhNelUyWkdWbE5nX1JTMjU2IiwiYWxnIjoiUlMyNTYifQ.eyJhdF9oYXNoIjoiaUFENWplWl93LTdNcm1MeDhWbkxnZyIsImF1ZCI6InFid1ZlVWNQekxxQmNBemRGV3lYcFJuUVMwTWEiLCJzdWIiOiJyYWh1bC5rdW1hckB0cnVtaW5kcy5jb20iLCJuYmYiOjE2NDk0MTI3MjAsImF6cCI6InFid1ZlVWNQekxxQmNBemRGV3lYcFJuUVMwTWEiLCJhbXIiOlsicGFzc3dvcmQiXSwiaXNzIjoiaHR0cHM6XC9cL3dzbzI6OTQ0M1wvb2F1dGgyXC90b2tlbiIsImV4cCI6MTY0OTQxNjMyMCwiaWF0IjoxNjQ5NDEyNzIwfQ.Qlg-zjRKt7TuVokV-7l3VcVZ3EYB_imcd1RNz2tCkODrRLVG_rGjxSXye4O-4t9L_wuNmer704TWa-4e7pb_iUZh65tWuVxKn0MpIpU-nlqu_uQWbwjKV3R4OOwWGFAvwDHqypXY7pjI5owwWF1qsYFDDv1iki_z1fvl26ozSOmnoW9uaS1SyLXBfzvMr_XTxrdQXH8y0KUgFDfXTIFvbil_7tikVSPwrlYJIwXUrlws32w0iw8OWgAySnty48MqcqJrrsP1tMqPU_JE_O1ciSLuLQDaBx3dzEGHTKwVyCYuaNeHv2uWxwSYfGB03yuLIG-RjBjgxIHWzT1mR3wTrQ";
        // debugger
        if ( idToken ) {
          if (localStorage.getItem("HANDLE_OTHER_REJECT_CASE"))  localStorage.removeItem("HANDLE_OTHER_REJECT_CASE");
          let base64Decode = Buffer.from(idToken.split(".")[1], "base64");
          let decodedJWT = JSON.parse(base64Decode.toString())
          if ( decodedJWT && "exp" in decodedJWT ) {
            console.log("exp", idToken)
            if ( decodedJWT.exp * 1000 < Date.now() ) {
              let refreshToken = SessionManager.getSessionData("REFRESH_TOKEN");
              // let refreshToken = "a8fa369f-bc58-33ae-ba30-4f3aa412e479";
              if ( refreshToken ) {
                let payload = { 'refreshToken': refreshToken };
                let headers =  { "Content-Type": "application/json" };
                try {
                  let resIAMAPI = await axios({
                    url: "https://api.k4mobility.com/iam/token",
                    method: "POST",
                    headers: Object.assign({}, headers),
                    data: payload
                  });
                  if (localStorage.getItem("TOKEN_EXPIRED"))  localStorage.removeItem("TOKEN_EXPIRED");
                  console.log("resIAMAPI ", resIAMAPI)
                  if ( resIAMAPI && resIAMAPI.data && resIAMAPI.data.message && resIAMAPI.data.message.message ) {
                    let respData = JSON.parse(resIAMAPI.data.message.message);
                    if ( respData && "access_token" in respData && "id_token" in respData && "refresh_token" in respData ) {
                      SessionManager.saveSessionData(
                        ACCESS_TOKEN,
                        respData.access_token ? respData.access_token : null
                      );
                      SessionManager.saveSessionData(
                        "ID_TOKEN",
                        respData.id_token ? respData.id_token : null
                      );
                      SessionManager.saveSessionData(
                        "REFRESH_TOKEN",
                        respData.refresh_token ? respData.refresh_token : null
                      );
                      console.log("Final res")
                      resolve();
                    } else {
                      console.log("Invalid resp json data")
                      reject("DisplaySnackbar")
                      SessionManager.saveSessionData("HANDLE_OTHER_REJECT_CASE", true)
                      //Session expired, please login again
                    }
                  } else {
                    console.log("Invalid IAM API res")
                    reject("DisplaySnackbar");
                    SessionManager.saveSessionData("HANDLE_OTHER_REJECT_CASE", true)
                    //Session expired, please login again
                  }
                } catch( api_err ) {
                  if (tokenCaseCheck === null) {
                    console.log("IAM token fetch API failed")
                    reject("TokenExpiredNoInternetCase");
                    SessionManager.saveSessionData("TOKEN_EXPIRED", true)
                  }
                }
              } else {
                console.log("Refresh token is not available")
                reject("DisplaySnackbar")
                SessionManager.saveSessionData("HANDLE_OTHER_REJECT_CASE", true)
                //Session expired, please login again
              }
            } else {
              console.log("id_token is valid")
              resolve()
            }
          } else {
            console.log("id_token expiration is not available")
            reject("DisplaySnackbar")
            SessionManager.saveSessionData("HANDLE_OTHER_REJECT_CASE", true)
            // Session expired, please login again
          }
        } else {
          console.log("Invalid or Null id_token")
          reject("DisplaySnackbar")
          SessionManager.saveSessionData("HANDLE_OTHER_REJECT_CASE", true)
          //Session expired, please login again
        }
      } else {
        resolve()
      }
    } else {
      console.log("Login didn't happen")
      resolve()
    }
  })
}
