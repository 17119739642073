import { withStyles } from "@material-ui/styles";

export default withStyles((theme) => ({
  logotype: {
    color: "#000000",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appBar: {
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
    background:
      "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  gridContainer: {
    position: "relative",
  },
  leftGridItem: { position: "absolute", left: 10 },
  imageBox: {
    width: "136px",
    height: "40px",
    backgroundColor: "#FFFFFF",
    borderRadius: "3px",
  },
  logo: { width: "100px", height: "40px" },
  dropContainer: {
    background: "#384089",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    width: "238px",
  },
  dropItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "10px 50px 8px 12px",
    backgroundColor: "#384089",
  },
  dropItemText: {
    color: "#fff",
    fontSize: "1rem",
  },
  headerText: {
    fontSize: "1.5rem",
    "@media (max-width:450px)": {
      fontSize: "1rem",
    },
  },
  headerContainer2: {
    paddingLeft: "15px",
    paddingRight: "10px",
    height: "40px",
    background:
      "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
  },
  headingText: {
    color: "rgba(0,0,0,0.8)",
    fontWeight: 600,
    fontSize: "1rem",
  },
  dialogText: {
    color: "rgba(0,0,0,0.8)",
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  dialog: {
    position: "absolute",
    right: 0,
    top: 20,
  },
  sysInfoDialog: {
    position: "absolute",
    right: 0,
    top: 20,
    width: "470px",
  },
  paper1: {
    minWidth: "400px",
    maxWidth: "600px",
    maxHeight: "300px",
    color: "#FFFFFF",
  },
  paper2: {
    // height:"100%",
    // width:"auto",
    color: "#FFFFFF",
  },
  headerStyle: {
    padding: "12px",
    background:
      "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
  },
  closeIconStyle: {
    color: "white",
    cursor: "pointer",
    background: "rgba(255, 255, 255, 0.1)",
    marginRight: "3px",
    borderRadius: "20px",
  },
  dialogText1: {
    fontWeight: 600,
    lineHeight: "30px",
    color: "#3E468C",
    fontSize: "1.2rem",
  },
  dialogBottom: {
    padding: "5px",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "30px",
  },
  dialogBottomContext: {
    fontSize: "14px",
    lineHeight: "19px",
    color: "rgba(0, 0, 0, 0.8)",
    fontWeight: 600,
    marginRight: "20px",
  },
  dialogButton: {
    backgroundColor: "#384089",
    color: "white",
    textTransform: "none",
    fontSize: "14px",
    lineHeight: "19px",
  },
  dialogText2: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#3E468C",
  },
  dialogBottom2: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "rgba(0, 0, 0, 0.8)",
    fontWeight: 600,
  },
  failText1: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "25px",
    color: "#3E468C",
  },
  rebootText: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-evenly",
    fontWeight: 600,
    color: "#3E468C",
  },
  logoMobileView: {
    "@media (max-width:450px)": {
      height: "30px",
    },
  },
  header: {
    display: "flex",
    cursor: "not-allowed",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  header1: {
    display: "none",
    cursor: "not-allowed",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  header2: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dropContainer2: {
    background: "white",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    width: "750px",
    height: "500px"
  },
  releaseHeader: {
    position: "sticky",
    top: "0px",
    backgroundColor: "#384089",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 10px 0px 24px"
  },
  scrollContainer: {
    overflowY: "auto",
  },
}));
