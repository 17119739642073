import React, { Component, version } from "react";
import {
  AppBar,
  Toolbar,
  MenuItem,
  Grid,
  Typography,
  Popover,
  useMediaQuery,
  IconButton,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import {
  clearSession,
  getSessionData,
  saveSessionData,
} from "../../managers/session/SessionManager";

import { IS_LOGGED_IN } from "../../constants/SessionKeys";

import withStyles from "../styles/HeaderScreen";
import {
  API_AUTH_LOGOUT,
  API_BASE_URL,
  API_USER_CHANGE_PASSWORD,
  API_RELEASE_NOTES,
} from "../../constants/ApiEndPoints";
import { callPostApi } from "../../managers/api/ApiManager";
import axios from "axios";
import moment from "moment";
import { useTheme } from "@material-ui/styles";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import NewReleasesIcon from "@material-ui/icons/NewReleases";

const logo = require("../../assets/images/k4-header-logo.png");
const menuIcon = require("../../assets/images/toolbar-dot-menu.png");

// simpleQuery = () => {
//   const matches = useMediaQuery("(min-width:600px)");

//   return <span>{`(min-width:600px) matches: ${matches}`}</span>;
// };

//import { unstable_useMediaQuery as useMediaQuery } from "@material-ui/core/useMediaQuery";
//import { useTheme } from "@material-ui/styles";

// function MyComponent() {
//   const theme = useTheme();
//   const matches = useMediaQuery(theme.breakpoints.up("sm"));

//   return <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>;
// }

function MyComponent() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  if (matches) {
    return (
      <Grid
        item
        xl={4}
        lg={4}
        md={4}
        // sm={0}
        // xs={0}
        // xs={12}
        // sm={12}
        style={
          {
            // display: title && title.includes("Plan Details") ? "none" : "",
            // backgroundColor: "cyan",
          }
        }
      >
        <Typography
          variant="h6"
          //className={classes.headerText}
        >
          Administration Portal
          {/* {title ? title : "Administration Portal"} */}
        </Typography>
      </Grid>
    );
  }

  return <span>{`theme.breakpoints.up('sm') matches: ${matches}`}</span>;
}

class Header extends Component {
  refreshInterval;
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      currentTime: moment()
        .utc()
        .format("MMM-DD-YYYY HH:mm"),
      username: getSessionData("ADMIN_NAME"),
      showHideSnackbar: {
        message: "",
        variant: "",
        show: false,
      },
      showPasswordSuccessMsg: {
        message: "",
        title: "",
        show: false,
      },
      headerLogoSrc: getSessionData("ADMIN_DP_ID")
        ? `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
            "ADMIN_DP_ID"
          )}/edge/CANheaderLogo.png?`
        : API_BASE_URL + "edgeIcon/CANheaderLogo.png",
      // headerLogoSrc: require(`../../assets/images/headerLogo.png`),
      // headerBglogo: getSessionData("ADMIN_DP_ID") ? `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData("ADMIN_DP_ID")}/edge/CANheaderBgLogo.png?` : "",
      headerBglogo: "",
      imageDimensions: {},
      releaseAnchor: null,
      releasePrev1: false,
      releasePrev2: false,
      currentReleaseNotes: {
        version: "",
        releaseDate: "",
        sessions: [],
        users: [],
        plans: [],
        reports: [],
        events: [],
        generic: [],
      },
      prevReleaseNotes: {},
      expandedNotes: {},
      releaseDateCheck: "",
      colorReleaseCheck: false
      // md5sumJson: `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData("DP_ID") ? getSessionData("DP_ID") : getSessionData("USER_LOGIN_DP_ID")}/edge/assets.md5sum.json?`
    };
  }

  componentDidMount = () => {
    //Check for headerLogo dimensions.
    this.fnGetReleaseNotes();
    const img = new Image();
    img.src = this.state.headerLogoSrc;
    console.log("loginLogo", img.src, img.height, img.width);

    img.onload = () => {
      this.setState({
        imageDimensions: {
          height: img.height,
          width: img.width,
        },
      });
      console.log("loginLogo", this.state.imageDimensions);
    };
    img.onerror = (err) => {
      this.setState({
        imageDimensions: {
          height: 40,
          width: 57,
        },
      });
      console.log("img error");
      console.error(err);
    };

    this._refreshInterval = setInterval(() => {
      this.setState({
        currentTime: moment()
          .utc()
          .format("MMM-DD-YYYY HH:mm"),
      });
    }, 10000);
    let backgroundLogo =
      img && img.width && img.width != 57
        ? `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
            "ADMIN_DP_ID"
          )}/edge/headerBgLogo.png?`
        : `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
            "ADMIN_DP_ID"
          )}/edge/CANheaderBgLogo.png`;
    this.isBgImage(backgroundLogo)
      .then(() => {
        console.log("Src-then", this.state.headerBglogo);
        this.setState({
          headerBglogo: backgroundLogo,
        });
      })
      .catch((err) => {
        this.setState({
          headerBglogo: "",
        });
        console.log("Src-catch", this.state.headerBglogo, err);
      });
    window.addEventListener("storage", (event) => {
      if (event.key == "logout-event") {
        window.location.replace("/login");
      }
    });
  };

  isBgImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = resolve;
      image.onerror = reject;
    });

  componentWillUnmount() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  // startmd5suminterval = () => {
  //   this._refreshinterval = setInterval(() => {
  //     this.fnFetchmd5sumJson();
  //   }, 60 * 60 * 1000);
  // }

  // fnFetchmd5sumJson = () => {
  //   let md5sumData = getSessionData("CURRENT_MD5SUM") ? getSessionData("CURRENT_MD5SUM") : null;
  //   fetch(this.state.md5sumJson)
  //     .then((response) => response.json())
  //     .then((responseJson) => {
  //       console.log("jsonData", responseJson)
  //       for (var i = 0; i < responseJson.assets.length; i++) {
  //         if (md5sumData !== null && md5sumData.find(oldAsset => oldAsset.md5sum === responseJson.assets[i].md5sum)) {
  //           // Map through the new asset list to check if all new assets is found in old Asset, else refresh.
  //         } else {
  //           Window.location.reload();
  //           break;
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("jsonDataCatch", error)
  //       console.error(error);
  //     });
  // }

  fnOpenCloseMenu = (currentTarget) => {
    this.setState({ menuAnchor: currentTarget });
  };

  convertDateFormat = (dateStr) => {
    const months = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04',
      May: '05', Jun: '06', Jul: '07', Aug: '08',
      Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
    const [day, month, year] = dateStr.split('-');
    const formattedYear = `20${year}`;
    return `${formattedYear}-${months[month]}-${day.padStart(2, '0')}`;
  };

  changePwd = (open) => {
    this.setState({ showChangePasswordDialog: { open }, menuAnchor: null });
  };

  closeChangePassword = () => {
    this.setState({ showChangePasswordDialog: { open: false } });
  };

  handleChangePassword = (payload) => {
    this.props.showLoader(true);
    callPostApi(
      "UserAuthentication.jsx",
      "API_USER_CHANGE_PASSWORD",
      API_USER_CHANGE_PASSWORD,
      payload
    )
      .then((res) => {
        this.props.showLoader(false);
        if (res && res.status === 1) {
          this.setState({
            showChangePasswordDialog: { open: false },
            showPasswordSuccessMsg: {
              open: true,
              message: "Password successfully updated!",
              title: "Change Password",
            },
          });
        } else {
          this.setState({
            showPasswordSuccessMsg: {
              open: true,
              message: "Password updation failed",
              title: "Change Password",
            },
          });
        }
      })
      .catch((err) => {
        this.fnOpenCloseMenu(null);
        this.props.showLoader(false);
        this.setState({
          showPasswordSuccessMsg: {
            open: true,
            message: "Password updation failed",
            title: "Change Password",
          },
        });
      });
  };

  handleMessageClose = () => {
    this.setState({ showPasswordSuccessMsg: { open: false } });
  };

  fnLogout = () => {
    console.log("this.props.history :>> ", this.props);

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from === "AuthUser"
    ) {
      // this.fnOpenCloseMenu(null);
      // localStorage.removeItem("NON_ADMIN_USER_INFO");
      // this.props.history.replace("/authentication");
      this.fnCallLogoutApi();
    } else {
      this.fnOpenCloseMenu(null);
      saveSessionData(IS_LOGGED_IN, false);
      clearSession();
      this.props.history.replace("/login");
    }
  };

  fnCallLogoutApi = () => {
    let payload = {};
    let userInfo = getSessionData("NON_ADMIN_USER_INFO");
    // let deviceIp = localStorage.getItem("DeviceIp");
    let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;
    payload.userRecId = userInfo.userRecId;
    payload.ipAddress = userInfo.ipAddr;
    payload.macAddress =
      decryptObj && decryptObj.mac ? decryptObj.mac.toUpperCase() : "";
    this.props.showLoader(true);

    callPostApi(
      "UserAuthentication.jsx",
      "TAG_API_AUTH_LOGOUT",
      API_AUTH_LOGOUT,
      payload
    )
      .then((res) => {
        this.props.showLoader(false);
        if (res && res.status === 1) {
          localStorage.removeItem("NON_ADMIN_USER_INFO");
          localStorage.removeItem("AUTH_FAIL_MESSAGE");
          window.location.replace(
            "http://10.0.254.1/user_management/getAccess"
          );
          // this.props.history.replace("/authentication");
          this.fnOpenCloseMenu(null);
        } else {
          this.fnOpenCloseMenu(null);
          this.props.fnShowSnackbar("Logout failed.", "error");
          // this.fetchEdgeAPItoRemovePolicy(userInfo.ipAddr);
          // setTimeout(() => {
          //   window.location.replace("http://10.0.254.1/user_management/getAccess")
          // }, 2000);
        }
      })
      .catch((err) => {
        this.fnOpenCloseMenu(null);
        this.props.showLoader(false);
        this.props.fnShowSnackbar("Logout failed.", "error");
        console.log("err :>> ", err);
      });

    // setTimeout(() => {
    // this.fetchEdgeAPItoRemovePolicy(userInfo.ipAddr);
    // setTimeout(() => {
    //   this.props.showLoader(false);
    //   localStorage.removeItem("NON_ADMIN_USER_INFO");
    //   localStorage.removeItem("AUTH_FAIL_MESSAGE");
    //   // window.location.replace("http://10.0.254.1/user_management/getAccess")
    // }, 5000);
    // }, 2000);
  };

  fetchEdgeAPItoRemovePolicy = (ip) => {
    // let URL = "http://10.0.254.1/user_management/updateUserdeviceIPpolicy";
    let payload = {
      ip_address: ip,
    };


    fetch("http://10.0.254.1/user_management/updateUserdeviceIPpolicy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // referrerPolicy: "unsafe_url",
      body: JSON.stringify({ ip_address: ip }),
    })
      .then((response) => response.json())
      .then((data) => console.log("RemovePolicyResponse", data))
      .catch((error) => console.error("RemovePolicyResponseErr", error));

    // axios.post(URL, JSON.stringify(payload))
    // .then((res) => {
    //   console.log("RemovePolicyResponse", res)
    // })
    // .catch((err) => {
    //   console.log("RemovePolicyResponseErr", err)
    // })
    // callPostApi(
    //   "UserAuthentication.jsx",
    //   "TAG_API_AUTH_LOGOUT",
    //   URL,
    //   payload
    // )
    // .then((res) => {})
    // .catch((err) => {

    // })
  };

  fnOpenCloseReleaseMenu = (currentTarget) => {
    this.setState({ releaseAnchor: currentTarget });
  };

  handleCloseIcon = () => {
    this.setState({ releaseAnchor: null });
  };

  fnGetReleaseNotes = () => {
    callPostApi("", "", API_RELEASE_NOTES, {})
      .then((res) => {
        if (res && res.status === 1 && res.msg) {
          const releaseInfo = res.msg[0];
          const prevReleaseInfo = res.msg.slice(1);
          this.setState(
            {
              releaseDateCheck: releaseInfo.Date ? this.convertDateFormat(releaseInfo.Date) : "",
              currentReleaseNotes: {
                version: releaseInfo.Version ? releaseInfo.Version : "",
                releaseDate: releaseInfo.Date ? releaseInfo.Date : "",
                sessions: releaseInfo["Major Changes"].Sessions && releaseInfo["Major Changes"].Sessions.length !== 0
                  ? releaseInfo["Major Changes"].Sessions
                  : [],
                users: releaseInfo["Major Changes"].Users && releaseInfo["Major Changes"].Users.length !== 0
                  ? releaseInfo["Major Changes"].Users
                  : [],
                plans: releaseInfo["Major Changes"].Plans && releaseInfo["Major Changes"].Plans.length !== 0
                  ? releaseInfo["Major Changes"].Plans
                  : [],
                reports: releaseInfo["Major Changes"].Reports && releaseInfo["Major Changes"].Reports.length !== 0
                  ? releaseInfo["Major Changes"].Reports
                  : [],
                events: releaseInfo["Major Changes"].Events && releaseInfo["Major Changes"].Events.length !== 0
                  ? releaseInfo["Major Changes"].Events
                  : [],
                generic: releaseInfo["Major Changes"].Generic && releaseInfo["Major Changes"].Generic.length !== 0
                  ? releaseInfo["Major Changes"].Generic
                  : [],
                bugs: releaseInfo["Major Changes"]["Bug Fixes"] && releaseInfo["Major Changes"]["Bug Fixes"].length !== 0
                  ? releaseInfo["Major Changes"]["Bug Fixes"]
                  : ["Other bug fixes"],
              },
              prevReleaseNotes: prevReleaseInfo,
            },
            () => {
              console.log("Release Info", this.state.prevReleaseNotes);
              this.calculateDateDifference();
            }
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching event:", error);
      });
  };

  toggleExpansion = (index) => {
    this.setState((prevState) => ({
      expandedNotes: {
        ...prevState.expandedNotes,
        [index]: !prevState.expandedNotes[index],
      },
    }));
  };

  calculateDateDifference = () => {
    console.log("Checking Date", this.state.releaseDateCheck);
    const releaseDateStr = this.state.releaseDateCheck;
    const releaseDate = new Date(releaseDateStr);
    const currentDate = new Date();

    // Resetting the time part to zero for both dates for accurate day difference calculation
    releaseDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const timeDifference = currentDate - releaseDate;
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    if(dayDifference > 2){
        this.setState({colorReleaseCheck: true});
    } else {
        this.setState({colorReleaseCheck: false});
    }
}


  render() {
    const { classes, title } = this.props;

    const {
      menuAnchor,
      showHideSnackbar,
      username,
      imageDimensions,
      releaseAnchor,
      releasePrev1,
      releasePrev2,
      currentReleaseNotes,
      prevReleaseNotes,
      expandedNotes,
    } = this.state;

    return (
      <AppBar
        //position="fixed"
        className={classes.appBar}
        color="primary"
      >
        <Toolbar className={classes.toolbar} color="primary">
          <Grid
            container
            justify="center"
            alignItems="center"
            // spacing={2}
            // className={classes.gridContainer}
            style={{ display: "flex" }}
          >
            <Grid
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              item
              className={classes.leftGridItem}
              style={{
                //marginLeft: "-14px",
                //backgroundColor: "black",
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <div
                style={{
                  // marginLeft: "-14px",
                  // backgroundColor: "#FFFFFF",
                  // borderRadius: "3px",
                  position: "relative",
                  left: 0,
                  right: 0,
                }}
              >
                {this.state.headerBglogo !== "" ? (
                  <img
                    className={classes.logo}
                    style={{
                      position: "relative",
                      padding: 0,
                      left: 0,
                      top: 0,
                      borderRadius: "3px",
                      width:
                        imageDimensions && imageDimensions.width != 57
                          ? "100px"
                          : 57,
                    }}
                    // src={require("../../../assets/images/white-bg-header.png")}
                    src={this.state.headerBglogo}
                    alt=""
                  />
                ) : (
                  <img
                    className={classes.logo}
                    style={{
                      position: "relative",
                      padding: 0,
                      left: 0,
                      top: 0,
                      visibility: "hidden",
                    }}
                    src={""}
                  />
                )}
                {imageDimensions &&
                Object.keys(imageDimensions).length !== 0 ? (
                  <img
                    // src={logo}
                    src={this.state.headerLogoSrc}
                    onError={() => {
                      this.setState({
                        headerLogoSrc: logo,
                      });
                    }}
                    alt="k4-logo"
                    className={classes.logoMobileView}
                    style={{
                      position: "absolute",
                      left: "0px",
                      borderRadius: "3px",
                      width:
                        imageDimensions && imageDimensions.width != 57
                          ? "100px"
                          : 57,
                      height: 40,
                    }}
                  />
                ) : null}
              </div>
              <div
                item
                style={{ marginLeft: 10 }}
                // style={{
                //   position: "absolute",
                //   left:
                //     imageDimensions && imageDimensions.width != 57 ? 150 : 70,
                // }}
              >
                <span>{this.state.currentTime} UTC</span>
              </div>
            </Grid>

            {/* <MyComponent /> */}

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              // xs={12}
              // sm={12}
              // style={{
              //   // display: title && title.includes("Plan Details") ? "none" : "",
              //   // backgroundColor: "cyan",
              //   [theme.breakpoints.down("md")]: {
              //     display: "none",
              //   },
              // }}
              className={classes.header}
              style={{ justifyContent: "center" }}
            >
              <Typography variant="h6" className={classes.headerText}>
                {/* Administration Portal */}
                {title ? title : "Administration Portal"}
              </Typography>
            </Grid>

            <Grid
              item
              xl={4}
              lg={4}
              md={4}
              sm={12}
              xs={12}
              style={{
                marginLeft: "5px",
                // backgroundColor: "green",
                position: "absolute",
                right: 10,
                display: title && title.includes("Plan Details") ? "flex" : "",
                alignItems:
                  title && title.includes("Plan Details") ? "center" : "",
              }}
            >
              <Grid
                item
                style={{
                  display:
                    title && title.includes("Plan Details") ? "" : "none",
                  marginRight: "10px",
                }}
              >
                <Typography
                  variant="h6"
                  className={classes.headerText}
                  style={{ fontSize: "1rem" }}
                >
                  {/* Administration Portal */}
                  {title}
                </Typography>
              </Grid>

              <div style={{ display: "flex", gap: "5px" }}>
                <Typography
                  style={{ alignSelf: "center" }}
                  className={classes.header2}
                >
                  {username}
                </Typography>
                <img
                  // className={classes.header2}
                  src={menuIcon}
                  style={{ cursor: "pointer", padding: "10px", height: "40px" }}
                  onClick={(event) => this.fnOpenCloseMenu(event.currentTarget)}
                  alt="vertical-ellipses"
                />
                {menuAnchor ? (
                  <Popover
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={() => this.fnOpenCloseMenu(null)}
                  >
                    <div className={classes.dropContainer}>
                      {title && (
                        <MenuItem
                          button={false}
                          className={classes.dropItem}
                          onClick={() => this.changePwd(true)}
                        >
                          <Typography className={classes.dropItemText}>
                            Change Password
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem
                        button={false}
                        className={classes.dropItem}
                        onClick={() => this.fnLogout()}
                      >
                        <Typography className={classes.dropItemText}>
                          Logout
                        </Typography>
                      </MenuItem>
                    </div>
                  </Popover>
                ) : null}

                <IconButton>
                  <NewReleasesIcon
                    onClick={(event) =>
                      this.fnOpenCloseReleaseMenu(event.currentTarget)
                    }
                    style={{ color: this.state.colorReleaseCheck ? "white" : "orange", paddingBottom: "2px" }}
                  />
                </IconButton>
                {releaseAnchor ? (
                  <Popover
                    anchorEl={releaseAnchor}
                    open={Boolean(releaseAnchor)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    onClose={() => this.fnOpenCloseReleaseMenu(null)}
                  >
                    <div
                      className={`scroll ${classes.scrollContainer} ${classes.dropContainer2}`}
                    >
                      <div className={classes.releaseHeader}>
                        <p style={{ fontSize: "18px", margin: "13px 0px" }}>
                          Release Notes
                        </p>
                        <IconButton
                          onClick={this.handleCloseIcon}
                          style={{
                            padding: "2px",
                            transform: "scale(1)",
                          }}
                        >
                          <CloseIcon style={{ color: "white" }} />
                        </IconButton>
                      </div>
                      <div
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          padding: "5px 10px 5px 24px",
                        }}
                      >
                        <div style={{ borderBottom: "1px solid black" }}>
                          <p>
                            <span
                              style={{ fontSize: "18px", fontWeight: "700" }}
                            >
                              Version:{" "}
                            </span>{" "}
                            {currentReleaseNotes.version}(Current)
                          </p>
                          <p>
                            <span
                              style={{ fontSize: "18px", fontWeight: "700" }}
                            >
                              Date:{" "}
                            </span>{" "}
                            {currentReleaseNotes.releaseDate}
                          </p>
                          <span style={{ fontSize: "18px", fontWeight: "700" }}>
                            Major Changes: <br />
                          </span>
                          {currentReleaseNotes.sessions.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Sessions:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.sessions.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {currentReleaseNotes.users.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Users:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.users.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {currentReleaseNotes.plans.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Plans:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.plans.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {currentReleaseNotes.reports.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Reports:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.reports.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {currentReleaseNotes.events.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Events:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.events.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {currentReleaseNotes.generic.length !== 0 ? (
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Others:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.generic.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          ) : null}
                          {
                            <p>
                              <span
                                style={{ fontSize: "18px", fontWeight: "700" }}
                              >
                                Bug Fixes:{" "}
                              </span>
                              <ul>
                                {currentReleaseNotes.bugs.map(
                                  (change, index) => (
                                    <li key={index}>{change}</li>
                                  )
                                )}
                              </ul>
                            </p>
                          }
                        </div>
                        <Grid
                          container
                          spacing={1}
                          style={{ marginTop: "5px" }}
                        >
                          {prevReleaseNotes.map((note, index) => (
                            <Grid item xs={12} key={index}>
                              <div
                                style={{
                                  borderTop: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <span>
                                  <IconButton
                                    onClick={() => this.toggleExpansion(index)}
                                    style={{ padding: "4px 0px" }}
                                  >
                                    {expandedNotes[index] ? (
                                      <ArrowDropDownIcon />
                                    ) : (
                                      <ArrowRightIcon />
                                    )}
                                  </IconButton>{" "}
                                  {note.Version}
                                </span>
                                <div
                                  style={{
                                    display: expandedNotes[index] ? "" : "none",
                                  }}
                                >
                                  <p>
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Version:
                                    </span>{" "}
                                    {note.Version}
                                  </p>
                                  <p>
                                    <span
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Date:
                                    </span>{" "}
                                    {note.Date}
                                  </p>
                                  <span
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "700",
                                    }}
                                  >
                                    Major Changes: <br />
                                  </span>
                                  {note["Major Changes"].Sessions &&
                                  note["Major Changes"].Sessions.length !==
                                    0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Sessions:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Sessions.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"].Users &&
                                  note["Major Changes"].Users.length !== 0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Users:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Users.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"].Plans &&
                                  note["Major Changes"].Plans.length !== 0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Plans:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Plans.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"].Reports &&
                                  note["Major Changes"].Reports.length !== 0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Reports:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Reports.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"].Events &&
                                  note["Major Changes"].Events.length !== 0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Events:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Events.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"].Generic &&
                                  note["Major Changes"].Generic.length !== 0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Others:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"].Generic.map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : null}
                                  {note["Major Changes"]["Bug Fixes"] &&
                                  note["Major Changes"]["Bug Fixes"].length !==
                                    0 ? (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Bug Fixes:{" "}
                                      </span>
                                      <ul>
                                        {note["Major Changes"]["Bug Fixes"].map(
                                          (change, index) => (
                                            <li key={index}>{change}</li>
                                          )
                                        )}
                                      </ul>
                                    </p>
                                  ) : (
                                    <p>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Bug Fixes:{" "}
                                      </span>
                                      <ul>
                                        <li>Other bug fixes</li>
                                      </ul>
                                    </p>
                                  )}
                                </div>
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </div>
                    </div>
                  </Popover>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
        {/* {showChangePasswordDialog.open && (
          <DialogChangePassword
            open={showChangePasswordDialog.open}
            data={showChangePasswordDialog.data}
            onClose={() => this.closeChangePassword()}     
            onChangePassword={this.handleChangePassword}      
            showSnackbar={(msg, variant) => this.props.showSnackbar(msg, variant)}
          />
        )}
        {showPasswordSuccessMsg.open && (
          <DialogMessage
          open={showPasswordSuccessMsg.open}
          header={showPasswordSuccessMsg.title}
          message={showPasswordSuccessMsg.message}
          onClose={this.handleMessageClose}
          />
        )} */}
      </AppBar>
    );
  }
}

export default withStyles(withRouter(Header));
