import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";
// import BG from "../../assets/images/ship-bg-1.png";

const RECT = require("../../assets/images/login-rec-cut.png");

export default makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(52,65,132,255)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    WebkitBackgroundSize: "cover",
    MozBackgroundSize: "cover",
    OBackgroundSize: "cover",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  rootSmall: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      background: "rgba(52,65,132,255)",
      //background: "#FFFFFF",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
    },
  },

  cardRoot: {
    width: "992px",
    height: "643px",
    background: "#FFFFFF",
    display: "flex",

    //borderRadius: "10px",
    boxShadow: "4px 4px 50px rgba(0, 0, 0, 0.5)",
    [theme.breakpoints.down("sm")]: {
      width: "350px",
      height: "643px",
      //borderRadius: "10px",
      //borderRadius: "100px",
    },
  },
  logoContainer: {
    position: "relative",
    width: "607.14px",
    height: "643px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundImage: "url(" + RECT + ")",
  },
  logo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "165px",
    height: "123px",
    // marginLeft: "127px",
    marginBottom: "20px",
  },
  logo2: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "250px",
    height: "100px",
    // marginLeft: "127px",
    marginBottom: "20px",
  },
  logoSmall: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "90px",
    height: "90px",
    // marginLeft: "127px",
    marginBottom: "10px",
  },
  logo3: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "250px",
    height: "250px",
    marginLeft: "127px",
    marginBottom: "20px",
  },
  k4_logo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "200px",
    height: "200px",
    // marginLeft: "150px",
    marginBottom: "20px",
  },
  nexus_logo: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "350px",
    height: "350px",
    // marginLeft: "150px",
    marginBottom: "20px",
  },
  internalLogoDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "100px",
  },
  logoCaption: {
    fontWeight: 800,
    fontSize: "24px",
    lineHeight: "33px",
    // marginLeft: "122px",
    width: "290px",
    height: "36px",
    color: theme.palette.primary.main,
  },
  copyright: {
    position: "absolute",
    bottom: 16,
    height: "16px",
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    left: 190,
  },
  copyrightSmall: {
    height: "16px",
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.primary.main,
    //left: 190,
  },
  inputRootContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "384.86px",
    height: "643px",
    backgroundColor: "#ffffff",
  },
  loginTitle: {
    fontSize: "24px",
    height: "33px",
    color: theme.palette.primary.main,
  },
  usernameHeading: {
    marginTop: "65px",
    height: "20px",
    width: "323px",
    color: "#384089",
    fontSize: "0.8rem",
  },
  pswrdHeading: {
    marginTop: "35.61px",
    height: "20px",
    width: "323px",
    color: "#384089",
    fontSize: "0.8rem",
  },
  submitBtnWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgrBtnWrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  loginLoader: {
    color: fade(theme.palette.primary.main, 1),
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12,
  },
  forgotPass: {
    width: "129px",
    height: "20px",
    fontSize: "16px",
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "40px",
    color: theme.palette.primary.main,
    marginLeft: "10px",
  },
}));
