import React, { Component } from "react";
import {
  Typography,
  Tooltip,
  withStyles as muiStyles,
  Grid,
  Dialog
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import classnames from "classnames";

import withStyles from "../styles/PlanList";
import {
  API_DELETE_PLAN,
  API_GET_PLANS_HISTORY,
  API_GET_USER_DEVICES,
  API_IS_USER_LOGGED_IN,
} from "../../constants/ApiEndPoints";
import { callPostApi } from "../../managers/api/ApiManager";
import { getSessionData } from "../../managers/session/SessionManager";
import asyncComponent from "../AsyncComponent"
import Header from "../private/Header";

const CustomSnackbar = asyncComponent(() => import("../snackbar/CustomSnackbar"));
const DialogDelete = asyncComponent(() => import("../private/DialogDelete"));
const CustomLoader = asyncComponent(() => import("../CustomLoader/CustomLoader"));
// const Header = asyncComponent(() => import("../private/Header"));

// import CustomLoader from "../CustomLoader/CustomLoader";
// import DialogDelete from "../private/DialogDelete";
// import CustomSnackbar from "../snackbar/CustomSnackbar";

const tableHeaders = [
  { title: "Plan Name", minWidth: "116px" },
  { title: "Purchase Date", minWidth: "116px" },
  { title: "Plan Start Date", minWidth: "116px" },
  { title: "Plan Expiry Date", minWidth: "116px" },
  { title: "Remaining Quota", minWidth: "116px" },
  { title: "Remaining Daily Limit", minWidth: "116px" },
  { title: "Action", minWidth: "" },
];

const tableHeadersUpcoming = [
  { title: "Plan Name", minWidth: "116px" },
  { title: "Purchase Date", minWidth: "116px" },
  { title: "Duration", minWidth: "116px" },
  { title: "Assigned Quota", minWidth: "116px" },
  { title: "Assigned Daily Limit", minWidth: "116px" },
  { title: "Action", minWidth: "" },
];

const LightTooltip = muiStyles((theme) => ({
  arrow: {
    color: "#f5f5f9",
  },
  tooltip: {
    width: "fit-content",
    backgroundColor: "#f5f5f9",
    color: "rgba(0,0,0,0.8)",
    boxShadow: theme.shadows[4],
    fontSize: "0.75rem",
  },
}))(Tooltip);

class PlanHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPlan: [],
      upcomingPlan: [],
      expiredPlan: [],
      expandId: "",
      expand: false,
      deviceDetail: [],
      isLoading: false,
      userId: "",
      userName: "",
      expandType: "",
      isLoading: false,
      expandIndex: null,
      openDeleteDialog: {
        open: false,
        id: null,
        deletePlanName: "",
      },
      isAssignedToUser: true,
      userId: null,
      showHideSnackbar: {
        message: "",
        variant: "",
        show: false,
      },
      planPurchaseDate: null,
      screenView: 1,
      showSessionDetailsPopup: false,
      isAnonymousUser: false
    };
  }

  componentDidMount() {
    let userInfo = getSessionData("NON_ADMIN_USER_INFO")
      ? getSessionData("NON_ADMIN_USER_INFO")
      : "";
    console.log('userInfo :>> ', userInfo);
    
    let width = window.innerWidth;

    if (width < "450") {
      this.setState({
        screenView: 2
      })
    }
    if (userInfo) {
      let userId = userInfo.userRecId;
      let userName = userInfo.username;
      let isAnonymousUser = userInfo.anonymous
      this.setState({ userId, userName, isAnonymousUser });
      this.fnGetHistory(userId, true);
      this.interval = setInterval(() => {
        this.fnGetHistory(this.state.userId, false);
      }, 60000);
      this.fnIsLoggedIn();
    } else {
      window.location.replace(
        "https://access.dev.k4mobility.com/authentication"
      );
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval();
  }

  fnGetHistory = (userId, isLoading) => {
    let payload = { userId };
    let currentPlan = [];
    let upcomingPlan = [];
    let expiredPlan = [];
    payload["dpIds"] = getSessionData("DP_ID") ? [getSessionData("DP_ID")] : [];
    this.setState({ isLoading, userId });
    callPostApi("", "", API_GET_PLANS_HISTORY,  payload)
      .then((res) => {
        if (res && res.status === 1) {
          console.log("res :>> ", res);
          currentPlan = res.data.currentPlan ? res.data.currentPlan : [];
          upcomingPlan = res.data.upcomingPlan ? res.data.upcomingPlan : [];
          expiredPlan = res.data.expiredPlan ? res.data.expiredPlan : [];
          currentPlan.map((obj) => {
            const expireDate = obj.planExpiryDate ? moment
              .utc(new Date(obj.planExpiryDate)) : null;
            const startDate = obj.planStartDate ? moment
              .utc(new Date(obj.planStartDate)) : null;
            obj.planExpiryDateFormat = expireDate && startDate ?
              expireDate.diff(startDate, 'days') > 120 ? 'No End Date' :
                expireDate.format("MMM-DD-yyyy") : '--';
            obj.planStartDateFormat = startDate ? startDate
              .format("MMM-DD-yyyy") : '--';
            obj.deviceDetails = [];
          });
          upcomingPlan.map((obj) => {
            obj.deviceDetails = [];
          });
          expiredPlan.map((obj) => {
            const expireDate = obj.planExpiryDate ? moment
              .utc(new Date(obj.planExpiryDate)) : null;
            const startDate = obj.planStartDate ? moment
              .utc(new Date(obj.planStartDate)) : null;
            obj.planExpiryDateFormat = expireDate && startDate ?
              expireDate.diff(startDate, 'days') > 120 ? 'No End Date' :
                expireDate.format("MMM-DD-yyyy") : '--';
            obj.planStartDateFormat = startDate ? startDate
              .format("MMM-DD-yyyy") : '--';
            obj.deviceDetails = [];
          });
          this.setState({
            currentPlan,
            upcomingPlan,
            expiredPlan,
            isLoading: false,
          });
        } else if (res && res.status === 0) {
          console.log("res :>> ", res);
          this.setState({ isLoading: false });
        } else {
          console.log("res :>> ", res);
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.setState({ isLoading: false });
      });
  };

  fnCloseDialog = () => {
    this.setState({
      showSessionDetailsPopup: false
    })
  }

  fnConvertBytes = (bytes) => {
    console.log("bytes", bytes);
    if (bytes === 0 || bytes === null || bytes === undefined) return 0;
    let result = 0;
    if (Number((bytes / (1000 * 1000 * 1000)).toFixed(2)) < 1) {
      result = Number((bytes / (1000 * 1000)).toFixed(2)) + " MB"
    } else {
      result = Number((bytes / (1000 * 1000 * 1000)).toFixed(2)) + " GB"
    }
    result = result.replace(/\.00$/, "");
    return result;
  };

  fnConvertDailyTimeLimit = (seconds, type) => {
    if (type === "sec") {
      //convert to hr & min & sec
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds - (hours * 3600)) / 60);
      var remainingSeconds = seconds % 60;

      var finalString = "";
      
      let hrString = (hours > 1) ? hours + " hrs" : (hours === 1) ? "1 hr" : ""
      let minString = (minutes >= 1) ? minutes + " min" :  ""
      let secString = (remainingSeconds >= 1) ? remainingSeconds + " sec" :  ""

      finalString = (hrString) + (hrString ? " " + minString : minString) + (minString ? " " + secString : secString)

      console.log("TEST", seconds, hours, hrString , finalString)

      return finalString
    }
  }

  fnOpenCloseNestedTable = (
    expand,
    expandId,
    expandType,
    expandIndex,
    date
  ) => {
    this.setState({ expand, expandId, expandType, expandIndex });
    if (expand) {
      let isCurrentPlan = false;
      if (expandType === "current") {
        isCurrentPlan = true;
      } else if (expandType === "expired") {
        isCurrentPlan = false;
      }
      this.fnGetPlanDevices(expandId, isCurrentPlan, date);
    }
  };

  fnGetPlanDevices = (planRecId, isCurrentPlan, planStartDate) => {
    let userRecId = this.state.userId;
    let payload = {};
    if (isCurrentPlan) {
      payload = { userRecId, planRecId, isCurrentPlan, planStartDate };
    } else {
      payload = { userRecId, planRecId, isCurrentPlan, planStartDate };
    }
    this.setState({ isLoading: true });
    let deviceDetail = [];
    callPostApi("", "", API_GET_USER_DEVICES, payload)
      .then((res) => {
        if (res && res.status === 1) {
          deviceDetail = res.data ? res.data : [];
          this.setState({ isLoading: false });
          this.setState({ 
            deviceDetail,
            showSessionDetailsPopup: this.state.screenView === 2 ? true : false
          });
        } else if (res && res.status === 0) {
          this.setState({ isLoading: false });
          this.setState({ deviceDetail,
            showSessionDetailsPopup: this.state.screenView === 2 ? true : false });
        } else {
          this.setState({ isLoading: false });
          this.setState({ deviceDetail,
            showSessionDetailsPopup: this.state.screenView === 2 ? true : false });
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  };

  fnOpenCloseConfirmDeleteDialog = (open, id, planPurchaseDate, deletePlanName) => {
    this.setState({ openDeleteDialog: { open, id, deletePlanName }, planPurchaseDate });
  };

  fnDeletePlan = () => {
    let payload = {
      planRecId: this.state.openDeleteDialog.id,
      userRecId: this.state.userId,
      planPurchaseDate: this.state.planPurchaseDate,
      planName: this.state.openDeleteDialog.deletePlanName
    };
    payload["admin_name"] = getSessionData("ADMIN_NAME") ? getSessionData("ADMIN_NAME") : "";
    payload["dpIds"] = getSessionData("DP_ID") ? [getSessionData("DP_ID")] : [];
    callPostApi(
      "PlanHistoryPublic.jsx",
      "TAG_API_DELETE_PLAN",
      API_DELETE_PLAN,
      payload
    )
      .then((res) => {
        if (res && res.status === 1) {
          this.fnGetHistory(this.state.userId);
          this.fnShowSnackbar(res.msg ? res.msg : "Plan Deleted", "success");
        } else if (res && res.status === 0) {
          console.log("res :>> ", res);
          this.fnShowSnackbar(res.msg ? res.msg : "Please try again");
        } else {
          console.log("res :>> ", res);
          this.fnShowSnackbar(res.msg ? res.msg : "Please try again");
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
        this.fnShowSnackbar("Please try again");
      });
  };

  fnShowSnackbar = (msg = "", variant = "error") => {
    this.setState(
      {
        showHideSnackbar: {
          message: msg,
          variant,
          show: true,
        },
      },
      () =>
        console.log("this.state.showHideSnackbar", this.state.showHideSnackbar)
    );
  };

  fnHideSnackbar = () => {
    this.setState({
      showHideSnackbar: {
        message: "",
        variant: "",
        show: false,
      },
    });
  };

  fnSetDaysUnit = (val) => {
    if (val === 1) {
      return " day";
    } else {
      return " days";
    }
  };

  fnIsLoggedIn = () => {
    let userData = getSessionData("NON_ADMIN_USER_INFO")
      ? getSessionData("NON_ADMIN_USER_INFO")
      : "";
    let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;  
    let payload = {};
    console.log('userData :>> ', userData);
    if (userData) {
      payload.userRecId = userData.userRecId;
      payload.ipAddress = userData.ipAddr;
      payload.macAddress = decryptObj && decryptObj.mac ? decryptObj.mac.toUpperCase() : "";
      callPostApi("", "", API_IS_USER_LOGGED_IN, payload)
        .then((res) => {
          if (res && res.status === 1) {
            if (res.data && res.data.loggedIn === false) {
              this.props.history.replace("/authentication");
            }
          } else if (res && res.status === 0) {
            this.props.history.replace("/authentication");
          } else {
            this.props.history.replace("/authentication");
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      currentPlan,
      upcomingPlan,
      expiredPlan,
      expand,
      expandId,
      userName,
      expandType,
      isLoading,
      openDeleteDialog,
      isAssignedToUser,
      showHideSnackbar,
      screenView,
      showSessionDetailsPopup,
      deviceDetail,
      isAnonymousUser
    } = this.state;
//     let currentPlan = [
//       {
//           "planName": "100MB_1Day_Plan",
//           "planQuota": 100000000,
//           "dailyQuotaLimit": 100000000,
//           "dailyTimeLimit": 86400,
//           "planStartDate": "2023-03-12T05:06:15.593Z",
//           "planExpiryDate": "2023-03-13T05:06:15.593Z",
//           "planPurchaseDate": "2023-03-12T05:05:35.547Z",
//           "remainingQuota": 100000000,
//           "planId": "63cffbe3bc54df37d6a1b05d",
//           "duration": 1,
//           "topupCount": 0,
//           "topupQuota": 0,
//           "remainingDailyQuotaLimit": 100000000,
//           "remainingDailyTimeLimit": 86400
//       }
//   ]
//   let currentPlan = [];
//   let upcomingPlan = [
//     {
//         "planName": "100MB_1Day_Plan",
//         "planQuota": 100000000,
//         "dailyQuotaLimit": 100000000,
//         "dailyTimeLimit": 86400,
//         "duration": "2023-03-12T05:06:15.593Z",
//         "planPurchaseDate": "2023-03-12T05:05:35.547Z",
//         "remainingQuota": 100000000,
//         "planId": "63cffbe3bc54df37d6a1b05d",
//         "duration": 1,
//         "topupCount": 0,
//         "topupQuota": 0,
//         "remainingDailyQuotaLimit": 100000000,
//         "remainingDailyTimeLimit": 86400
//     }
// ]
// let upcomingPlan = [];
//   let deviceDetail = [
//     {
//         "_id": "640d5dc75fe8f10997296345",
//         "ipAddress": "192.168.231.17",
//         "isCurrentPlan": true,
//         "planRecId": "63cffbe3bc54df37d6a1b05d",
//         "userRecId": "640d5d9fca95aa1d1e0e4fde",
//         "vesselDeviceId": "EN1K31AAAAA000016",
//         "__v": 0,
//         "createdAt": "2023-03-12T05:06:15.594Z",
//         "deviceDpId": "DP-0001",
//         "hostname": "lab-win-2",
//         "k4id": "K4-0211",
//         "lastActivityAt": "2023-03-12T05:22:53.469Z",
//         "loggedIn": false,
//         "macAddress": "F4:4E:E3:ED:FD:9B",
//         "planStartDate": "2023-03-12T05:06:15.593Z",
//         "updatedAt": "2023-03-12T05:23:02.008Z",
//         "vesselName": "MY-Demo Site",
//         "quotaUsed": 0,
//         "sessionData": [
//             {
//                 "_id": "640d61ae5fe8f109972ba541",
//                 "logoutDate": "2023-03-12T05:23:02.411Z",
//                 "logInDate": "2023-03-12T05:22:53.897Z",
//                 "quotaUsed": 0,
//                 "sessionId": 4,
//                 "isOngoing": false,
//                 "duration": 8
//             },
//             {
//                 "_id": "640d5e9e5fe8f1099729e1ce",
//                 "logoutDate": "2023-03-12T05:10:22.056Z",
//                 "logInDate": "2023-03-12T05:09:49.990Z",
//                 "quotaUsed": 6723789,
//                 "sessionId": 3,
//                 "isOngoing": false,
//                 "duration": 32
//             },
//             {
//                 "_id": "640d5df65fe8f10997297e47",
//                 "logoutDate": "2023-03-12T05:07:29.640Z",
//                 "logInDate": "2023-03-12T05:07:01.966Z",
//                 "quotaUsed": 0,
//                 "sessionId": 2,
//                 "isOngoing": false,
//                 "duration": 27
//             },
//             {
//                 "_id": "640d5dc85fe8f10997296372",
//                 "logoutDate": "2023-03-12T05:06:31.745Z",
//                 "logInDate": "2023-03-12T05:06:16.014Z",
//                 "quotaUsed": 0,
//                 "sessionId": 1,
//                 "isOngoing": false,
//                 "duration": 15
//             }
//         ]
//     },
//     {
//       "_id": "640d5dc75fe8f10997296345",
//       "ipAddress": "192.168.231.17",
//       "isCurrentPlan": true,
//       "planRecId": "63cffbe3bc54df37d6a1b05d",
//       "userRecId": "640d5d9fca95aa1d1e0e4fde",
//       "vesselDeviceId": "EN1K31AAAAA000016",
//       "__v": 0,
//       "createdAt": "2023-03-12T05:06:15.594Z",
//       "deviceDpId": "DP-0001",
//       "hostname": "lab-win-2",
//       "k4id": "K4-0211",
//       "lastActivityAt": "2023-03-12T05:22:53.469Z",
//       "loggedIn": false,
//       "macAddress": "F4:4E:E3:ED:FD:9B",
//       "planStartDate": "2023-03-12T05:06:15.593Z",
//       "updatedAt": "2023-03-12T05:23:02.008Z",
//       "vesselName": "MY-Demo Site",
//       "quotaUsed": 0,
//       "sessionData": [
//           {
//               "_id": "640d61ae5fe8f109972ba541",
//               "logoutDate": "2023-03-12T05:23:02.411Z",
//               "logInDate": "2023-03-12T05:22:53.897Z",
//               "quotaUsed": 0,
//               "sessionId": 4,
//               "isOngoing": false,
//               "duration": 8
//           },
//           {
//               "_id": "640d5e9e5fe8f1099729e1ce",
//               "logoutDate": "2023-03-12T05:10:22.056Z",
//               "logInDate": "2023-03-12T05:09:49.990Z",
//               "quotaUsed": 6723789,
//               "sessionId": 3,
//               "isOngoing": false,
//               "duration": 32
//           },
//           {
//               "_id": "640d5df65fe8f10997297e47",
//               "logoutDate": "2023-03-12T05:07:29.640Z",
//               "logInDate": "2023-03-12T05:07:01.966Z",
//               "quotaUsed": 0,
//               "sessionId": 2,
//               "isOngoing": false,
//               "duration": 27
//           },
//           {
//               "_id": "640d5dc85fe8f10997296372",
//               "logoutDate": "2023-03-12T05:06:31.745Z",
//               "logInDate": "2023-03-12T05:06:16.014Z",
//               "quotaUsed": 0,
//               "sessionId": 1,
//               "isOngoing": false,
//               "duration": 15
//           }
//       ]
//   }
// ]
// let deviceDetail = [];
// let expiredPlan = [
//   {
//       "planName": "100MB_1Day_Plan",
//       "planQuota": 100000000,
//       "dailyQuotaLimit": 100000000,
//       "dailyTimeLimit": 86400,
//       "planStartDate": "2023-02-21T08:01:45.234Z",
//       "planExpiryDate": "2023-02-22T08:01:45.234Z",
//       "planPurchaseDate": "2023-02-21T08:00:25.486Z",
//       "remainingQuota": 100000000,
//       "planId": "63cffbe3bc54df37d6a1b05d",
//       "duration": 1,
//       "topupCount": 1,
//       "topupQuota": 20000000
//   },
//   {
//       "planName": "100MB_1Day_Plan",
//       "planQuota": 100000000,
//       "dailyQuotaLimit": 100000000,
//       "dailyTimeLimit": 86400,
//       "planStartDate": "2023-02-24T11:28:17.057Z",
//       "planExpiryDate": "2023-02-25T11:28:17.057Z",
//       "planPurchaseDate": "2023-02-24T06:28:54.559Z",
//       "remainingQuota": 82403663,
//       "planId": "63cffbe3bc54df37d6a1b05d",
//       "duration": 1,
//       "topupCount": 0,
//       "topupQuota": 0
//   },
//   {
//       "planName": "100MB_1Day_Plan",
//       "planQuota": 100000000,
//       "dailyQuotaLimit": 100000000,
//       "dailyTimeLimit": 86400,
//       "planStartDate": null,
//       "planExpiryDate": "2023-03-11T05:30:00.000Z",
//       "planPurchaseDate": "2023-03-03T12:19:41.835Z",
//       "remainingQuota": 100000000,
//       "planId": "63cffbe3bc54df37d6a1b05d",
//       "duration": 1,
//       "topupCount": 0,
//       "topupQuota": 0
//   }
// ]
// let expiredPlan = [];
    return (
      <>
        <Header 
          title={`Plan Details (${userName})`} 
          showLoader={(isLoading) => this.setState({ isLoading })} 
          showSnackbar={(msg, variant) => this.fnShowSnackbar(msg, variant)}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: true,
          })}
        >
          <div className={classes.fakeToolbar} />
          {screenView === 1
          ? <div className={`scroll ${classes.scrollContainer}`}>
            <div className={`scroll ${classes.plansContainer}`}>
              <div className={classes.tableContainer}>
                <div className={classes.headingBlock}>
                  <Typography className={classes.heading}>
                    Current Plan
                  </Typography>
                </div>
                <Table>
                  <TableHead>
                    <TableRow className={classes.headerTableRow}>
                      {tableHeaders.map((tableCell, ind) => (
                        <TableCell
                          key={ind}
                          className={classes.headerTableCell}
                          align="center"
                          style={{ minWidth: tableCell.minWidth }}
                        >
                          <Typography className={classes.headerTableCellText}>
                            {tableCell.title}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPlan.map((tableCell, ind) => (
                      <>
                        <TableRow key={ind} className={classes.tableRowBody}>
                          <TableCell className={classes.tableCellNoPaddingTop}>
                            <Typography className={classes.tableBodyCellText}>
                              {tableCell.planName}
                            </Typography>
                            <Typography className={classes.tableBodyCellText}>
                              {tableCell.topupCount ? (
                                <LightTooltip
                                  title={`Total Top-Up Quota: ${this.fnConvertBytes(
                                    tableCell.topupQuota
                                  )}`}
                                >
                                  <span className={classes.topUpFontSize}>
                                    {`(Top-ups- ${tableCell.topupCount})`}
                                  </span>
                                </LightTooltip>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planPurchaseDate
                                ? moment
                                    .utc(new Date(tableCell.planPurchaseDate))
                                    .format("MMM-DD-yyyy")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planStartDateFormat}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planExpiryDateFormat}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip
                              title={`Assigned Quota - ${this.fnConvertBytes(
                                tableCell.planQuota
                              )}`}
                            >
                              <Typography
                                className={`${classes.tableBodyCellText}`}
                              >
                                {this.fnConvertBytes(tableCell.remainingQuota)}
                              </Typography>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip
                              title={
                                <Grid container>
                                  <Grid item>
                                    <Typography className={classes.TooltipText} style={{ padding: "5px 10px" }}>
                                      {`Assigned Daily Usage Limit - ${tableCell.dailyQuotaLimit ? this.fnConvertBytes(tableCell.dailyQuotaLimit) : "--"}`}
                                    </Typography>
                                    <Typography className={classes.TooltipText} style={{ padding: "0px 10px 5px 10px" }}>
                                      {`Assigned Daily Time Limit - ${tableCell.dailyTimeLimit ? this.fnConvertDailyTimeLimit(tableCell.dailyTimeLimit, "sec") : "--"}`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              }
                            >
                              <Typography
                                className={`${classes.tableBodyCellText}`}
                              >
                                {tableCell.remainingDailyQuotaLimit ? this.fnConvertBytes(tableCell.remainingDailyQuotaLimit) : "--"}{" "}
                                / {" "}
                                {tableCell.remainingDailyTimeLimit ? this.fnConvertDailyTimeLimit(tableCell.remainingDailyTimeLimit, "sec") : "--"}
                              </Typography>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip
                              title={
                                this.state.expand &&
                                this.state.expandId === tableCell.planId &&
                                this.state.expandType === "current" &&
                                this.state.expandIndex === ind
                                  ? "Close Session details"
                                  : "View Session details"
                              }
                            >
                              <img
                                src={
                                  this.state.expand &&
                                  this.state.expandId === tableCell.planId &&
                                  this.state.expandType === "current" &&
                                  this.state.expandIndex === ind
                                    ? require("../../assets/images/close.png")
                                    : require("../../assets/images/expand.png")
                                }
                                alt="expand-close-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    this.state.expand &&
                                    this.state.expandId === tableCell.planId &&
                                    this.state.expandType === "current" &&
                                    this.state.expandIndex === ind
                                  ) {
                                    this.fnOpenCloseNestedTable(
                                      false,
                                      tableCell.planId,
                                      "current",
                                      ind,
                                      tableCell.planStartDate
                                    );
                                  } else {
                                    this.fnOpenCloseNestedTable(
                                      true,
                                      tableCell.planId,
                                      "current",
                                      ind,
                                      tableCell.planStartDate
                                    );
                                  }
                                }}
                                className={classes.icon}
                              />
                            </LightTooltip>
                          </TableCell>
                        </TableRow>
                        {expand &&
                        expandId === tableCell.planId &&
                        expandType === "current" &&
                        this.state.expandIndex === ind ? (
                          <TableRow
                            style={{
                              boxShadow:
                                // tableCell.deviceDetails.length === 0
                                //   ? "none"
                                //   :
                                "0px 4px 8px rgba(0, 0, 0, 0.15)",
                              backgroundColor: "#FAFCFF",
                            }}
                          >
                            <TableCell colSpan={11} style={{ padding: 0 }}>
                              <Table>
                                <TableHead>
                                  <TableRow style={{ height: "35px" }}>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        IP Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        MAC Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Host Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Site Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Quota Used
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.deviceDetail.map(
                                    (innerData, ind) => (
                                      <TableRow
                                        key={ind}
                                        className={classes.innerTableBodyRow}
                                      >
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.ipAddress
                                            ? innerData.ipAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.macAddress
                                            ? innerData.macAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.hostname
                                            ? innerData.hostname
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.vesselName
                                            ? innerData.vesselName
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {this.fnConvertBytes(
                                            innerData.quotaUsed
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              {this.state.deviceDetail.length === 0 ? (
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  No Records Found
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
                {currentPlan.length === 0 ? (
                  <Typography
                    style={{
                      marginTop: "12px",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    No Records Found
                  </Typography>
                ) : null}
              </div>
              {/* {!isAnonymousUser
              ?  */}
                <div className={classes.tableContainer}>
                <div className={classes.headingBlock}>
                  <Typography className={classes.heading}>
                    Upcoming Plans
                  </Typography>
                </div>
                <Table>
                  <TableHead>
                    <TableRow className={classes.headerTableRow}>
                      {tableHeadersUpcoming.map((tableCell, ind) => (
                        <TableCell
                          key={ind}
                          className={classes.headerTableCell}
                          align="center"
                          style={{ minWidth: tableCell.minWidth }}
                        >
                          <Typography className={classes.headerTableCellText}>
                            {tableCell.title}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {upcomingPlan.map((tableCell, ind) => (
                      <>
                        <TableRow key={ind} className={classes.tableRowBody}>
                          <TableCell className={classes.tableCellNoPaddingTop}>
                            <Typography className={classes.tableBodyCellText}>
                              {tableCell.planName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planPurchaseDate
                                ? moment
                                    .utc(new Date(tableCell.planPurchaseDate))
                                    .format("MMM-DD-yyyy")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.duration
                                ? tableCell.duration +
                                  this.fnSetDaysUnit(tableCell.duration)
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            {/* <LightTooltip
                              title={`Assigned Quota - ${this.fnConvertBytes(
                                tableCell.planQuota
                              )} GB`}
                            > */}
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {this.fnConvertBytes(tableCell.planQuota)}
                            </Typography>
                            {/* </LightTooltip> */}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography className={`${classes.tableBodyCellText}`}>
                              {tableCell.dailyQuotaLimit ? this.fnConvertBytes(tableCell.dailyQuotaLimit) : "--"} / {tableCell.dailyTimeLimit ? this.fnConvertDailyTimeLimit(tableCell.dailyTimeLimit, "sec") : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip title="Delete Plan">
                              <img
                                src={require("../../assets/images/delete-disabled.png")}
                                alt="expand-close-icon"
                                style={{ cursor: "not-allowed" }}
                                // onClick={() => {
                                //   this.fnOpenCloseConfirmDeleteDialog(
                                //     true,
                                //     tableCell.planId,
                                //     tableCell.planPurchaseDate
                                //   );
                                // }}
                                className={classes.icon}
                              />
                            </LightTooltip>
                          </TableCell>
                        </TableRow>
                        {expand &&
                        expandId === tableCell.planId &&
                        expandType === "upcoming" &&
                        this.state.expandIndex === ind ? (
                          <TableRow
                            style={{
                              boxShadow:
                                // tableCell.deviceDetails.length === 0
                                //   ? "none"
                                //   :
                                "0px 4px 8px rgba(0, 0, 0, 0.15)",
                              backgroundColor: "#FAFCFF",
                            }}
                          >
                            <TableCell colSpan={11} style={{ padding: 0 }}>
                              <Table>
                                <TableHead>
                                  <TableRow style={{ height: "35px" }}>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        IP Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        MAC Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Host Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Site Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Quota Used
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.deviceDetail.map(
                                    (innerData, ind) => (
                                      <TableRow
                                        key={ind}
                                        className={classes.innerTableBodyRow}
                                      >
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.ipAddress
                                            ? innerData.ipAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.macAddress
                                            ? innerData.macAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.hostname
                                            ? innerData.hostname
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.vesselName
                                            ? innerData.vesselName
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {this.fnConvertBytes(
                                            innerData.quotaUsed
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              {this.state.deviceDetail.length === 0 ? (
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  No Records Found
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
                {upcomingPlan.length === 0 ? (
                  <Typography
                    style={{
                      marginTop: "12px",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    No Records Found
                  </Typography>
                ) : null}
                </div>
              {/* : null} */}
              {/* {!isAnonymousUser
              ? */}
              <div className={classes.tableContainer}>
                <div className={classes.headingBlock}>
                  <Typography className={classes.heading}>
                    Expired Plans
                  </Typography>
                  <div className={classes.historyTextContainer}>
                    <Typography className={classes.historyText}>
                      Last 3 months
                    </Typography>
                  </div>
                </div>
                <Table>
                  <TableHead>
                    <TableRow className={classes.headerTableRow}>
                      {tableHeaders.map((tableCell, ind) => (
                        <TableCell
                          key={ind}
                          className={classes.headerTableCell}
                          align="center"
                          style={{ minWidth: tableCell.minWidth, display : tableCell.title === "Remaining Daily Limit" ? "none" : ""  }}
                        >
                          <Typography className={classes.headerTableCellText}>
                            {tableCell.title}
                          </Typography>
                          <Typography className={classes.tableBodyCellText}>
                            {tableCell.topupCount ? (
                              <LightTooltip
                                title={`Total Top-Up Quota: ${this.fnConvertBytes(
                                  tableCell.topupQuota
                                )}`}
                              >
                                <span className={classes.topUpFontSize}>
                                  {`(Top-ups- ${tableCell.topupCount})`}
                                </span>
                              </LightTooltip>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {expiredPlan.map((tableCell, ind) => (
                      <>
                        <TableRow key={ind} className={classes.tableRowBody}>
                          <TableCell className={classes.tableCellNoPaddingTop}>
                            <Typography className={classes.tableBodyCellText}>
                              {tableCell.planName}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planPurchaseDate
                                ? moment
                                    .utc(new Date(tableCell.planPurchaseDate))
                                    .format("MMM-DD-yyyy")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planStartDate
                                ? moment
                                    .utc(new Date(tableCell.planStartDate))
                                    .format("MMM-DD-yyyy")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <Typography
                              className={`${classes.tableBodyCellText}`}
                            >
                              {tableCell.planExpiryDate
                                ? moment
                                    .utc(new Date(tableCell.planExpiryDate))
                                    .format("MMM-DD-yyyy")
                                : "--"}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip
                              title={`Assigned Quota - ${this.fnConvertBytes(
                                tableCell.planQuota
                              )}`}
                            >
                              <Typography
                                className={`${classes.tableBodyCellText}`}
                              >
                                {this.fnConvertBytes(tableCell.remainingQuota)}
                              </Typography>
                            </LightTooltip>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={`${classes.tableCellNoPaddingTop}`}
                          >
                            <LightTooltip
                              title={
                                this.state.expand &&
                                this.state.expandId === tableCell.planId &&
                                this.state.expandType === "current" &&
                                this.state.expandIndex === ind
                                  ? "Close Session details"
                                  : "View Session details"
                              }
                            >
                              <img
                                src={
                                  this.state.expand &&
                                  this.state.expandId === tableCell.planId &&
                                  this.state.expandType === "expired" &&
                                  this.state.expandIndex === ind
                                    ? require("../../assets/images/close.png")
                                    : require("../../assets/images/expand.png")
                                }
                                alt="expand-close-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    this.state.expand &&
                                    this.state.expandId === tableCell.planId &&
                                    this.state.expandType === "expired" &&
                                    this.state.expandIndex === ind
                                  ) {
                                    this.fnOpenCloseNestedTable(
                                      false,
                                      tableCell.planId,
                                      "expired",
                                      ind,
                                      tableCell.planStartDate
                                    );
                                  } else {
                                    this.fnOpenCloseNestedTable(
                                      true,
                                      tableCell.planId,
                                      "expired",
                                      ind,
                                      tableCell.planStartDate
                                    );
                                  }
                                }}
                                className={classes.icon}
                              />
                            </LightTooltip>
                          </TableCell>
                        </TableRow>
                        {expand &&
                        expandId === tableCell.planId &&
                        expandType === "expired" &&
                        this.state.expandIndex === ind ? (
                          <TableRow
                            style={{
                              boxShadow:
                                // tableCell.deviceDetails.length === 0
                                //   ? "none"
                                //   :
                                "0px 4px 8px rgba(0, 0, 0, 0.15)",
                              backgroundColor: "#FAFCFF",
                            }}
                          >
                            <TableCell colSpan={11} style={{ padding: 0 }}>
                              <Table>
                                <TableHead>
                                  <TableRow style={{ height: "35px" }}>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        IP Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        MAC Address
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Host Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Site Name
                                      </Typography>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      className={classes.innerHeaderTableCell}
                                    >
                                      <Typography
                                        className={
                                          classes.innerHeaderTableCellText
                                        }
                                      >
                                        Quota Used
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.deviceDetail.map(
                                    (innerData, ind) => (
                                      <TableRow
                                        // key={index}
                                        className={classes.innerTableBodyRow}
                                      >
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.ipAddress
                                            ? innerData.ipAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.macAddress
                                            ? innerData.macAddress
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.hostname
                                            ? innerData.hostname
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {innerData.vesselName
                                            ? innerData.vesselName
                                            : "--"}
                                        </TableCell>
                                        <TableCell
                                          className={classes.innerTableBodyCell}
                                          align="center"
                                        >
                                          {this.fnConvertBytes(
                                            innerData.quotaUsed
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                              {this.state.deviceDetail.length === 0 ? (
                                <Typography
                                  style={{
                                    textAlign: "center",
                                    marginTop: "10px",
                                    fontSize: "12px",
                                  }}
                                >
                                  No Records Found
                                </Typography>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
                {expiredPlan.length === 0 ? (
                  <Typography
                    style={{
                      marginTop: "12px",
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    No Records Found
                  </Typography>
                ) : null}
              </div>
              {/* : null} */}
            </div>
            </div>
          : <Grid item xs={11} sm={11} md={6} lg={4} xl={4} style={{ marginTop: "60px" }}>  
            <div
              className={classes.root1}
            >
              <div className={classes.headingBlockMobile} style={{ borderBottom: currentPlan.length === 0 ? "1px solid #008FBDF2" : ""}}>
                <Typography className={classes.heading}>
                  Current Plan
                </Typography>
              </div>

              {currentPlan.map((plan, ind) => (
                <div style={{ border: "1px solid #008FBDF2", borderRadius: "15px", marginBottom: "15px", padding: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "5px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                          Plan Name:&nbsp;<br />
                          <span className={classes.value}>
                            {plan.planName}
                          </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Purchase Date:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.planPurchaseDate
                          ? moment
                              .utc(new Date(plan.planPurchaseDate))
                              .format("MMM-DD-yyyy")
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Top-ups:&nbsp;
                          <span className={classes.value}>
                          {plan.topupCount
                          ? plan.topupCount
                          : "NA"}
                          </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Top-up Quota:&nbsp;
                        <span className={classes.value}>
                          {plan.topupQuota
                            ? this.fnConvertBytes( plan.topupQuota )
                            : "NA"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Plan Start Date:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.planStartDate
                        ? moment
                            .utc(new Date(plan.planStartDate))
                            .format("MMM-DD-yyyy")
                        : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Plan Expiry Date:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.planExpiryDate
                          ? moment
                              .utc(new Date(plan.planExpiryDate))
                              .format("MMM-DD-yyyy")
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Assigned Quota:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.planQuota ? this.fnConvertBytes(plan.planQuota) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Remaining Quota:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.remainingQuota ? this.fnConvertBytes(plan.remainingQuota) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Assigned Daily Limit:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.dailyQuotaLimit ? this.fnConvertBytes(plan.dailyQuotaLimit) : "--"}{" "}
                        / {" "}
                        {plan.dailyTimeLimit ? this.fnConvertDailyTimeLimit(plan.dailyTimeLimit, "sec") : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Remaining Daily Limit:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.remainingDailyQuotaLimit ? this.fnConvertBytes(plan.remainingDailyQuotaLimit) : "--"}{" "}
                        / {" "}
                        {plan.remainingDailyTimeLimit ? this.fnConvertDailyTimeLimit(plan.remainingDailyTimeLimit, "sec") : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <Typography className={classes.cardItems}>
                      View Session Details:&nbsp;
                    </Typography>
                    <img
                      src={
                        this.state.expand &&
                        this.state.expandId === plan.planId &&
                        this.state.expandType === "current" &&
                        this.state.expandIndex === ind
                          ? require("../../assets/images/close.png")
                          : require("../../assets/images/expand.png")
                      }
                      alt="expand-close-icon"
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer" 
                      }}
                      onClick={() => {
                        if (
                          this.state.expand &&
                          this.state.expandId === plan.planId &&
                          this.state.expandType === "current" &&
                          this.state.expandIndex === ind
                        ) {
                          this.fnOpenCloseNestedTable(
                            false,
                            plan.planId,
                            "current",
                            ind,
                            plan.planStartDate,
                          );
                        } else {
                          this.fnOpenCloseNestedTable(
                            true,
                            plan.planId,
                            "current",
                            ind,
                            plan.planStartDate
                          );
                        }
                      }}
                      className={classes.icon}
                    />
                  </div>                  
                </div>
              ))}
              {currentPlan.length === 0 ? 
                <Typography
                  style={{
                    marginTop: "12px",
                    textAlign: "center",
                    fontSize: "12px",
                    marginBottom: "12px"
                  }}
                >
                  No Records Found
                </Typography>
                : null
              }

              {/* {!isAnonymousUser
              ?  */}
              <>
              <div className={classes.headingBlockMobile}  style={{ borderBottom: upcomingPlan.length === 0 ? "1px solid #008FBDF2" : ""}}>
                <Typography className={classes.heading}>
                  Upcoming Plans
                </Typography>
              </div>

              {upcomingPlan.map((plan, ind) => (
                <div style={{ border: "1px solid #008FBDF2", borderRadius: "15px", marginBottom: "15px", padding: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "5px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                          Plan Name:&nbsp;<br />
                          <span className={classes.value}>
                            {plan.planName}
                          </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Purchase Date:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.planPurchaseDate
                          ? moment
                              .utc(new Date(plan.planPurchaseDate))
                              .format("MMM-DD-yyyy")
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Assigned Quota:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.planQuota ? this.fnConvertBytes(plan.planQuota) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Assigned Daily Limit:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.dailyQuotaLimit ? this.fnConvertBytes(plan.dailyQuotaLimit) : "--"} / {plan.dailyTimeLimit ? this.fnConvertDailyTimeLimit(plan.dailyTimeLimit, "sec") : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Duration:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.duration
                          ? plan.duration +
                            this.fnSetDaysUnit(plan.duration)
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>             
                </div>
              ))}
              {upcomingPlan.length === 0 ? 
                <Typography
                  style={{
                    marginTop: "12px",
                    textAlign: "center",
                    fontSize: "12px",
                    marginBottom: "12px"
                  }}
                >
                  No Records Found
                </Typography>
                : null
              }
              </>
              {/* : null} */}

              {/* {!isAnonymousUser
              ?  */}
              <>
              <div className={classes.headingBlockMobile} style={{ marginBottom: "6px", borderBottom: expiredPlan.length === 0 ? "1px solid #008FBDF2" : "" }}>
                <Typography className={classes.heading} style={{ marginBottom: "0px" }}>
                  Expired Plans
                </Typography>
                <div className={classes.historyTextContainerMobileView}>
                  <Typography className={classes.historyText}>
                    Last 3 months
                  </Typography>
                </div>
              </div>

              {expiredPlan.map((plan, ind) => (
                <div style={{ border: "1px solid #008FBDF2", borderRadius: "15px", marginBottom: "15px", padding: "10px", display: expiredPlan.length === 0 ? "none" : "" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "5px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                          Plan Name:&nbsp;<br />
                          <span className={classes.value}>
                            {plan.planName}
                          </span>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Purchase Date:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.planPurchaseDate
                          ? moment
                              .utc(new Date(plan.planPurchaseDate))
                              .format("MMM-DD-yyyy")
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Plan Start Date:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.planStartDateFormat}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Plan Expiry Date:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.planExpiryDateFormat}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Assigned Quota:&nbsp;<br />
                        <span className={classes.value}>
                        {plan.planQuota ? this.fnConvertBytes(plan.planQuota) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Remaining Quota:&nbsp;<br />
                        <span className={classes.value}>
                          {plan.remainingQuota ? this.fnConvertBytes(plan.remainingQuota) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>

                  <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
                    <Typography className={classes.cardItems}>
                      View Session Details:&nbsp;
                    </Typography>
                    <img
                      src={
                        this.state.expand &&
                        this.state.expandId === plan.planId &&
                        this.state.expandType === "expired" &&
                        this.state.expandIndex === ind
                          ? require("../../assets/images/close.png")
                          : require("../../assets/images/expand.png")
                      }
                      alt="expand-close-icon"
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer" 
                      }}
                      onClick={() => {
                        if (
                          this.state.expand &&
                          this.state.expandId === plan.planId &&
                          this.state.expandType === "expired" &&
                          this.state.expandIndex === ind
                        ) {
                          this.fnOpenCloseNestedTable(
                            false,
                            plan.planId,
                            "expired",
                            ind,
                            plan.planStartDate,
                          );
                        } else {
                          this.fnOpenCloseNestedTable(
                            true,
                            plan.planId,
                            "expired",
                            ind,
                            plan.planStartDate
                          );
                        }
                      }}
                      className={classes.icon}
                    />
                  </div>                  
                </div>
              ))}
              {expiredPlan.length === 0 ? 
                <Typography
                  style={{
                    marginTop: "12px",
                    textAlign: "center",
                    fontSize: "12px",
                  }}
                >
                  No Records Found
                </Typography>
                : null
              }
              </>
              {/* : null} */}
            </div>
            </Grid>}
        </div>
        {openDeleteDialog.open ? (
          <DialogDelete
            open={openDeleteDialog.open}
            onClose={() => this.fnOpenCloseConfirmDeleteDialog(false, "", null, "")}
            confirmDelete={() => this.fnDeletePlan()}
            type="plan"
            isAssignedToUser={isAssignedToUser}
          />
        ) : null}
        {showSessionDetailsPopup ? (
          <Dialog
            open={showSessionDetailsPopup}
            classes={{ paper: classes.paper }}
            disableEscapeKeyDown
          >
            <Grid
              container
              style={{
                padding: "10px 16px",
                background:
                  "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: "#FFFFFF",
                  }}
                >
                  Session Details
                </Typography>
              </Grid>
              <Grid item>
                <img
                  onClick={() => this.fnCloseDialog()}
                  src={require("../../assets/images/close-round.png")}
                  alt="close-icon"
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <div className={classes.dialogBody} style={{ height: deviceDetail.length === 0 ? "60px" : "180px", overflowY: deviceDetail.length === 0 ? "hidden" : "auto"  }}>
              {deviceDetail && deviceDetail.map((innerData) => (
                <div style={{ border: "1px solid #008FBDF2", borderRadius: "5px", marginBottom: "5px", padding: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        IP Address:&nbsp;<br />
                        <span className={classes.value}>
                        {innerData.ipAddress
                          ? innerData.ipAddress
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        MAC Address:&nbsp;<br />
                        <span className={classes.value}>
                        {innerData.macAddress
                          ? innerData.macAddress
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Host Name:&nbsp;<br />
                        <span className={classes.value}>
                        {innerData.hostname
                          ? innerData.hostname
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2} style={{ marginLeft: "5px" }}>
                      <Typography className={classes.cardItems}>
                        Site Name:&nbsp;<br />
                        <span className={classes.value}>
                        {innerData.vesselName
                          ? innerData.vesselName
                          : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                    <Grid item xs={6} sm={6} md={6} lg={2} xl={2}>
                      <Typography className={classes.cardItems}>
                        Quota Used:&nbsp;<br />
                        <span className={classes.value}>
                        {innerData.quotaUsed ? this.fnConvertBytes(
                          innerData.quotaUsed
                        ) : "--"}
                        </span>
                      </Typography>
                    </Grid>
                  </div>
                </div>
              ))}
              {deviceDetail.length === 0 ? (
                <Typography
                  style={{
                    textAlign: "center",
                    paddingTop: "10px",
                    fontSize: "12px",
                  }}
                >
                  No Records Found
                </Typography>
              ) : null}
            </div>
          </Dialog>
        ) : null}
        {showHideSnackbar.show ? (
          <CustomSnackbar
            open={showHideSnackbar.show}
            variant={showHideSnackbar.variant}
            message={showHideSnackbar.message}
            onClose={this.fnHideSnackbar}
          />
        ) : null}
        {isLoading ? <CustomLoader showLoader={isLoading} /> : null}
      </>
    );
  }
}

export default withStyles(PlanHistory);
