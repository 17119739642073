import React, { Component } from "react";
import {
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
  TextField,
  withStyles as muiStyles,
  Button,
  CircularProgress,
  MenuItem,
  Radio,
  Dialog,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import withStyles from "../styles/UserAuth";
import {
  API_AUTH_FORGOT_PASSWORD,
  API_AUTH_LOGIN,
  API_AUTH_LOGOUT,
  API_AUTH_PASSWORD_RECOVERY,
  API_AUTH_SIGNUP,
  API_BASE_URL,
  API_FETCH_SELF_SIGN_UP_DETAILS,
  API_GET_PLANS_HISTORY,
  API_GET_SECURITY_QUESTIONS,
  API_GET_VLANS,
  API_IS_USER_LOGGED_IN,
  API_SET_SECURITY_QUESTIONS,
  API_USER_TRIGGER_RESET_PASSWORD,
} from "../../constants/ApiEndPoints";
import { callGetApi, callPostApi } from "../../managers/api/ApiManager";
import {
  getSessionData,
  saveSessionData,
} from "../../managers/session/SessionManager";
import asyncComponent from "../AsyncComponent";
import CustomLoader from "../CustomLoader/CustomLoader";

const CustomSnackbar = asyncComponent(() => import("../snackbar/CustomSnackbar"));
const DialogUserSignedIn = asyncComponent(() => import("./DialogUserSignedIn"));
const DialogUserAuthentication = asyncComponent(() => import("./DialogUserAuthentication"));
const DialogCloseWindow = asyncComponent(() => import("./DialogCloseWindow"));
const DialogIsAccessNetwork = asyncComponent(() => import("./DialogIsAccessNetwork"));

// import DialogUserAuthentication from "./DialogUserAuthentication";
// import CustomSnackbar from "../snackbar/CustomSnackbar";
// import DialogCloseWindow from "./DialogCloseWindow";
// import DialogIsAccessNetwork from "./DialogIsAccessNetwork";

const k4EdgeLogo = require("../../assets/images/k4-logo.png");

const CustomCheckbox = muiStyles({
  root: {
    color: "#384089",
    padding: 0,
    marginRight: "11px",
    "&$checked": {
      color: "#384089",
    },
    "&$disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  checked: {},
  disabled: {},
})((props) => <Checkbox color="default" {...props} />);

export function updateUserInfoAsync() {
  return new Promise((res, rej) => {
    const url = "http://10.0.254.1/getCaptiveAccess";
    const newWindow = window.open(url, "popup", "width=60,height=50");
    let updated = false;
    newWindow.onbeforeunload = () => {
      if (newWindow.location.href === url && !updated) {
        // in case user closes the new window
        rej();
        return 'user data is not updated';
      }
    }
    // periodically check if the window is redirected or not
    const intervalRef = setInterval(function () {
      if (newWindow.location.href !== url) {
        updated = true;
        clearInterval(intervalRef);
        newWindow.close();
        res();
      }
    }, 1000);
  })
}
class UserAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserAuthenticated: { open: false, auth: false, msg: "" },
      agreeTermsAndConditions: false,
      username: "",
      password: "",
      showPassword: false,
      showHideSnackbar: {
        message: "",
        variant: "",
        show: false,
      },
      dialogCloseWindow: false,
      showViewAccount: false,
      currentPlan: [],
      isAccessNetwork: false,
      isLoading: false,
      loggedInDetails: {},
      isLoggedIn: false,
      // logoSrc: `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData("USER_LOGIN_DP_ID")}/edge/CANuserloginLogo.png?`,
      logoSrc: API_BASE_URL + "edgeIcon/CANuserloginLogo.png?",
      displaySignUp: false,
      displayLogin: true,
      displaySecurityQuestions: false,
      displayPasswordRecovery: false,
      displayUpdatePassDialog: false,
      signUpFields: [],
      signUpFnameLname: "",
      showConfirmPassword: false,
      fullPageLoader: false,
      securityQuestions: {},
      selectedSecurityQues: {},
      isUserSignedIn: { open: false, auth: false, msg: "" },
      k4id: "",
      updatePassword: "",
      confirmupdatepassword: "",
      showResetPWsuccessDialog: false,
      passwordResetByAdmin: false,
      isResetPasswordChecked: false,
      showInfoDialogInUserAuth: { open: false, msg: "" },
      showPasswordUpdateSuccess: false,
      selfsignup: false,
      domain: "",
      _firstApiFailure: 1,
      screenSize: undefined
      // userRecId: "",
    };
  }

  componentDidMount() {
    // this.fnGetVlans();
    this.fnIsUserLoggedIn();
    //User Sign Up Flow --->
    this.fnGetSignUpInfo(true)
    let decryptObj = getSessionData("DecryptObj")
    ? getSessionData("DecryptObj")
    : null;
    let k4id = decryptObj ? decryptObj.k4id : "";
    //1. Landing in to Auth page -> New User,Sign Up -> No Email Signup -> Security Questions -> Welcome Screen.
    let json = require("../../components/auth/SecurityQuestions.json");
    let tempQues = {};
    for (let i=0; i<Object.keys(json).length; i++) {
      tempQues[Object.keys(json)[i]] = "";
    }
    this.setState({
      securityQuestions: tempQues,
      k4id,
      screenSize: window.innerWidth
    }, () => {
      console.log("TEST????///", tempQues)
    })
  }

  resetData = () => {
    this.setState({
      displaySignUp: false,
      displayLogin: true,
      displaySecurityQuestions: false,
      displayPasswordRecovery: false,
      displayUpdatePassDialog: false,
      showResetPWsuccessDialog: false,
      passwordResetByAdmin: false,
      agreeTermsAndConditions: false
    })
  }

  fnOpenUserAuthDialog = (open, auth, msg, isLoggedIn, triggerAfterSignup = false) => {
    if (triggerAfterSignup) this.setState({ isUserSignedIn: { open, auth, msg }, isLoggedIn })
    else this.setState({ isUserAuthenticated: { open, auth, msg }, isLoggedIn });
    // this.fnOpenUserAuthDialog(false, false)
  };

  fnOnCheckboxChange = (event) => {
    // this.setState({})
    console.log("event :>> ", event.target.checked);
    let agreeTermsAndConditions = event.target.checked;
    this.setState({ agreeTermsAndConditions });
  };

  fnOnInputChange = (event) => {
    const { name, value } = event.target;
    // state variable set according to name given to input
    this.setState(
      {
        [name]: value,
      },
      () => {
        console.log(
          "this.state.username :>> ",
          this.state.username,
          this.state,
          name,
          value
        );
      }
    );
  };

  fnValidateEmailId = (emailID) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(emailID);
  };

  fnOnInputChangeSignUp = (event) => {
    const { name, value } = event.target;
    // state variable set according to name given to input
    let selectedIndex = this.state.signUpFields.findIndex((obj) => name === obj.name)
    let tempSignUpFields = this.state.signUpFields;
    tempSignUpFields[selectedIndex].value = value

    // //validation.
    if (name === "Password" && (value.length > 1 && value.length < 8)) {
      tempSignUpFields[selectedIndex].isError = true;
      tempSignUpFields[selectedIndex].msg = "*Password should have at least 8 characters"
    } else {
      tempSignUpFields[selectedIndex].isError = false;
      tempSignUpFields[selectedIndex].msg = ""
    }

    if (name === "Email Address") {
      if (!this.fnValidateEmailId(value)) {
        tempSignUpFields[selectedIndex].isError = true;
        tempSignUpFields[selectedIndex].msg = "*Please enter valid Email ID."
      } else if (this.state.domain !== "") {
        if (this.state.domain.includes(",")) {
          console.log("TESting1", this.state.domain)
          let domains = this.state.domain.split(",")
          let checkIfDomainExist = false;
          console.log("TESting2", domains)
          domains.forEach( ele => {
            if (ele !== "") {
              if (value && value.toLowerCase().includes(ele)) {
                checkIfDomainExist = true
                console.log("TESting3", value, checkIfDomainExist, ele)
              }
            }
          })
          console.log("TESting4", checkIfDomainExist)
          if (!checkIfDomainExist) {
            tempSignUpFields[selectedIndex].isError = true;
            tempSignUpFields[selectedIndex].msg = "*Please enter valid Email ID."
          }
        } else {
          if (value && !value.toLowerCase().includes(this.state.domain.toLowerCase())) {
            tempSignUpFields[selectedIndex].isError = true;
            tempSignUpFields[selectedIndex].msg = "*Please enter valid Email ID."
          }
        }
      }
    }

    this.setState(
      {
        signUpFields: tempSignUpFields,
      },
      () => {
        console.log(
          "this.state.username :>> ",
          this.state.signUpFields
        );
      }
    );
  };

  fnOnInputChangeSecurityQues = (event) => {
    const { name, value } = event.target;
    let tempSecurityQues = this.state.securityQuestions;
    tempSecurityQues[name] = value
    this.setState({
      securityQuestions: tempSecurityQues,
    });
  };

  fnOnSelectSecurityQues = (event, index) => {
    const { value } = event.target;
    let temp = {...this.state.selectedSecurityQues}

    // Checking if any ans added by user for previous code, retaining that answer for the updated code also.
    // let _previousAnswer = temp && Object.keys(temp).length > 0 ? temp[Object.keys(temp)[index]] : ""
    // console.log("selectedQues:::",temp, _previousAnswer)
    
    let json = require("../../components/auth/SecurityQuestions.json");
    let _getSecurityCode = ""
    Object.keys(json).map((securityCode) => {
      if (json[securityCode] === value) {
        _getSecurityCode = securityCode;
      }
    })

    // Replace the key with another if a key already exists in the position.
    if (temp && Object.keys(temp)[index] !== undefined) {
      Object.keys(temp).map((securityCode, idx) => {
        if (idx === index) {
          console.log("selectedQues:::",temp,securityCode, _getSecurityCode )
          temp = JSON.parse(JSON.stringify(temp).replace(securityCode, _getSecurityCode))
          console.log("selectedQues:::",temp, )
        }
      })
    } else {
      // temp is empty.
      temp = {
        ...temp,
        [_getSecurityCode] : ""
      }
      console.log("selectedQues:::",temp, )
    }
    
    this.setState({
      selectedSecurityQues: temp
    }, () => {
      console.log("selectedQues:::", this.state.selectedSecurityQues, temp)
    })

  }

  fnUpdateSelectedSecurityQues = (event, index) => {
    const { name, value } = event.target;
    let temp = this.state.selectedSecurityQues;
    temp[name] = value
    this.setState({
      selectedSecurityQues: temp
    }, () => {
      console.log("selectedQues:::", this.state.selectedSecurityQues, temp)
    })
  }



  fnGetPassword = () => {
    let tempSignUpFields = this.state.signUpFields;
    let password = tempSignUpFields.find((obj) => obj.name === "Password");
    return password.value
  }

  fnLogin = (triggerAfterSignup = false) => {
    // updateUserInfoAsync().then(() => {
      let decryptObj = getSessionData("DecryptObj")
        ? getSessionData("DecryptObj")
        : null;
      let payload = {};
      let msg = "";
      let loggedInDetails = {
        planDetails: { planQuota: null, planEndDate: "", topupQuota: 0 },
        userRecId: "",
        ipAddr: "",
      };
      if (triggerAfterSignup === false) this.setState({ isLoading: true });
      else this.setState({ fullPageLoader: true })
      payload.username = this.state.username;
      payload.password = triggerAfterSignup ? this.fnGetPassword() : this.state.password;
      payload.ipAddress = decryptObj ? decryptObj.dev_ip : "";
      payload.deviceID = decryptObj ? decryptObj.device_id : "";
      payload.vesselName = decryptObj ? decryptObj.vessel : "";
      payload.k4id = decryptObj ? decryptObj.k4id : "";
      payload.k4dpid = decryptObj ? decryptObj.k4dpid : "";
      payload.device = this.state.screenSize < "450" ? "mobile" : "other";
      payload.macAddress = decryptObj && decryptObj.mac ? decryptObj.mac.toUpperCase() : "";
      console.log("payload???", payload, this.state.password);
      if (decryptObj && decryptObj.access_network === "false") {
        this.fnIsAccessNetwork(true);
        if (triggerAfterSignup === false) this.setState({ isLoading: false });
        else this.setState({ fullPageLoader: false })
        setTimeout(() => {
          this.fnIsAccessNetwork(false);
        }, 5000);
      } else {
        this.setState({ isAccessNetwork: false });
        callPostApi(
          "UserAuthentication.jsx",
          "TAG_API_AUTH_LOGIN",
          API_AUTH_LOGIN,
          payload
        )
          .then((res) => {
            if (triggerAfterSignup === false) this.setState({ isLoading: false });
            else this.setState({ fullPageLoader: false })
            if (res && res.status === 1) {
              // this.fnGetAccountDetails();
              loggedInDetails.planDetails.topupQuota =
                res.data && res.data.planDetails && res.data.planDetails.topupQuota
                  ? res.data.planDetails.topupQuota
                  : 0;
              loggedInDetails.planDetails.planQuota =
                res.data && res.data.planDetails && res.data.planDetails.planQuota
                  ? res.data.planDetails.planQuota +
                  loggedInDetails.planDetails.topupQuota
                  : 0;
              loggedInDetails.remainingQuota =
                res.data && res.data.planDetails && res.data.remainingQuota
                  ? res.data.remainingQuota
                  : 0;
              loggedInDetails.planDetails.planEndDate =
                res.data && res.data.planDetails && res.data.planDetails.planEndDate
                  ? res.data.planDetails.planEndDate
                  : 0;
              loggedInDetails.planDetails.accountExpirationDate =
                res.data && res.data.accountExpirationDate
                  ? res.data.accountExpirationDate
                  : 0;
              loggedInDetails.planDetails.dailyQuotaLimit =
                res.data && res.data.planDetails && res.data.planDetails.dailyQuotaLimit
                  ? res.data.planDetails.dailyQuotaLimit
                  : null;
              loggedInDetails.planDetails.dailyTimeLimit =
                res.data && res.data.planDetails && res.data.planDetails.dailyTimeLimit
                  ? res.data.planDetails.dailyTimeLimit
                  : null;
              loggedInDetails.remainingDailyQuotaLimit =
                res.data && res.data.planDetails && res.data.remainingDailyQuotaLimit
                  ? res.data.remainingDailyQuotaLimit
                  : 0;
              loggedInDetails.remainingDailyTimeLimit =
                res.data && res.data.planDetails && res.data.remainingDailyTimeLimit
                  ? res.data.remainingDailyTimeLimit
                  : 0;
              loggedInDetails.userRecId = res.data ? res.data._id : "";
              loggedInDetails.ipAddr = decryptObj ? decryptObj.dev_ip : "";
              loggedInDetails.username = res.data ? res.data.username : "";
              loggedInDetails.loginAccess = res.data ? res.data.loginAccess : false;
              loggedInDetails.allowedDevices = res.data && res.data.allowedDevices ? res.data.allowedDevices : 1;
              loggedInDetails.anonymous = res.data && res.data.anonymous ? res.data.anonymous : false;
              saveSessionData("NON_ADMIN_USER_INFO", loggedInDetails);
              saveSessionData("USER_LOGIN_DP_ID", res.data ? res.data.adminDpId : null)
              this.setState(
                {
                  loggedInDetails,
                  username: res.data ? res.data.username : "",
                  displaySecurityQuestions: false
                },
                () => {
                  this.fnOpenUserAuthDialog(true, true, msg, false, triggerAfterSignup);
                  // localStorage.removeItem("DecryptObj");
                }
              );
            } else if (res && res.status === 0) {
              console.log("res :>> ", res);
              if (res && res.data && res.data[0] === 1) {
                msg = res.data[1];
              }
              this.fnOpenUserAuthDialog(true, false, msg, false, triggerAfterSignup);
              localStorage.setItem("AUTH_FAIL_MESSAGE", msg);
            } else {
              console.log("res :>> ", res);
              this.fnOpenUserAuthDialog(true, false, msg, false, triggerAfterSignup);
              localStorage.setItem("AUTH_FAIL_MESSAGE", msg);
            }
          })
          .catch((err) => {
            if (triggerAfterSignup === false) this.setState({ isLoading: false });
            else this.setState({ fullPageLoader: false })
            console.log("err :>> ", err);
            this.fnOpenUserAuthDialog(true, false, msg, false, triggerAfterSignup);
            localStorage.setItem("AUTH_FAIL_MESSAGE", msg);
          });
      }
    // })
    // this.fnOpenUserAuthDialog(true, true);
  };

  fnCloseWindowTab = () => {
    console.log("window :>> ", window);
    // window.close();
    window.open("", "_self").close();
  };

  fnShowHidePassword = (state = "") => {
    if (state === "") {
      let showPassword = !this.state.showPassword;
      this.setState({ showPassword });
    } else {
      let showPassword = !this.state.showConfirmPassword;
      this.setState({ showConfirmPassword: showPassword });
    }
  };

  fnHandleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  fnLogoutUser = () => {
    let payload = {};
    let userInfo = getSessionData("NON_ADMIN_USER_INFO");
    // let deviceIp = localStorage.getItem("DeviceIp");
    let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;
    payload.userRecId = userInfo.userRecId;
    payload.ipAddress = userInfo.ipAddr;
    payload.macAddress = decryptObj && decryptObj.mac ? decryptObj.mac.toUpperCase() : "";
    console.log("userInfo :>> ", userInfo);
    console.log("userInfo payload :>> ", payload);
    // this.setState({
    //   fullPageLoader: true,
    // })
    callPostApi(
      "UserAuthentication.jsx",
      "TAG_API_AUTH_LOGOUT",
      API_AUTH_LOGOUT,
      payload
    )
      .then((res) => {
        if (res && res.status === 1) {
          console.log("res :>> ", res);
          // localStorage.removeItem("DeviceIp");
          localStorage.removeItem("NON_ADMIN_USER_INFO");
          localStorage.removeItem("AUTH_FAIL_MESSAGE");
          // localStorage.removeItem("DeviceID");
          this.fnShowSnackbar(res.msg ? res.msg : "User Logged Out", "success");
          // window.location.reload();
          window.location.replace("http://10.0.254.1/user_management/getAccess")
          setTimeout(() => {
            this.fnOpenUserAuthDialog(false, false, "");
            this.fnOpenCloseDialogCloseWindow(false);
            this.setState({ showViewAccount: false });
          }, 1000);
        } else if (res && res.status === 0) {
          console.log("res :>> ", res);
          this.fnShowSnackbar(res.msg ? res.msg : "Please try again");
        } else {
          console.log("res :>> ", res);
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
        this.fnShowSnackbar("Please try again");
      })

      // setTimeout(() => {
      //   // if (this.state._firstApiFailure === 1) 
      //   this.fetchEdgeAPItoRemovePolicy(userInfo.ipAddr);
      //   // this.setState({
      //   //   _firstApiFailure : this.state._firstApiFailure + 1
      //   // })
      //   setTimeout(() => {
      //     this.setState({
      //       fullPageLoader: true,
      //     })
      //     localStorage.removeItem("NON_ADMIN_USER_INFO");
      //     localStorage.removeItem("AUTH_FAIL_MESSAGE");
      //     window.location.replace("http://10.0.254.1/user_management/getAccess")
      //   }, 5000);
      // }, 2000);
  };

  fetchEdgeAPItoRemovePolicy = (ip) => {
    let URL = "http://10.0.254.1/user_management/updateUserdeviceIPpolicy";
    let payload = {
      "ip_address": ip
    }
    callPostApi(
      "UserAuthentication.jsx",
      "TAG_API_AUTH_LOGOUT",
      URL,
      payload
    )
      .then((res) => {})
      .catch((err) => {

      })
  }

  fnShowSnackbar = (msg = "", variant = "error") => {
    this.setState(
      {
        showHideSnackbar: {
          message: msg,
          variant,
          show: true,
        },
      },
      () =>
        console.log("this.state.showHideSnackbar", this.state.showHideSnackbar)
    );
  };

  fnHideSnackbar = () => {
    this.setState({
      showHideSnackbar: {
        message: "",
        variant: "",
        show: false,
      },
    });
  };

  fnGetVlans = () => {
    callGetApi(
      "UserAuthentication.jsx",
      "TAG_API_GET_VLANS_GATEWAY",
      API_GET_VLANS
    )
      .then((res) => {
        console.log("response =======>", res);
        if (res && res.status === 1) {
          if (res.data && res.data.deviceIp) {
            localStorage.setItem("DeviceIp", res.data.deviceIp);
          }
          if (res.data && res.data.deviceId) {
            localStorage.setItem("DeviceID", res.data.deviceId);
          }
        }
      })
      .catch((err) => {
        console.log("error =======> ", err);
      });
  };

  fnOpenCloseDialogCloseWindow = (dialogCloseWindow) => {
    this.setState({ dialogCloseWindow });
  };

  fnIsUserLoggedIn = () => {
    let userData = getSessionData("NON_ADMIN_USER_INFO")
      ? getSessionData("NON_ADMIN_USER_INFO")
      : "";
    let msg = "";
    let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;
    let payload = {};
    if (userData && userData.userRecId) {
      // this.fnGetAccountDetails();
      // this.fnOpenUserAuthDialog(true, true, msg);
      payload.userRecId = userData.userRecId;
      payload.ipAddress = userData.ipAddr;
      payload.macAddress = decryptObj && decryptObj.mac ? decryptObj.mac.toUpperCase() : "";
      callPostApi(
        "UserAuthentication.jsx",
        "TAG_API_IS_USER_LOGGED_IN",
        API_IS_USER_LOGGED_IN,
        payload
      )
        .then((res) => {
          if (res && res.status === 1) {
            if (res.data && res.data.loggedIn) {
              this.setState({ loggedInDetails: userData }, () =>
                this.fnOpenUserAuthDialog(
                  true,
                  true,
                  "You are already logged in.",
                  true
                )
              );
            } else if (res.data && res.data.loggedIn === false) {
              localStorage.removeItem("NON_ADMIN_USER_INFO");
              this.fnOpenUserAuthDialog(
                true,
                false,
                "Your session has expired.",
                true
              );
            }
          } else if (res && res.status === 0) {
            localStorage.removeItem("NON_ADMIN_USER_INFO");
            this.fnOpenUserAuthDialog(
              true,
              false,
              res.msg ? res.msg : "",
              false
            );
          } else {
            localStorage.removeItem("NON_ADMIN_USER_INFO");
            this.fnOpenUserAuthDialog(
              true,
              false,
              res.msg ? res.msg : "",
              false
            );
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
          localStorage.removeItem("NON_ADMIN_USER_INFO");
        });
    }
  };

  fnGetAccountDetails = () => {
    let userInfo = getSessionData("NON_ADMIN_USER_INFO")
      ? getSessionData("NON_ADMIN_USER_INFO")
      : "";
    let userId = "";
    if (userInfo) {
      this.fnOpenUserAuthDialog(true, true);
      userId = userInfo._id;
      let payload = { userId };
      let currentPlan = [];
      let upcomingPlan = [];
      let expiredPlan = [];
      payload["dpIds"] = getSessionData("DP_ID") ? [getSessionData("DP_ID")] : [];
      callPostApi("", "", API_GET_PLANS_HISTORY, payload)
        .then((res) => {
          if (res && res.status === 1) {
            console.log("res :>> ", res);
            currentPlan = res.data.currentPlan ? res.data.currentPlan : [];
            upcomingPlan = res.data.upcomingPlan ? res.data.upcomingPlan : [];
            expiredPlan = res.data.expiredPlan ? res.data.expiredPlan : [];
            currentPlan.map((obj) => {
              obj.planStartDate = new Date();
              obj.planEndDate = new Date(
                new Date().setDate(new Date().getDate() + 10)
              );
            });
            console.log("currentPlan :>> ", currentPlan);
            this.setState({
              currentPlan,
              // upcomingPlan,
              // expiredPlan,
              // isLoading: false,
            });
          } else if (res && res.status === 0) {
            console.log("res :>> ", res);
          } else {
            console.log("res :>> ", res);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  fnIsAccessNetwork = (isAccessNetwork) => {
    this.setState({ isAccessNetwork });
  };

  fnRenderInputs(stateName, classes, placeholder) {
    
    let selectedIndex = this.state.signUpFields.findIndex((obj) => stateName === obj.name)
    console.log("stateName :>> ", stateName, selectedIndex, this.state.signUpFields);
    return (
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <TextField
          value={this.state.signUpFields[selectedIndex].value}
          InputProps={{
            disableUnderline: true,
            fullWidth: true,
            endAdornment:
              stateName === "Password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.fnShowHidePassword()}
                    onMouseDown={(event) =>
                      this.fnHandleMouseDownPassword(event)
                    }
                  >
                    {this.state.showPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : 
              stateName === "Confirm Password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.fnShowHidePassword(stateName)}
                    onMouseDown={(event) =>
                      this.fnHandleMouseDownPassword(event)
                    }
                  >
                    {this.state.showConfirmPassword ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                </InputAdornment>
              ) 
              : (
                ""
              ),
            style: {
              fontSize: "12px",
            },
          }}
          className={classes.inputContainerSignUp}
          name={stateName}
          onChange={(e) => {
            this.fnOnInputChangeSignUp(e);
          }}
          placeholder={placeholder}
          type={
            stateName === "Password"
            ? this.state.showPassword
              ? "text"
              : "password"
            : stateName === "Confirm Password"
              ? this.state.showConfirmPassword
                ? "text"
                : "password"
              : "text"
          }
          onKeyPress={(e) => {
            if (stateName === "Phone Number") {
              var regex = new RegExp("^[0-9]+$");
              var str = String.fromCharCode(
                !e.charCode ? e.which : e.charCode
              );
              if (regex.test(str) && e.target.value.length < 10) {
                return true;
              }

              e.preventDefault();
              return false;
            }
          }}
        />
      </form>
    );
  }

  fnGetSignUpInfo = (fromReload = false) => {
    let decryptObj = getSessionData("DecryptObj")
    ? getSessionData("DecryptObj")
    : null;
    this.setState({
      fullPageLoader: true,
    })
    let URL = API_FETCH_SELF_SIGN_UP_DETAILS + `?k4Id=${decryptObj ? decryptObj.k4id : ""}`
    callPostApi(
      "DialogSelfSignUp.jsx", 
      "TAG_FETCH_SELF_SIGN_UP_DETAILS", 
      API_FETCH_SELF_SIGN_UP_DETAILS,
      {
        siteIds: decryptObj ? [decryptObj.k4id] : []
      } 
    )
    .then((res) => {
      this.setState({
        fullPageLoader: false,
      })
      if (res && res.status === 1) {
        if (res.data) {
          if (res.data.selfSignUp === false) {
            // this.setState({
            //   showInfoDialogInUserAuth: { 
            //     open: true,
            //     // header: "Self Sign Up" 
            //     msg: "This Action cannot be performed. Contact Admin for assistance." 
            //   }
            // })
            this.setState({
              selfsignup: false
            })
            saveSessionData("SELF_SIGN_UP_ACCESS", false);
          } else {
            saveSessionData("SELF_SIGN_UP_ACCESS", true);
            if (!fromReload) {
      //   let res = {
      //     "status": 1,
      //     "data": {
      //         "_id": "63f33f895ce57cebcdbef185",
      //         "dpId": "DP-0001",
      //         "__v": 0,
      //         "adminApproval": true,
      //         "counter": 6,
      //         "createdAt": "2023-02-20T09:38:17.232Z",
      //         "expiryDuration": 1,
      //         "planRecId": "63cffbe3bc54df37d6a1b05d",
      //         "requiredInfo": [
      //             {
      //                 "name": "Email Address",
      //                 "state": "mandatory"
      //             },
      //             {
      //                 "name": "First Name",
      //                 "state": "mandatory"
      //             },
      //             {
      //                 "name": "Last Name",
      //                 "state": "mandatory"
      //             },
      //             {
      //                 "name": "Phone Number",
      //                 "state": "notinuse"
      //             }
      //         ],
      //         "selfSignUp": true,
      //         "updatedAt": "2023-03-07T07:11:40.494Z",
      //         "accountExpiry": false,
      //         "allowedDevices": 1
      //     },
      //     "msg": ""
      // }
              let tempSignUpFields = [];
              let _checkFnameLname = '';
              let len = res.data.requiredInfo ? res.data.requiredInfo.length : 0
              for (let i=0; i< res.data.requiredInfo.length; i++) {
                tempSignUpFields[i] = res.data.requiredInfo[i];
                tempSignUpFields[i]["value"] = "";
                tempSignUpFields[i]["isError"] = false;
                tempSignUpFields[i]["msg"] = "";
                if ((res.data.requiredInfo[i].name === "First Name" && res.data.requiredInfo[i].state !== "notinuse")) {
                  if (_checkFnameLname !== "") _checkFnameLname = "both"
                  else _checkFnameLname = "Fname"
                } else if ((res.data.requiredInfo[i].name === "Last Name" && res.data.requiredInfo[i].state !== "notinuse")) {
                  if (_checkFnameLname !== "") _checkFnameLname = "both"
                  else _checkFnameLname = "Lname"
                }
              }
              console.log("TEST????///", len, tempSignUpFields)
              tempSignUpFields[len] = {
                "name": "Password",
                "state": "mandatory",
                "value": "",
                "isError": false,
                "msg": ""
              }
              tempSignUpFields[len+1] = {
                "name": "Confirm Password",
                "state": "mandatory",
                "value": "",
                "isError": false,
                "msg": ""
              }
              this.setState({
                signUpFields: tempSignUpFields,
                signUpFnameLname: _checkFnameLname,
                displaySignUp: true,
                displayLogin: false,
                selfsignup: true,
                domain: res.data.domain ? res.data.domain : ""
              }, () => {
                console.log("TEST????///", this.state.signUpFields)
              })
            } else {
              this.setState({
                selfsignup: true
              })
            }
          }
        }
      } else {
        this.setState({
          selfsignup: false
        })
        saveSessionData("SELF_SIGN_UP_ACCESS", false);
        // this.fnShowSnackbar("", "error");
      }
    })
    .catch((err)=> {
      this.setState({
        fullPageLoader: false,
        selfsignup: false
      })
      saveSessionData("SELF_SIGN_UP_ACCESS", false);
      // this.fnShowSnackbar("", "error");
    })
  }

  _checkSignUpDisable = () => {
    //check all mandatory fields are entered.
    let tempSignUpFields = this.state.signUpFields;
    for (let i=0; i < tempSignUpFields.length; i++) {
      if (tempSignUpFields[i].state === "mandatory" && 
          tempSignUpFields[i].value === ""
        ) {
        return true;
      } else if (
        tempSignUpFields[i].value !== "" && 
        tempSignUpFields[i].isError === true
      ) {
        return true;
      }
    }
    if (!this.state.agreeTermsAndConditions) return true;
    return false;
  }

  _validate = () => {
    let tempSignUpFields = this.state.signUpFields;

    let password = tempSignUpFields.find((obj) => obj.name === "Password");
    let confirm_pass = tempSignUpFields.find((obj) => obj.name === "Confirm Password");

    if (password.value !== confirm_pass.value) {
      this.fnShowSnackbar("Passwords didn't match. Try again.", "error");
      return false
    }
    return true;
  }

  toCamelCase = (reqInfo) => {
    let signUpEntries = {};
    for ( let i=0; i<reqInfo.length; i++) {
      if (reqInfo[i].state !== "notinuse") {
        let words = reqInfo[i].name.toLowerCase().replace(/[_-]/g, ' ').split(' ');
        let camelCase = words[0];
        for (let i = 1; i < words.length; i++) {
          camelCase += words[i].charAt(0).toUpperCase() + words[i].slice(1);
        }
        if (camelCase === "emailAddress") camelCase = words[0]
        signUpEntries[camelCase] = reqInfo[i].value
      }
    }
    return signUpEntries;
  }

  fnSignUp = () => {
    // this.setState({
    //   // fullPageLoader: false,
    //   displaySecurityQuestions: true,
    //   displaySignUp: false,
    // })
    if (this._validate()) {
      this.setState({
        fullPageLoader: true,
      })
      let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;
      let payload = {};
      payload = this.toCamelCase(this.state.signUpFields)
      payload.ipAddress = decryptObj ? decryptObj.dev_ip : "";
      payload.deviceID = decryptObj ? decryptObj.device_id : "";
      payload.vesselName = decryptObj ? decryptObj.vessel : "";
      payload.k4id = decryptObj ? decryptObj.k4id : "";
      payload.k4dpid = decryptObj ? decryptObj.k4dpid : "";
      callPostApi(
        "DialogSelfSignUp.jsx", 
        "TAG_AUTH_SIGNUP", 
        API_AUTH_SIGNUP,
        payload
      )
      .then((res) => {
        if (res && res.status === 1) {
          let loggedInDetails = {
            planDetails: { planQuota: null, planEndDate: "", topupQuota: 0 },
            userRecId: "",
            ipAddr: "",
          };
          if (res.data) { 
            loggedInDetails.planDetails.planQuota =
            res.data && res.data.remainingQuota
              ? res.data.remainingQuota
              : 0;
            loggedInDetails.planDetails.accountExpirationDate =
              res.data && res.data.accountExpirationDate
                ? res.data.accountExpirationDate
                : 0;
            loggedInDetails.planDetails.planEndDate =
              res.data && res.data.planDetails && res.data.planDetails.planEndDate
                ? res.data.planDetails.planEndDate
                : 0;
            loggedInDetails.loginAccess = res.data ? res.data.loginAccess : false;
            loggedInDetails.userRecId = res.data ? res.data.userRecId : "";
            loggedInDetails.ipAddr = decryptObj ? decryptObj.dev_ip : "";
            loggedInDetails.username = res.data ? res.data.username : "";
            loggedInDetails.allowedDevices = res.data && res.data.allowedDevices ? res.data.allowedDevices : 1;
            saveSessionData("USER_LOGIN_DP_ID", res.data ? res.data.adminDpId : null)
            this.setState({
              // userRecId: res.data._id ? res.data._id : "",
              username: res.data ? res.data.username : "",
              loggedInDetails,
              fullPageLoader: false,
              displaySecurityQuestions: true,
              displaySignUp: false,
            })
            // this.fnShowSnackbar(res.msg ? res.msg : "Sign Up successful.", "success");
          }
        } else if (res && res.status === 0) {
          this.fnShowSnackbar(res.msg ? res.msg : "Sign Up failed.", "error");
          this.setState({
            fullPageLoader: false,
          })
        } else{
          this.fnShowSnackbar("Sign Up failed.", "error");
          this.setState({
            fullPageLoader: false,
          })
        }
      })
      .catch((err)=> {
        this.fnShowSnackbar("", "error");
        this.setState({
          fullPageLoader: false,
        })
      })
    }
  }

  fnCloseInfoDialog = () => {
    this.setState({
      showInfoDialogInUserAuth: {
        open: false,
        msg: ""
      }
    })
  }

  _handleSQdisable = () => {
    let temp = this.state.selectedSecurityQues;
    let _answeredCount = 0;
    Object.keys(temp).map((key) => {
      if ( temp[key] !== "" ) _answeredCount++
    })
    if (_answeredCount < 2) {
      return true;
    }
    return false;
  }

  _fnSaveSecurityQues = () => {  
      let payload = {};

      payload.userRecId = this.state.loggedInDetails ? this.state.loggedInDetails.userRecId : "";
      payload.securityQuestions = this.state.selectedSecurityQues
      this.setState({
        fullPageLoader: true,
      })
      callPostApi(
        "DialogSelfSignUp.jsx", 
        "TAG_AUTH_SIGNUP", 
        API_SET_SECURITY_QUESTIONS,
        payload
      )
      .then((res) => {
        if (res && res.status === 1) {
            // Only when admin approved then trigger login or else show successful signup.
            this.setState({
              selectedSecurityQues: {},
              fullPageLoader: false,
              displayLogin: this.state.loggedInDetails && (this.state.loggedInDetails.loginAccess === true) ? false : true,
              displaySecurityQuestions: this.state.loggedInDetails && (this.state.loggedInDetails.loginAccess === true) ? true : false,
              agreeTermsAndConditions: false 
            }, () => {
              if (this.state.loggedInDetails && (this.state.loggedInDetails.loginAccess === true)) {
                this.fnLogin(true);
              } else {
                this.fnOpenUserAuthDialog(true, true, "", false, true);
              }
            })
        } else if (res && res.status === 0) {
          this.fnShowSnackbar(res.msg ? res.msg : "Error in updating Security Questions.", "error");
          this.setState({
            fullPageLoader: false
          })
        } else{
          this.fnShowSnackbar("Error in updating Security Questions.", "error");
          this.setState({
            fullPageLoader: false
          })
        }
      })
      .catch((err)=> {
        this.fnShowSnackbar("", "error");
        this.setState({
          fullPageLoader: false
        })
      })
    // }
  }

  // Based on output can be either sq or pwrecovery popups.
  _fnGetPasswordRecScreen = () => {
    if (this.state.username === "" || this.state.username === undefined) {
      this.fnShowSnackbar("Please enter Username", "error");
    } else {
      this.setState({
        fullPageLoader: true,
      })
      let decryptObj = getSessionData("DecryptObj")
      ? getSessionData("DecryptObj")
      : null;
      let URL = API_GET_SECURITY_QUESTIONS + `?k4Id=${decryptObj ? decryptObj.k4id : ""}&username=${this.state.username}`
      // let URL = API_GET_SECURITY_QUESTIONS + `?userRecId=63ef0423bb5b7f0c2851a1b8`
      // let URL = API_GET_SECURITY_QUESTIONS + `?k4Id=K4-0211&username=DummyUser2`
      callGetApi(
        "DialogSelfSignUp.jsx", 
        "TAG_GET_SECURITY_QUESTIONS", 
        URL
      )
      .then((res) => {
        if (res && res.status === 1) {
          
          // Admin created user cannot perfom forgotpw flow
          if (res.data.anonymous === false) {
            this.setState({
              showInfoDialogInUserAuth: {
                open: true,
                msg: "You are not authorised to reset password. Contact Administrator for assistance."
              },
              fullPageLoader: false,
            })
          } else if (res.data.accountExpired === true) {
            this.setState({
              showInfoDialogInUserAuth: {
                open: true,
                msg: "Your Account has expired. You are not authorised to reset password. Contact Administrator for assistance."
              },
              fullPageLoader: false,
            })
          } else {
            let loggedInDetails = {...this.state.loggedInDetails}
            if (loggedInDetails && loggedInDetails.userRecId) {}
            else {
              loggedInDetails = {
                userRecId: ""
              }
            }
            loggedInDetails.userRecId = res.data ? res.data.userRecId : "";
  
            // Without admin approval user, cannot perform forgot pw flow.
            if (res.data.loginAccess === false) {
              this.setState({
                loggedInDetails,
                fullPageLoader: false,
                showInfoDialogInUserAuth: {
                  open: true,
                  msg: "This action is not allowed as your Sign Up request is pending Administrator approval."
                }
              })
            } else {
              if (res.data.password_reset) {
                // Show Password recovery screen without SQ screen.
                this.setState({
                  loggedInDetails,
                  fullPageLoader: false,
                  displayPasswordRecovery: true,
                  displayUpdatePassDialog: true,
                  passwordResetByAdmin: true,
                  displayLogin: false
                })
              } else {
                // User already requested -> show popup
                if (res.data.code && res.data.code.length > 0) {
                  let tempSecurityQues = {};
                  for (let i=0; i<res.data.code.length; i++) {
                    tempSecurityQues[res.data.code[i]] = "";
                  }
                  this.setState({
                    securityQuestions: tempSecurityQues,
                    loggedInDetails,
                    fullPageLoader: false,
                    displayPasswordRecovery: true,
                    displayUpdatePassDialog: false,
                    displaySecurityQuestions: false,
                    showResetPWsuccessDialog: res.data.password_reset_req === true ? true : false,
                    displayLogin: false
                  })
                } else {
                  this.setState({
                    fullPageLoader: false
                  })
                }
              }
            }
          }
        } else if (res && res.status === 0) {
          this.fnShowSnackbar(res.msg ? res.msg : "Error in fetching Security Questions.", "error");
          this.setState({
            fullPageLoader: false
          })
        } else{
          this.fnShowSnackbar("Error in fetching Security Questions.", "error");
          this.setState({
            fullPageLoader: false
          })
        }
      })
      .catch((err)=> {
        this.fnShowSnackbar("", "error");
        this.setState({
          fullPageLoader: false
        })
      })
    }
  }

  fnCloseResetPWdialog = () => {
    this.setState({
      showResetPWsuccessDialog: false
    })
  }

  fnTriggerAdminResetPW = () => {
    let payload = {};
    payload.userRecId = this.state.loggedInDetails ? this.state.loggedInDetails.userRecId : "";
    this.setState({
      fullPageLoader: true,
    })
    callPostApi(
      "userAuthentication.jsx", 
      "TAG_USER_TRIGGER_RESET_PASSWORD", 
      API_USER_TRIGGER_RESET_PASSWORD,
      payload
    )
    .then((res) => {
      if (res && res.status === 1) {
        this.setState({
          showResetPWsuccessDialog: true,
          fullPageLoader: false,
          isResetPasswordChecked: false
        })
      } else if (res && res.status === 0) {
        this.fnShowSnackbar(res.msg ? res.msg : "Error in notifying administrator to reset your password.", "error");
        this.setState({
          fullPageLoader: false
        })
      } else{
        this.fnShowSnackbar("Error in notifying administrator to reset your password.", "error");
        this.setState({
          fullPageLoader: false
        })
      }
    })
    .catch((err)=> {
      this.fnShowSnackbar("", "error");
      this.setState({
        fullPageLoader: false
      })
    })
  }

  _checkPasswordRecoveryDisabled = () => {
    if (this.state.updatePassword === "" || this.state.confirmupdatepassword === "") return true
    return false
  }

  _checkSQdisabled = () => {
    if (this.state.isResetPasswordChecked === true) {
      return false;
    } else {
      let temp = this.state.securityQuestions;
      // Checking all the ques are entered.
      let _checkIfnotAnswered = false;
      Object.keys(temp).map((key) => {
        if (temp[key] === '') _checkIfnotAnswered = true
      })
      if (_checkIfnotAnswered === true) return true;
      return false;
    }
  }

  fnIdentityVerification = () => {
    let payload = {};
    payload.userRecId = this.state.loggedInDetails ? this.state.loggedInDetails.userRecId : "";
    payload.code = this.state.securityQuestions;
    payload.op = "security_questions";
    // payload.value = ""
    // Object.keys(this.state.securityQuestions).map((key) => {
    //   if (payload.value === "") {
    //     payload.code = key;
    //     payload.value = this.state.securityQuestions[key] ? this.state.securityQuestions[key] : ""
    //   }
    // })
    // payload.userRecId = "63ef0423bb5b7f0c2851a1b8";
    // if (payload.value === "") {
    //   this.fnShowSnackbar("Please answer any one of the mentioned questions.", "error");
    // } else {
      this.setState({
        fullPageLoader: true,
      })
      callPostApi(
        "DialogSelfSignUp.jsx", 
        "TAG_AUTH_SIGNUP", 
        API_AUTH_FORGOT_PASSWORD,
        payload
      )
      .then((res) => {
        if (res && res.status === 1) {
            // this.fnShowSnackbar( res.msg ? res.msg : "Security Questions verified succesfully.", "success");
            this.setState({
              fullPageLoader: false,
              displayUpdatePassDialog: true
            })
        }
         else if (res && res.status === 0) {
          this.fnShowSnackbar(res.msg ? res.msg : "Error in identity verification", "error");
          this.setState({
            fullPageLoader: false
          })
        } else{
          this.fnShowSnackbar("Error in identity verification", "error");
          this.setState({
            fullPageLoader: false
          })
        }
      })
      .catch((err)=> {
        this.fnShowSnackbar("", "error");
        this.setState({
          fullPageLoader: false
        })
      })
    // }
  }

  _validateUpdatePassword = () => {
    if (this.state.updatePassword !== this.state.confirmupdatepassword) {
      this.fnShowSnackbar("Passwords didn't match. Try again.", "error");
      return false;
    }
    return true;
  }

  fnUpdatePassword = () => {
    if (this._validateUpdatePassword()) {
      let payload = {};
      payload.userRecId = this.state.loggedInDetails ? this.state.loggedInDetails.userRecId : "";
      // payload.userRecId = "63ef0423bb5b7f0c2851a1b8";
      payload.password = this.state.updatePassword;
      this.setState({
        fullPageLoader: true,
      })
      callPostApi(
        "DialogSelfSignUp.jsx", 
        "TAG_AUTH_PASSWORD_RECOVERY", 
        API_AUTH_PASSWORD_RECOVERY,
        payload
      )
      .then((res) => {
        this.setState({
          updatePassword: "",
          confirmupdatepassword: ""
        })
        if (res && res.status === 1) {
            // this.fnShowSnackbar( res.msg ? res.msg : "Password updated succesfully.", "success");
            this.setState({
              showPasswordUpdateSuccess: true,
              fullPageLoader: false,
            })
            setTimeout(() => {
              this.setState({
                displayLogin: true,
                displayUpdatePassDialog: false,
                showPasswordUpdateSuccess: false,
                agreeTermsAndConditions: false
              })
            }, 2000)
        }
         else if (res && res.status === 0) {
          this.fnShowSnackbar(res.msg ? res.msg : "Error in updating Password", "error");
          this.setState({
            fullPageLoader: false
          })
        } else{
          this.fnShowSnackbar("Error in updating Password", "error");
          this.setState({
            fullPageLoader: false
          })
        }
      })
      .catch((err)=> {
        this.fnShowSnackbar("", "error");
        this.setState({
          fullPageLoader: false
        })
      })
    }
  }

  render() {
    const { classes } = this.props;
    const {
      isUserAuthenticated,
      agreeTermsAndConditions,
      username,
      password,
      showPassword,
      showHideSnackbar,
      dialogCloseWindow,
      showViewAccount,
      currentPlan,
      isAccessNetwork,
      isLoading,
      loggedInDetails,
      isLoggedIn,
      displaySignUp,
      displayLogin,
      displaySecurityQuestions,
      securityQuestions,
      selectedSecurityQues,
      displayPasswordRecovery,
      displayUpdatePassDialog,
      passwordError,
      signUpFields,
      isUserSignedIn,
      updatePassword,
      confirmupdatepassword,
      signUpFnameLname,
      showResetPWsuccessDialog,
      showInfoDialogInUserAuth
    } = this.state;
    let jsonData = require("../../components/auth/SecurityQuestions.json");
    return (
      <Grid container className={classes.root}>
        {displayLogin
        ? <Paper className={classes.cardRoot}>
            <div className={classes.header}>
              <img
                // src={k4EdgeLogo} 
                src={this.state.logoSrc}
                onError={() => { 
                  this.setState({
                    logoSrc: k4EdgeLogo
                  })
                }} 
                alt="k4-edge-logo" 
                className={classes.imgStyle}
              />
              <Typography className={classes.headerText} style={{ marginLeft: "0px" }}>
                User Authentication
              </Typography>
            </div>
            <div className={classes.bodyContainer}>
              <Typography className={classes.bodyHeading}>
                Welcome ! Please Enter your Credentials to Connect
              </Typography>
              <div>
                <form
                  noValidate
                  autoComplete="off"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                        fullWidth: true,
                      }}
                      id="standard-basic"
                      label="Username or Email ID"
                      className={classes.inputContainer}
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      name="username"
                      value={username ? username : ""}
                      onChange={(event) => this.fnOnInputChange(event)}
                    />
                  </div>
                  <div>
                    <TextField
                      InputProps={{
                        disableUnderline: true,
                        fullWidth: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => this.fnShowHidePassword()}
                              onMouseDown={(event) =>
                                this.fnHandleMouseDownPassword(event)
                              }
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      id="standard-basic"
                      label="Password"
                      className={classes.inputContainer}
                      InputLabelProps={{
                        className: classes.label,
                      }}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={password ? password : ""}
                      onChange={(event) => this.fnOnInputChange(event)}
                    />
                  </div>
                  <div style={{ marginTop: "5px" }}>
                    <Typography 
                      style={{ 
                        fontSize: "12px", 
                        color: "#384089", 
                        textDecoration: "underline",
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        this._fnGetPasswordRecScreen();
                      }}
                    >
                      Forgot Password?
                    </Typography>
                  </div>
                </form>
              </div>
              <div className={`terms-conditions ${classes.termsConditionsBox}`}>
                <Typography className={classes.termsHeader}>
                  Terms {"&"} Conditions{" "}
                </Typography>
                <div className={classes.termsBox}>
                  <Typography className={classes.term}>
                    1. The owner and operator ( “Owner” ) of this computer (“the
                    service” ) receives the right to discontinue the Service at any
                    time.
                  </Typography>
                </div>
              </div>
              <FormControlLabel
                style={{ margin: 0, marginTop: "13px" }}
                control={
                  <CustomCheckbox
                    onChange={(event) => {
                      this.fnOnCheckboxChange(event);
                    }}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    name="agree"
                    checked={agreeTermsAndConditions}
                  />
                }
                label={
                  <Typography style={{ fontSize: "12px" }}>
                    I Agree to the Terms & Conditions
                  </Typography>
                }
              />
              <div className={classes.connectContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    !(agreeTermsAndConditions && username && password && !isLoading)
                  }
                  onClick={() => this.fnLogin()}
                >
                  Connect
                </Button>
                {isLoading && (
                  <CircularProgress size={26} className={classes.loginLoader} />
                )}
              </div>
              <div className={classes.bodySection}
                onClick={() => {
                  //sign up page
                  this.fnGetSignUpInfo();
                }}
              > 
              {
                this.state.selfsignup &&
                (<Typography className={classes.bodyHeading} style={{ fontSize: "14px", cursor: "pointer" }}>
                  New User? Sign Up
                </Typography>)
              }
              </div>
            </div>
          </Paper>
        : displaySignUp
        ? <Paper className={classes.cardRoot} style={{  height: "582px", overflowY: "auto" }}>
          <div className={classes.header}>
            <img 
              // src={k4EdgeLogo} 
              src={this.state.logoSrc}
              onError={() => { 
                this.setState({
                  logoSrc: k4EdgeLogo
                })
              }} 
              alt="k4-edge-logo"
              className={classes.imgStyle} 
            />
            <Typography className={classes.headerText} style={{ marginLeft: "0px" }}>
              Sign Up
            </Typography>
          </div>
          <div className={classes.bodyContainerSignUp}>
            <div className={classes.innerSignUpDialogBody}>             
              {
                signUpFields && signUpFields.map((obj) => {
                  let _checkFNameState = signUpFields.find((obj) => obj.name === "First Name"); 
                  console.log("ihgfvnm", obj)
                  return (
                    <div className={classes.inputBox} 
                      style={{ 
                        display: (obj.state !== "notinuse") && !(obj.name === "First Name" && signUpFnameLname === "both") ? "" : "none",
                        marginBottom: obj.name === "Confirm Password" ? "0px" : "12px" 
                      }}
                    >
                      {obj.name === "Email Address"
                      ? <>
                          <Typography className={classes.inputLabel}>
                            Email ID
                            {obj.state === "mandatory"
                              ? <span style={{ color: "red" }}>*</span>
                              : null
                            }
                          </Typography>
                          {this.fnRenderInputs(obj.name, classes, "Email ID")}
                        </>
                      : obj.name === "Last Name"
                        ? signUpFnameLname === "Lname"
                          ? <>
                              <Typography className={classes.inputLabel}>
                                  Last Name
                                  {obj.state === "mandatory"
                                    ? <span style={{ color: "red" }}>*</span>
                                    : null
                                  }
                                </Typography>
                              {this.fnRenderInputs("Last Name", classes, "Last Name")}
                            </>
                          : <Grid container spacing={1}>
                              <Grid item xs={6} sm={6} md={6} >
                                <Typography className={classes.inputLabel}>
                                  Last Name
                                  {obj.state === "mandatory"
                                    ? <span style={{ color: "red" }}>*</span>
                                    : null
                                  }
                                </Typography>
                                {this.fnRenderInputs("Last Name", classes, "Last Name")}
                              </Grid>
                              <Grid item xs={6} sm={6} md={6}>
                                <Typography className={classes.inputLabel}>
                                  First Name
                                  {_checkFNameState.state === "mandatory"
                                    ? <span style={{ color: "red" }}>*</span>
                                    : null
                                  }
                                </Typography>
                                {this.fnRenderInputs("First Name", classes, "First Name")}
                              </Grid>
                            </Grid>
                      : obj.name === "First Name"
                        ? signUpFnameLname === "Fname"
                          ? <>
                              <Typography className={classes.inputLabel}>
                                  First Name
                                  {obj.state === "mandatory"
                                    ? <span style={{ color: "red" }}>*</span>
                                    : null
                                  }
                                </Typography>
                              {this.fnRenderInputs("First Name", classes, "First Name")}
                            </>
                          : null
                      : obj.name === "Phone Number"
                      ? <>
                        <Typography className={classes.inputLabel}>
                          Phone Number
                          {obj.state === "mandatory"
                            ? <span style={{ color: "red" }}>*</span>
                            : null
                          }
                        </Typography>
                        {this.fnRenderInputs("Phone Number", classes, "Phone Number")}
                        {/* <Grid container spacing={1} className={classes.inputBox} style={{ alignItems: "end" }}>       
                          <Grid item xs={3} sm={3} md={2}>
                          <TextField
                            select
                            value={this.state.countrycode}
                            className={classes.inputContainerSignUp}
                            InputProps={{
                              disableUnderline: true,
                              fullWidth: true,
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            name="countrycode"
                            // onChange={(event) => this.fnOnInputChange(event)}
                          >
                            <MenuItem style={{ fontSize: "12px" }} value="+91">
                              +91
                            </MenuItem>
                            <MenuItem style={{ fontSize: "12px" }} value="+1">
                              +1
                            </MenuItem>
                          </TextField>
                          </Grid >
                          <Grid item xs={9} sm={9} md={10}>
                            {this.fnRenderInputs("Phone Number", classes, "Phone Number")}
                          </Grid >
                        </Grid> */}
                        </>
                      : <>
                          <Typography className={classes.inputLabel}>
                            {obj.name}
                            {obj.state === "mandatory"
                              ? <span style={{ color: "red" }}>*</span>
                              : null
                            }
                          </Typography>
                          {this.fnRenderInputs(obj.name, classes, obj.name === "Confirm Password" ? "Confirm your Password" : obj.name)}
                        </>
                      }      
                      <Typography 
                        className={classes.errorMessage}
                        style={{
                          visibility: obj.isError ? "" : (obj.state !== "notinuse") ? "hidden" : "",
                          height: "10px"
                        }}
                      >
                        {obj.msg}
                      </Typography>
                    </div>
                  )
                })
              }
            </div>
            <Typography className={classes.errorMessage}>
              *These fields are mandatory.
            </Typography>
            <div className={`terms-conditions ${classes.termsConditionsBox}`} style={{ marginTop: "5px", height: "70px" }}>
              <Typography className={classes.termsHeader}>
                Terms {"&"} Conditions{" "}
              </Typography>
              <div className={classes.termsBox}>
                <Typography className={classes.term} style={{ marginTop: "10px" }}>
                  1. The owner and operator ( “Owner” ) of this computer (“the
                  service” ) receives the right to discontinue the Service at any
                  time.
                </Typography>
              </div>
            </div>
            <FormControlLabel
              style={{ margin: 0, marginTop: "10px" }}
              control={
                <CustomCheckbox
                  onChange={(event) => {
                    this.fnOnCheckboxChange(event);
                  }}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  name="agree"
                  checked={agreeTermsAndConditions}
                />
              }
              label={
                <Typography style={{ fontSize: "12px" }}>
                  I Agree to the Terms & Conditions
                </Typography>
              }
            />
            <div className={classes.connectContainer} style={{ marginTop: "20px" }}>
              <Button
                color="primary"
                variant="contained"
                disabled={
                  this._checkSignUpDisable()
                }
                onClick={() => this.fnSignUp()}
                style={{
                  textTransform: 'none'
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
          </Paper>
        : displaySecurityQuestions
        ? <Paper className={classes.cardRoot}>
            <div className={classes.header}>
              <img 
                // src={k4EdgeLogo}
                src={this.state.logoSrc}
                onError={() => { 
                  this.setState({
                    logoSrc: k4EdgeLogo
                  })
                }} 
                alt="k4-edge-logo"
                className={classes.imgStyle} 
              />
              <Typography className={classes.headerText}>
                Sign Up - {username}
              </Typography>
            </div>
            <div className={classes.bodyContainerSQ}>
              <Typography className={classes.bodyHeading} style={{ fontSize: "14px" }}>
                Hi,
              </Typography>
              <Typography className={classes.bodyHeading} style={{ fontSize: "14px", marginTop: "10px" }}>
                Please set your questions and answers below. These will be helpful incase you forget password.
              </Typography>
              {/* <div className={classes.innerDialogBody}>             
                {Object.keys(securityQuestions).map((key) => {
                  return (
                    <div className={classes.inputBox}>
                      <Typography className={classes.inputLabel}>
                        <Radio
                          size="small"
                          color="primary"
                          style={{ padding: "5px", marginLeft: "10px", color: "#384089" }} 
                          onChange={(evnt) => {
                            console.log("oigfgh", evnt,)
                            let tempSecurityQues = {...this.state.securityQuestions};
                            let _value = tempSecurityQues[key].answer;
                            console.log("oigfgh", evnt, tempSecurityQues)
                            tempSecurityQues[key] = {
                              "answer": _value,
                              "checked": !tempSecurityQues[key].checked
                            }
                            this.setState({
                              securityQuestions: tempSecurityQues,
                            }, () => {
                              console.log("oigfgh", tempSecurityQues)
                            });
                          }}
                          checked={securityQuestions[key].checked}
                          disabled={false}
                        />
                        {jsonData[key]}
                      </Typography>
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <TextField
                          value={securityQuestions[key].answer}
                          InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          className={classes.inputContainerSignUp}
                          name={key}
                          onChange={(e) => {
                            this.fnOnInputChangeSecurityQues(e)
                          }}
                          placeholder={"Enter your Answer"}
                        />
                      </form>
                    </div>
                  )
                })}
              </div> */}
              <div className={classes.innerDialogBody}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <Typography className={classes.inputLabel} style={{ width: "20%" }}>
                    Question 1
                  </Typography>
                  <TextField
                    select
                    value={selectedSecurityQues && (Object.keys(selectedSecurityQues)[0] !== undefined) ? jsonData[Object.keys(selectedSecurityQues)[0]] : "select"}
                    InputProps={{
                      disableUnderline: true,
                      // fullWidth: true,
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={(event) => {
                      this.fnOnSelectSecurityQues(event, 0);
                    }}
                    style={{
                      width: "70%",
                      borderBottom: "1px solid #384089"
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: "12px" }}
                      key={-1}
                      value="select"
                    >
                      Select
                    </MenuItem>
                    {Object.keys(securityQuestions).map((code, ind) => (
                      <MenuItem
                        style={{ fontSize: "12px", display: Object.keys(selectedSecurityQues)[1] === code ? "none" : "" }}
                        key={ind}
                        value={jsonData[code]}
                      >
                        {jsonData[code]}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "15px" }}>
                  <Typography className={classes.inputLabel} style={{ width: "20%" }}>
                    Answer 1
                  </Typography>
                  {/* <form
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  > */}
                    <TextField
                      value={selectedSecurityQues && (Object.keys(selectedSecurityQues)[0] !== undefined) ? selectedSecurityQues[Object.keys(selectedSecurityQues)[0]] : ""}
                      InputProps={{
                        disableUnderline: true,
                        // fullWidth: true,
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      className={classes.inputContainerSignUp}
                      style={{
                        width: "70%", 
                      }}
                      name={selectedSecurityQues && (Object.keys(selectedSecurityQues)[0] !== undefined) ? Object.keys(selectedSecurityQues)[0] : ""}
                      onChange={(e) => {
                        this.fnUpdateSelectedSecurityQues(e, 0)
                      }}
                      placeholder={"Enter your Answer"}
                      disabled={selectedSecurityQues && (Object.keys(selectedSecurityQues)[0] !== undefined) ? false : true}
                    />
                  {/* </form> */}
                </div>
              </div>
              <div className={classes.innerDialogBody}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                  <Typography className={classes.inputLabel} style={{ width: "20%" }}>
                    Question 2
                  </Typography>
                  <TextField
                    select
                    value={selectedSecurityQues && (Object.keys(selectedSecurityQues)[1] !== undefined) ? jsonData[Object.keys(selectedSecurityQues)[1]] : "select"}
                    InputProps={{
                      disableUnderline: true,
                      // fullWidth: true,
                      style: {
                        fontSize: "12px",
                      },
                    }}
                    onChange={(event) => {
                      this.fnOnSelectSecurityQues(event, 1);
                    }}
                    style={{
                      width: "70%",
                      borderBottom: "1px solid #384089"
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: "12px" }}
                      key={-1}
                      value="select"
                    >
                      Select
                    </MenuItem>
                    {Object.keys(securityQuestions).map((code, ind) => (
                      <MenuItem
                        style={{ fontSize: "12px", display: Object.keys(selectedSecurityQues)[0] === code ? "none" : "" }}
                        key={ind}
                        value={jsonData[code]}
                      >
                        {jsonData[code]}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between", marginTop: "15px" }}>
                  <Typography className={classes.inputLabel} style={{ width: "20%" }}>
                    Answer 2
                  </Typography>
                  {/* <form
                    noValidate
                    autoComplete="off"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  > */}
                    <TextField
                      value={selectedSecurityQues && (Object.keys(selectedSecurityQues)[1] !== undefined) ? selectedSecurityQues[Object.keys(selectedSecurityQues)[1]] : ""}
                      InputProps={{
                        disableUnderline: true,
                        // fullWidth: true,
                        style: {
                          fontSize: "12px",
                        },
                      }}
                      className={classes.inputContainerSignUp}
                      style={{
                        width: "70%",
                      }}
                      name={selectedSecurityQues && (Object.keys(selectedSecurityQues)[1] !== undefined) ? Object.keys(selectedSecurityQues)[1] : ""}
                      onChange={(e) => {
                        this.fnUpdateSelectedSecurityQues(e, 1)
                      }}
                      placeholder={"Enter your Answer"}
                      disabled={selectedSecurityQues && (Object.keys(selectedSecurityQues)[1] !== undefined) ? false : true}
                    />
                  {/* </form> */}
                </div>
              </div>
              <div className={classes.connectContainer}>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    this._handleSQdisable()
                  }
                  onClick={() => {
                    if (!this._handleSQdisable()) this._fnSaveSecurityQues()
                  }}
                  style={{
                    textTransform: 'none'
                  }}
                >
                  Continue
                </Button>
              </div>
            </div>
          </Paper>
        : displayPasswordRecovery
        ? <Paper className={classes.cardRoot}>
            <div className={classes.header}>
              <img 
                // src={k4EdgeLogo}
                src={this.state.logoSrc}
                onError={() => { 
                  this.setState({
                    logoSrc: k4EdgeLogo
                  })
                }} 
                alt="k4-edge-logo"
                className={classes.imgStyle} 
              />
              <Typography className={classes.headerText}>
                Password Recovery - {username}
              </Typography>
            </div>
            <div className={classes.bodyContainer}>
              <Typography className={classes.bodyHeading} style={{ fontSize: "14px" }}>
                Hi,
              </Typography>
              <Typography className={classes.bodyHeading} style={{ fontSize: "14px", marginTop: "10px" }}>
                {this.state.passwordResetByAdmin 
                  ? `Password Reset request is Approved. Please reset your password.`
                  : displayUpdatePassDialog
                    ? `Reset your Password`
                    : `Forgot your Password? Answer these questions to proceed.`
                }
              </Typography>
              {displayUpdatePassDialog
              ? <>
                  <div className={classes.innerDialogBody}>
                    <div className={classes.inputBox}>
                      <Typography className={classes.inputLabel}>
                        Create a Password
                      </Typography>
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <TextField
                          value={updatePassword ? updatePassword : ""}
                          InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.setState({ showPassword: !this.state.showPassword });
                                  }}
                                  onMouseDown={(event) =>
                                    this.fnHandleMouseDownPassword(event)
                                  }
                                >
                                  {this.state.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>,
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          className={classes.inputContainerSignUp}
                          name="updatePassword"
                          onChange={(e) => {
                            this.fnOnInputChange(e);
                          }}
                          placeholder={"Password"}
                          type={
                            this.state.showPassword
                              ? "text"
                              : "password"
                          }
                        />
                      </form>
                      <span 
                        className={classes.errorMessage}
                        style={{
                          visibility: (updatePassword && updatePassword.length > 1 && updatePassword.length < 8)  ? "" : "hidden"
                        }}
                      >
                        *Password should have at least 8 characters
                      </span>
                    </div>
                    <div className={classes.inputBox}>
                      <Typography className={classes.inputLabel}>
                        Confirm Password
                      </Typography>
                      <form
                        noValidate
                        autoComplete="off"
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <TextField
                          value={confirmupdatepassword ? confirmupdatepassword : ""}
                          InputProps={{
                            disableUnderline: true,
                            fullWidth: true,
                            endAdornment:
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
                                  }}
                                  onMouseDown={(event) =>
                                    this.fnHandleMouseDownPassword(event)
                                  }
                                >
                                  {this.state.showConfirmPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>,
                            style: {
                              fontSize: "12px",
                            },
                          }}
                          className={classes.inputContainerSignUp}
                          name="confirmupdatepassword"
                          onChange={(e) => {
                            this.fnOnInputChange(e);
                          }}
                          placeholder={"Confirm your Password"}
                          type={
                            this.state.showConfirmPassword
                              ? "text"
                              : "password"
                          }
                        />
                      </form>
                    </div>
                  </div>
                  <div style={{ display: this.state.showPasswordUpdateSuccess ? "flex" : "none", alignItems: "center", justifyContent: "center" }}>
                    <img
                      src={require("../../assets/images/success_resp.png")}
                      style={{ height: "15px" }}
                    />
                    <Typography className={classes.inputLabel} style={{ marginLeft: "10px" }}>
                      Password Reset Successfully.
                    </Typography>
                  </div>
                </>
              : <div className={classes.innerDialogBody}>             
                {Object.keys(securityQuestions).length > 0 ?
                  Object.keys(securityQuestions).map((key) => {
                    return (
                      <div className={classes.inputBox}>
                        <Typography className={classes.inputLabel}>
                          {jsonData[key]}
                        </Typography>
                        <form
                          noValidate
                          autoComplete="off"
                          onSubmit={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <TextField
                            value={securityQuestions[key]}
                            InputProps={{
                              disableUnderline: true,
                              fullWidth: true,
                              style: {
                                fontSize: "12px",
                              },
                            }}
                            className={classes.inputContainerSignUp}
                            name={key}
                            onChange={(e) => {
                              this.fnOnInputChangeSecurityQues(e)
                            }}
                            placeholder={"Enter your Answer"}
                          />
                        </form>
                      </div>
                    )
                  })
                : null}
                </div>}
              {!displayUpdatePassDialog
                ? <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <CustomCheckbox
                      onChange={(event) => {
                        this.setState({
                          isResetPasswordChecked: event.target.checked
                        })
                      }}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checked={this.state.isResetPasswordChecked}
                    />
                    <Typography
                      style={{ 
                        // marginTop: "10px",
                        color: "#384089",
                        // textDecoration: "underline",
                        fontSize: "13px",
                        cursor: "pointer", 
                      }}
                    >
                      Do not remember answers? Request administrator to reset password
                    </Typography>
                  </div>
                : null}
              {displayUpdatePassDialog
              ? <div className={classes.connectContainer} style={{ justifyContent: "space-evenly" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    if (this.state.passwordResetByAdmin) {
                      //Go back directly to login screen.
                      this.setState({ 
                        displayUpdatePassDialog: false,
                        displayPasswordRecovery: false,
                        displayLogin: true,
                        updatePassword: "",
                        confirmupdatepassword: ""
                      }) 
                    } else {
                      this.setState({ 
                        displayUpdatePassDialog: false,
                        updatePassword: "",
                        confirmupdatepassword: "" 
                      })
                    }
                  }}
                  style={{
                    textTransform: 'none'
                  }}
                >
                  Go back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (this._checkPasswordRecoveryDisabled) {
                      this.fnUpdatePassword()
                    }
                  }}
                  style={{
                    textTransform: 'none',    
                  }}
                  disabled={this._checkPasswordRecoveryDisabled()}
                >
                  Continue
                </Button>
                </div>
              : <div className={classes.connectContainer} style={{ justifyContent: "space-evenly" }}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    this.setState({ displayLogin: true, agreeTermsAndConditions: false, displayPasswordRecovery: false, securityQuestions: {}, isResetPasswordChecked: false })
                  }}
                  style={{
                    textTransform: 'none'
                  }}
                >
                  Go back
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    if (this.state.isResetPasswordChecked) {
                      // notify admin to reset pw, display popup. -> Go back to login.
                      this.fnTriggerAdminResetPW()
                    } else {
                      this.fnIdentityVerification()
                    }
                  }}
                  style={{
                    textTransform: 'none',    
                  }}
                  disabled={this._checkSQdisabled()}
                >
                  {this.state.isResetPasswordChecked ? "Proceed" : "Reset Password"}
                </Button>
              </div>}
            </div>
          </Paper>
        : null
        }
        {this.state.fullPageLoader ? (
          <CustomLoader loadingText={"Please wait..."} showLoader={this.state.fullPageLoader} />
        ) : null}
        {isUserSignedIn.open ? (
          <DialogUserSignedIn
            open={isUserSignedIn.open}
            onClose={() => {
              // Auth failed after sign up success. On Tryagain trigger -> display login.
              this.setState({
                isUserSignedIn: { open: false, auth: false, msg: "" },
                displayLogin: true,
                agreeTermsAndConditions: false
              })
            }}
            username={username}
            loggedInDetails={loggedInDetails}
            logout={() => this.fnLogoutUser()}
            isAuthenticated={isUserSignedIn.auth}
            message={isUserSignedIn.msg}
            openWindowDialog={() => this.fnOpenUserAuthDialog(false, false, "", isLoggedIn, true)}
            fromIsLoggedInAPI={isLoggedIn}
            redirectToLogin={() => {
              this.setState({
                isUserSignedIn: { open: false, auth: false, msg: "" },
              }, () => {
                this.resetData();
              })
            }}
          />
        ) : null}
        {isUserAuthenticated.open ? (
          <DialogUserAuthentication
            open={isUserAuthenticated.open}
            isAuthenticated={isUserAuthenticated.auth}
            message={isUserAuthenticated.msg}
            onClose={() => this.fnOpenUserAuthDialog(false, false)}
            logout={() => this.fnLogoutUser()}
            openWindowDialog={() => this.fnOpenUserAuthDialog(false, false)}
            currentPlan={currentPlan}
            loggedInDetails={loggedInDetails}
            isAccessNetwork={isAccessNetwork}
            fromIsLoggedInAPI={isLoggedIn}
          />
        ) : null}
        {showResetPWsuccessDialog ? (
          <Dialog
            open={showResetPWsuccessDialog}
            classes={{ paper: classes.paperSignIn }}
            disableEscapeKeyDown
          >
            <Grid
              container
              style={{
                padding: "10px 16px",
                background:
                  "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: "#FFFFFF",
                  }}
                >
                  Reset Password
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.dialogBody}>
              <div style={{ textAlign: "center" }}>
                <img
                  src={require("../../assets/images/success_resp.png")}
                  alt="success_resp"
                  style={{ margin: "23px 0px" }}
                />
                <Typography className={classes.boldText}>
                  Password reset request submitted.
                </Typography>
                <Typography className={classes.lightText}>
                  This action may take some time. 
                </Typography>
                <div className={classes.btnContainer}>
                  <div
                    className={classes.darkBtn}
                    onClick={() => {
                      this.setState({
                        displayPasswordRecovery: false,
                        displayLogin: true,
                        displayUpdatePassDialog: false,
                        agreeTermsAndConditions: false
                      },() => {
                        this.fnCloseResetPWdialog()
                      })
                    }}
                  >
                    <Typography className={classes.darkBtnText}>
                      Go Back
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        ) : null}
        {showInfoDialogInUserAuth.open ? (
          <Dialog
            open={showInfoDialogInUserAuth.open}
            classes={{ paper: classes.paperSignIn }}
            disableEscapeKeyDown
          >
            <Grid
              container
              style={{
                padding: "10px 16px",
                background:
                  "linear-gradient(180deg, #384089 0%, rgba(56, 64, 137, 0.88) 100%)",
                justifyContent: "space-between",
                display: "flex"
              }}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "1rem",
                    color: "#FFFFFF",
                  }}
                >
                  User Authentication
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.dialogBody}>
              <div style={{ textAlign: "center" }}>
                <Typography className={classes.boldText}>
                  {showInfoDialogInUserAuth.msg}
                </Typography>
                <div className={classes.btnContainer}>
                  <div
                    className={classes.darkBtn}
                    onClick={() => {
                      this.fnCloseInfoDialog()
                    }}
                  >
                    <Typography className={classes.darkBtnText}>
                      OK
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </Dialog>
        ) : null}
        {isAccessNetwork ? (
          <DialogIsAccessNetwork
            open={isAccessNetwork}
            onClose={() => this.fnIsAccessNetwork(false)}
          />
        ) : null}
        {showHideSnackbar.show ? (
          <CustomSnackbar
            open={showHideSnackbar.show}
            variant={showHideSnackbar.variant}
            message={showHideSnackbar.message}
            onClose={this.fnHideSnackbar}
          />
        ) : null}
        {dialogCloseWindow ? (
          <DialogCloseWindow
            open={dialogCloseWindow}
            logout={() => this.fnLogoutUser()}
            showViewAccount={showViewAccount}
          />
        ) : null}
      </Grid>
    );
  }
}

export default withStyles(UserAuthentication);
