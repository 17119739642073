import React, { useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "../styles/HomeScreen";
import asyncComponent from "../AsyncComponent";
//routes
import routes from "../../routes/privateRoutes";
// components
import Header from "../private/Header";

const CustomSnackbar = asyncComponent(() => import("../snackbar/CustomSnackbar"));

/**
 * 
 * @typedef {Object} SnackbarOptions 
 * @property {string} variant
 * @property {string} message
 */

// const routes = asyncComponent(() => import("../../routes/privateRoutes"));
// const Header = asyncComponent(() => import("../private/Header"));



function HomeScreen(props) {
  var classes = useStyles();
  /** @type {[SnackbarOptions, React.Dispatch<React.SetStateAction<SnackbarOptions>>]} */
  const [snackbar, setSnackbar] = useState(null);

  return (
    <div className={classes.root}>
      <>
        <Header
          history={props.history}
          showSnackbar={(message, variant) => setSnackbar({message, variant})} />

        <div
          className={classnames(classes.content, {
            [classes.contentShift]: true,
          })}
        >
          <div className={classes.fakeToolbar} />

          <Switch>
            {routes && routes.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => <route.component {...props} />}
                />
              ) : null;
            })}
          </Switch>
        </div>
        {snackbar && (
          <CustomSnackbar
            open
            variant={snackbar.variant}
            message={snackbar.message}
            onClose={() => setSnackbar(null)}
          />)}
      </>
    </div>
  );
}

export default withRouter(HomeScreen);
