import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Paper,
  Input,
  Typography,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import useStyles from "../styles/LoginScreen";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withRouter } from "react-router-dom";
import { callGetApi, callPostApi } from "../../managers/api/ApiManager";
import {
  API_FORGOT_PASSWORD,
  API_SAVE_ANSWERS_LIST,
  API_RESET_PASSWORD,
  API_ADMIN_RESET_PASSWORD,
  API_BASE_URL,
  API_LOGIN,
  API_GENERATE_OTP,
  API_VALIDATE_OTP,
  API_ADMIN_ACCESS,
  API_SSO_LOGIN,
} from "../../constants/ApiEndPoints";
import { IS_LOGGED_IN, USER_DATA } from "../../constants/SessionKeys";
import {
  clearSession,
  getSessionData,
  saveSessionData,
} from "../../managers/session/SessionManager";
import asyncComponent from "../AsyncComponent";
import CustomLoader from "../CustomLoader/CustomLoader";

const CustomSnackbar = asyncComponent(() =>
  import("../snackbar/CustomSnackbar")
);
const DialogResetPassword = asyncComponent(() =>
  import("./DialogResetPassword")
);
const DialogSecurityQuestion = asyncComponent(() =>
  import("./DialogSecurityQuestion")
);

// import DialogResetPassword from "./DialogResetPassword";
// import DialogSecurityQuestion from "./DialogSecurityQuestion";
// import CustomSnackbar from "../snackbar/CustomSnackbar";

const LOGO = require("../../assets/images/k4-only-logo.png");
const Password_Reset = require("../../assets/images/password_reset.png");

const fnHandleLogin = (
  username,
  password,
  setError,
  setIsLoading,
  history,
  setSecurityQuestion,
  setQuestionList,
  setLoginResponse,
  setTwoFactorAuth,
  setOTPdetails,
  setEnableResendCode,
  setloginLogoSrc,
  setloginSloganSrc,
  loadImage,
  isImage,
  loadFavIcon
) => {
  if (username === "") {
    setError({ message: "Enter Username", status: true });
  } else if (password === "") {
    setError({ message: "Enter Password", status: true });
  } else {
    setError(null);
    // setTwoFactorAuth(true);
    fnCallLoginApi(
      username,
      password,
      setError,
      setIsLoading,
      history,
      setSecurityQuestion,
      setQuestionList,
      setLoginResponse,
      "",
      setTwoFactorAuth,
      setOTPdetails,
      setEnableResendCode,
      setloginLogoSrc,
      setloginSloganSrc,
      loadImage,
      isImage,
      loadFavIcon
    );
    localStorage.removeItem("logout-event");
  }
};

const fnCallLoginApi = async (
  username,
  password,
  setError,
  setIsLoading,
  history,
  setSecurityQuestion,
  setQuestionList,
  setLoginResponse,
  tokenFromSport,
  setTwoFactorAuth,
  setOTPdetails,
  setEnableResendCode,
  setloginLogoSrc,
  setloginSloganSrc,
  loadImage,
  isImage,
  loadFavIcon
) => {
  setIsLoading(true);
  let body = {};
  console.log("payload>>>", tokenFromSport, username, password);
  if (tokenFromSport) {
    body.token = tokenFromSport;
  } else {
    body.applicationName = "CANAdminPortal";
    body.userName = username;
    body.password = btoa(password);
  }
  try {
    let response = await callPostApi(
      "LoginPage",
      "LOGIN",
      tokenFromSport ? API_SSO_LOGIN : API_LOGIN,
      body
    );
    console.log("bodysent", body);
    console.log("response--->", response);
    if (response === null) {
      setIsLoading(false);
      setError({ message: "Network Error", status: "error" });
    } else {
      if (response.success === true) {
        console.log("response--->", response);
        setLoginResponse(response);
        if (
          response.data &&
          response.data.user &&
          response.data.user.mfaEnabled
        ) {
          console.log("response--->", response);
          setTwoFactorAuth(true);

          let dpId =
            response &&
            response.data &&
            response.data.ou &&
            response.data.ou.id &&
            response.data.ou.id !== ""
              ? response.data.ou.id
              : getSessionData("ADMIN_DP_ID")
              ? getSessionData("ADMIN_DP_ID")
              : null;
          //loginlogo
          loadImage(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANadminloginLogo.png?`
          );
          setloginLogoSrc(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANadminloginLogo.png?`
          );
          //login slogan
          isImage(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/loginSlogan.png?`
          )
            .then(() => {
              setloginSloganSrc(
                `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/loginSlogan.png?`
              );
            })
            .catch((err) => {
              setloginSloganSrc("");
            });
          //favicon
          loadFavIcon(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANfavicon.png?`
          );

          let payload = {
            username: username,
            session_token: response.data.session_token,
          };
          setOTPdetails({ sessionToken: response.data.session_token });
          fnGenerateOTP(payload, setEnableResendCode);
        } else {
          fnSaveData(response, history, setError, setIsLoading);
          let dpId =
            response &&
            response.data &&
            response.data.ou &&
            response.data.ou.id &&
            response.data.ou.id !== ""
              ? response.data.ou.id
              : getSessionData("ADMIN_DP_ID")
              ? getSessionData("ADMIN_DP_ID")
              : null;

          saveSessionData("ADMIN_DP_ID", dpId);
          //loginlogo
          loadImage(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANadminloginLogo.png?`
          );
          setloginLogoSrc(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANadminloginLogo.png?`
          );
          //login slogan
          isImage(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/loginSlogan.png?`
          )
            .then(() => {
              setloginSloganSrc(
                `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/loginSlogan.png?`
              );
            })
            .catch((err) => {
              setloginSloganSrc("");
            });
          //favicon
          loadFavIcon(
            `https://k4storage.blob.core.windows.net/cdn/wl/${dpId}/edge/CANfavicon.png?`
          );
        }
        // if (response.returnData.firstLogin === 1) {
        //   setIsLoading(false);
        //   setQuestionList(
        //     response.returnData.questionslist
        //       ? response.returnData.questionslist
        //       : []
        //   );
        //   setSecurityQuestion({
        //     open: true,
        //     list: response.returnData.questionslist
        //       ? response.returnData.questionslist
        //       : [],
        //     verifyAnswers: false,
        //     email: "",
        //   });
        // } else {

        // }
      } else if (response.success === false) {
    
        setIsLoading(false);
        if (!tokenFromSport) {
          let msg =
            response &&
            response.errors &&
            response.errors[0] &&
            response.errors[0].message
              ? response.errors[0].message
              : "";
          setError({ message: msg, status: true });
        }
      } else {
        if (!tokenFromSport)
          setError({ message: "Error in Logging in the user.", status: true });
      }
    }
  } catch (e) {
    console.log("err here", e);
    setIsLoading(false);
    if (!tokenFromSport) setError({ message: "" + e, open: true });
  }
};

const fnGenerateOTP = (payload, setEnableResendCode, setFullLoader) => {
  if (setFullLoader) setFullLoader(true);
  console.log("response--->", payload);
  callPostApi("Login", "generateOTP", API_GENERATE_OTP, payload)
    .then((res) => {
      console.log("response--->", payload);
      if (setFullLoader) setFullLoader(false);
      if (res && res.success === true) {
        setEnableResendCode(false);
        setTimeout(() => {
          setEnableResendCode(true);
        }, 60 * 1000);
      }
    })
    .catch((err) => {
      if (setFullLoader) setFullLoader(false);
    });
};

const fnValidateOTP = (
  username,
  reqOTPdetails,
  securityCode,
  history,
  setTwoFactorAuth,
  setFullLoader,
  setError,
  setInvalidCode,
  setPasswordValue,
  setSecurityCode,
  setIsLoading
) => {
  let payload = {
    username: username,
    session_token: reqOTPdetails.sessionToken,
    verification_code: securityCode,
  };
  setFullLoader(true);
  callPostApi("Login", "validateOTP", API_VALIDATE_OTP, payload)
    .then(async (res) => {
      if (res && res.success === true) {
        fnSaveData(res, history, setError, setIsLoading);
      } else if (res.success === false) {
        setFullLoader(false);
        if (
          res.msg &&
          res.msg === "IAM Policy restricts login for this credentials"
        ) {
          setError({ message: res.msg, status: true });
          setTwoFactorAuth(false);
          setPasswordValue("");
          setInvalidCode(false);
          setSecurityCode("");
        } else {
          setInvalidCode(true);
        }
      } else {
        setFullLoader(false);
        setInvalidCode(true);
      }
    })
    .catch((err) => {
      setFullLoader(false);
      // setError({
      //   message: "Error in validating OTP.",
      //   variant: "error",
      //   status: true,
      // })
      // setTwoFactorAuth(false)
      setInvalidCode(true);
    });
};

const fnGetAdminAccess = async (access_token, username) => {
  let URL = API_ADMIN_ACCESS + `?userName=${username ? username : ""}`;
  let ADMIN_ACCESS_TOKEN = access_token;

  await fetch(URL, {
    method: "GET",
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: `Bearer ${ADMIN_ACCESS_TOKEN}`,
    },
  })
    .then((response) => response.json())
    .then((res) => {
      if (res && res.permissions) {
        for (let i = 0; i < res.permissions.length; i++) {
          if (
            res.permissions[i].service &&
            res.permissions[i].service === "can_portal"
          ) {
            let adminPermissions = res.permissions[i].servicefunctions.map(
              (obj) => obj.function
            );
            saveSessionData("ADMIN_PERMISSIONS", adminPermissions);
            break;
          }
        }
      }
      return;
    })
    .catch((err) => {
      console.log("Admin Access Policies error", err);
    });
};

const fnSaveData = async (response, history, setError, setIsLoading) => {
  console.log("response =================>", response);
  let accessToken =
    response && response.data ? response.data.access_token : null;
  let idToken = response && response.data ? response.data.id_token : null;
  let refreshToken =
    response && response.data ? response.data.refresh_token : null;
  let expiresIn = response && response.data ? response.data.expires_in : null;
  let tokenType = response && response.data ? response.data.token_type : null;
  let adminRecId =
    response && response.data && response.data.user
      ? response.data.user.id
      : null;
  let dpId =
    response && response.data && response.data.ou ? response.data.ou.id : null;
  let dpName =
    response && response.data && response.data.ou
      ? response.data.ou.name
      : null;
  let adminUsername =
    response && response.data && response.data.user
      ? response.data.user.userName
      : null;
  let allSitesFlag = response && response.data && response.data.user ? response.data.user.allSitesUser : false;
  let grpUser = response && response.data && response.data.group_dp_details ? response.data.group_dp_details : null;

  await fnGetAdminAccess(accessToken, adminUsername);
  console.log("New check", getSessionData("ADMIN_PERMISSIONS"));
  let can_portal_check = getSessionData("ADMIN_PERMISSIONS");

  saveSessionData(USER_DATA, {
    name:
      response && response.data && response.data.user
        ? response.data.user.userName
        : "",
    username:
      response && response.data && response.data.user
        ? response.data.user.userName
        : "",
  });

  saveSessionData(IS_LOGGED_IN, true);
  saveSessionData("ACCESS_TOKEN", accessToken);
  saveSessionData("ID_TOKEN", idToken);
  saveSessionData("REFRESH_TOKEN", refreshToken);
  saveSessionData("EXPIRES_IN", expiresIn);
  saveSessionData("TOKEN_TYPE", tokenType);
  saveSessionData("ADMIN_REC_ID", adminRecId);
  saveSessionData("ADMIN_NAME", adminUsername);
  saveSessionData("DP_ID", dpId);
  saveSessionData("ADMIN_DP_ID", dpId);
  saveSessionData("DP_NAME", dpName);
  saveSessionData("ALL_SITES_FLAG", allSitesFlag);
  saveSessionData("GRP_USER", grpUser);
  // if (response.returnData) {

  // window.location.reload();
  // }

  if (can_portal_check === null) {
    saveSessionData(IS_LOGGED_IN, false);
    clearSession();
    setIsLoading(false);
    let msg = "Login Credentials do not have Captive Portal Access";
    setError({ message: msg, status: true });
  } else {
    history.replace("/portal");
    window.location.reload();
  }
};

function Login(props) {
  var classes = useStyles();

  const [releaseNo, setReleaseNo] = useState("4.6");
  var [forgotPASS, setForgotPASS] = useState(false);
  var [afterForgotPASS, setAfterForgotPASS] = useState(false);
  var [usernameForgotPASS, setUserForgotPASS] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [twoFactorAuth, setTwoFactorAuth] = useState(false);
  var [reqOTPdetails, setOTPdetails] = useState({ sessionToken: "" });
  var [securityCode, setSecurityCode] = useState("");
  var [enableResendCode, setEnableResendCode] = useState(false);
  var [isInvalidCode, setInvalidCode] = useState(false);

  var [username, setUsername] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [showPassword, setShowPassword] = useState(false);
  var [loginLogoSrc, setloginLogoSrc] = useState(
    `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
      "ADMIN_DP_ID"
    )}/edge/CANadminloginLogo.png?`
  );
  // var [loginLogoSrc, setloginLogoSrc] = useState(require(`../../assets/images/loginLogo.png`));
  // var [loginLogoSrc, setloginLogoSrc] = useState(API_BASE_URL + "edgeIcon/CANadminloginLogo.png");
  var [loginSloganSrc, setloginSloganSrc] = useState(
    `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
      "ADMIN_DP_ID"
    )}/edge/loginSlogan.png?`
  );
  var [faviconSrc, setFaviconSrc] = useState(
    `https://k4storage.blob.core.windows.net/cdn/wl/${getSessionData(
      "ADMIN_DP_ID"
    )}/edge/CANfavicon.png?`
  );
  const [imageDimensions, setImageDimensions] = useState({});

  const [loginResponse, setLoginResponse] = useState(null);
  const [showForgotDialog, setShowForgotDialog] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);
  const [tokenFromSport, setTokenFromSport] = useState("");
  // const [isLoggedInFromToken, setisLoggedInFromToken] = useState(false);
  const [securityQuestion, setSecurityQuestion] = useState({
    open: false,
    list: [],
    verifyAnswers: false,
    email: "",
  });
  const [showResetDialog, setResetDialog] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const isImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.src = url;
      image.onload = resolve;
      image.onerror = reject;
    });

  console.log("loginSloganSrc", loginSloganSrc);
  isImage(loginSloganSrc)
    .then(() => {
      console.log("loginSloganSrc-then", loginSloganSrc);
    })
    .catch((err) => {
      setloginSloganSrc("");
      console.log("loginSloganSrc-catch", loginSloganSrc, err);
    });

  const checkURLToken = () => {
    const url = window.location.href;
    console.log("URLtest", window.location.href);
    if (url && url.includes("/login") && url.includes("token")) {
      if (tokenFromSport !== url.split("token=")[1]) {
        setTokenFromSport(url.split("token=")[1]);
        console.log("URLtest2", tokenFromSport);
      }
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const fnValidateEmailId = (emailID) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(emailID);
  };

  const fnHandleResetBtn = (username) => {
    if (username === "") {
      // this.props._showSnackbar("Enter Username please", "error");
      setError({
        message: "Enter Username please",
        variant: "error",
        status: true,
      });
    }
    // else if (this.state.email === "admin@k4mobility") {
    //   this.setState({ invalidcredstate: true });
    // }
    else if (username === "Admin" || username === "admin") {
      // this.props._showSnackbar("Wrong Username");
      setError({
        message: "Wrong Username",
        variant: "error",
        status: true,
      });
    } else {
      fnForgotApi();
    }
  };

  const fnForgotApi = () => {
    callPostApi("LoginPage", "FORGOT_PASSWORD", API_ADMIN_RESET_PASSWORD, {
      username: username,
    })
      .then((res) => {
        if (res && res.status === 1) {
          if (res.data.firstLogin === 1) {
            setError({
              message: "Please login first",
              variant: "error",
              status: true,
            });
          } else {
            if (fnValidateEmailId(username)) {
              setAfterForgotPASS(true);
              setError({
                message: "Password change request sent successfully",
                variant: "success",
                status: true,
              });
            } else {
              setSecurityQuestion({
                open: true,
                list: res.data.securityAnswers,
                verifyAnswers: true,
                email: username,
              });
            }
          }
        } else {
          setError({
            message: "Email Id is invalid",
            variant: "error",
            status: true,
          });
        }
      })
      .catch((err) => {
        setFullLoader(false);
        setError({
          message: "" + err,
          variant: "error",
          status: true,
        });
      });
  };

  const _saveQuestions = (data) => {
    setFullLoader(true);
    let body = { username: username, ...data };

    callPostApi(
      "LoginPage",
      "TAG_API_SAVE_ANSWERS_LIST",
      API_SAVE_ANSWERS_LIST,
      body
    )
      .then((res) => {
        setFullLoader(false);
        if (res && res.status === 1) {
          setSecurityQuestion({
            open: false,
            list: [],
            verifyAnswers: false,
            email: "",
          });
          setError({
            message: "" + res.message,
            variant: "success",
            status: true,
          });
          if (loginResponse) fnSaveData(loginResponse, props.history, setError);
          // if (res.returnData) _saveData(res, props.history);
        } else if (res && res.status === 0) {
          setError({ message: "" + res.msg, variant: "error", status: true });
        } else {
          setError({ message: "" + res, variant: "error", status: true });
        }
      })
      .catch((er) => {
        setFullLoader(false);
        setError({ message: "" + er, variant: "error", status: true });
      });
  };

  const _resetPassword = (password) => {
    setFullLoader(true);
    let body = { username: securityQuestion.email, password };

    callPostApi("LoginPage", "RESET_PASSWORD", API_RESET_PASSWORD, body)
      .then((res) => {
        setFullLoader(false);
        if (res && res.status === 1) {
          setResetDialog(false);
          setShowForgotDialog(false);
          setUserForgotPASS(true);
          setError({
            message: "Password Changed Successfully",
            variant: "success",
            status: true,
          });
        } else if (res && res.status === 0) {
          setError({
            message: "" + res.message,
            variant: "error",
            status: true,
          });
        } else {
          setError({
            message: "" + res,
            variant: "error",
            status: true,
          });
        }
      })
      .catch((err) => {
        setFullLoader(false);
        setError({
          message: "" + err,
          variant: "error",
          status: true,
        });
      });
  };

  checkURLToken();

  React.useEffect(() => {
    if (tokenFromSport !== "") {
      fnCallLoginApi(
        "",
        "",
        setError,
        setIsLoading,
        props.history,
        setSecurityQuestion,
        setQuestionList,
        setLoginResponse,
        tokenFromSport,
        setTwoFactorAuth,
        setOTPdetails,
        setEnableResendCode,
        setloginLogoSrc,
        setloginSloganSrc,
        loadImage,
        isImage,
        loadFavIcon
        // setisLoggedInFromToken
      );
      localStorage.removeItem("logout-event");
    }
  }, [tokenFromSport]);

  const loadImage = (imageUrl) => {
    const img = new Image();
    img.src = imageUrl;
    console.log("loginLogo", imageUrl, img.src, img.height, img.width);

    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
      console.log("loginLogo", imageDimensions);
    };
    img.onerror = (err) => {
      setImageDimensions({
        height: 123,
        width: 165,
      });
      console.log("img error");
      console.error(err);
    };
  };

  React.useEffect(() => {
    loadImage(loginLogoSrc);
    loadFavIcon(faviconSrc);
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams) {
      let data = {
        type: searchParams.get("type"),
        userId: searchParams.get("userId"),
        loginAccess: searchParams.get("loginAccess"),
        username: searchParams.get("username"),
        passwordReset: searchParams.get("passwordReset"),
      };
      saveSessionData("MANAGE_LOGIN", data);
      if (getSessionData(IS_LOGGED_IN)) {
        props.history.replace("/portal");
      }
    }

    // alert("hey")
    console.log("loginLogo--", imageDimensions, loginLogoSrc);
  }, []);

  const favicon = document.getElementById("favicon");
  const loadFavIcon = (faviconSrc) => {
    console.log("favicon", favicon);
    isImage(faviconSrc)
      .then(() => {
        console.log("favIcon-then", faviconSrc);
        favicon.href = faviconSrc;
      })
      .catch((err) => {
        setFaviconSrc(`/public/favicon.ico`);
        favicon.href = `/public/favicon.ico`;
        console.log("favIcon-catch", faviconSrc, err);
      });
    console.log("favIcon-final", favicon.href);
  };

  React.useEffect(() => {
    if (
      getSessionData("TOKEN_EXPIRED") ||
      getSessionData("HANDLE_OTHER_REJECT_CASE")
    ) {
      setError({
        message: "Session expired, please login again.",
        variant: "error",
        status: true,
      });
      localStorage.clear();
      // localStorage.removeItem("TOKEN_EXPIRED");
    }
  }, []);

  return (
    <>
      <Grid container component="main" className={classes.root}>
        {twoFactorAuth ? (
          <Paper className={classes.cardRoot}>
            <div className={classes.logoContainer}>
              <div className={classes.internalLogoDiv}>
                {imageDimensions &&
                Object.keys(imageDimensions).length !== 0 ? (
                  <img
                    className={
                      getSessionData("DP_ID") == "DP-0140"?
                      classes.nexus_logo:
                      imageDimensions && imageDimensions.height != 123
                        ? imageDimensions.height === imageDimensions.width
                          ? classes.k4_logo
                          : classes.logo2
                        : classes.logo
                    }
                    src={loginLogoSrc}
                    onError={() => {
                      setloginLogoSrc(LOGO);
                    }}
                    alt="k4-logo"
                  />
                ) : null}
                {imageDimensions &&
                Object.keys(imageDimensions).length !== 0 ? (
                  loginSloganSrc !== "" ? (
                    <img
                      className={classes.logoCaption}
                      src={loginSloganSrc}
                      alt=""
                    />
                  ) : (
                    <div className={classes.logoCaption}></div>
                  )
                ) : null}
              </div>
              <Typography
                className={classes.copyright}
                style={{ display: "flex", alignItems: "center" }}
              >
                {`Captive Portal Build ${releaseNo}`}
              </Typography>
            </div>
            <div className={classes.inputRootContainer}>
              <Typography
                className={classes.loginTitle}
                style={{ marginTop: forgotPASS ? "130px" : "77px" }}
              >
                {"Two Factor Authentication"}
              </Typography>
              <Typography
                className={classes.usernameHeading}
                // variant="h6"
              >
                A 6 digit code has been sent to your email address <br />{" "}
                <b>{username}.</b> <br /> The code may take a few minutes to
                arrive.
              </Typography>
              <Typography
                className={classes.usernameHeading}
                style={{
                  marginTop: "50px",
                  fontSize: "1rem",
                  color: "#FD2D2D",
                  visibility: !isInvalidCode ? "hidden" : "",
                }}
              >
                Invalid Security Code
              </Typography>
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                {"Security Code"}
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  // marginRight: "87.23px",
                }}
                value={securityCode}
                onChange={(e) => {
                  setSecurityCode(e.target.value);
                  setInvalidCode(false);
                }}
              />
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                The Code expires in 10 minutes.
              </Typography>

              <div>
                <Grid container justify="flex-end" style={{ marginTop: "0px" }}>
                  <Grid item>
                    <div
                      style={{
                        width: "71px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "30px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        setTwoFactorAuth(false);
                        setPasswordValue("");
                        setEnableResendCode(false);
                        setInvalidCode(false);
                        setSecurityCode("");
                        setIsLoading(false);
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go Back
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        width: "131px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#384089",
                        cursor: "pointer",
                        borderRadius: "5px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        fnValidateOTP(
                          username,
                          reqOTPdetails,
                          securityCode,
                          props.history,
                          setTwoFactorAuth,
                          setFullLoader,
                          setError,
                          setInvalidCode,
                          setPasswordValue,
                          setSecurityCode,
                          setIsLoading
                        );
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                        }}
                      >
                        Verify
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid item style={{ marginTop: "30px" }}>
                  <div
                    style={{
                      width: "90px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: enableResendCode
                        ? ""
                        : "rgba(0,0,0,0.25)",
                      border: enableResendCode
                        ? "0.5px solid #384089"
                        : "0.5px solid rgba(0,0,0,0.3)",
                      borderRadius: "5px",
                      cursor: enableResendCode ? "pointer" : "not-allowed",
                      marginRight: "30px",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      if (enableResendCode) {
                        let payload = {
                          username: username,
                          session_token: reqOTPdetails.sessionToken,
                        };
                        fnGenerateOTP(
                          payload,
                          setEnableResendCode,
                          setFullLoader
                        );
                        setInvalidCode(false);
                        setSecurityCode("");
                      }
                    }}
                  >
                    <Typography
                      style={{
                        color: enableResendCode ? "#384089" : "rgba(0,0,0,0.5)",
                        // fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Resend Code
                    </Typography>
                  </div>
                </Grid>
              </div>
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                If you cannot access your email, contact Administrator.
              </Typography>
            </div>
          </Paper>
        ) : (
          <Paper className={classes.cardRoot}>
            <div className={classes.logoContainer}>
              <div className={classes.internalLogoDiv}>
                {imageDimensions &&
                Object.keys(imageDimensions).length !== 0 ? (
                  <img
                    className={
                      getSessionData("DP_ID") == "DP-0140"?
                      classes.nexus_logo:
                      imageDimensions && imageDimensions.height != 123
                        ? imageDimensions.height === imageDimensions.width
                          ? classes.k4_logo
                          : classes.logo2
                        : classes.logo
                    }
                    // src={LOGO}
                    src={loginLogoSrc}
                    onError={() => {
                      setloginLogoSrc(LOGO);
                    }}
                    alt="k4-logo"
                  />
                ) : null}
                {imageDimensions &&
                Object.keys(imageDimensions).length !== 0 ? (
                  loginSloganSrc !== "" ? (
                    <img
                      className={classes.logoCaption}
                      src={loginSloganSrc}
                      // onError={()=>{
                      //   setloginSloganSrc(LOGIN_SLOGAN)
                      // }}
                      alt=""
                    />
                  ) : (
                    <div className={classes.logoCaption}></div>
                  )
                ) : null}
              </div>
              <Typography
                className={classes.copyright}
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* {`© ${new Date().getUTCFullYear()} K4 Mobility Inc-All Rights Reserved.`} */}
                {/* Powered by
              <img 
                src={require("../../assets/images/powered-by-icon.png")}
                alt="" 
                style={{
                  // width: "20px",
                  height: "15px",
                  margin: "0px 3px"
                }}
              /> */}
                {`Captive Portal Build ${releaseNo}`}
              </Typography>
            </div>
            <div className={classes.inputRootContainer}>
              <Typography
                // variant="h5"
                className={classes.loginTitle}
                // color="primary"
                style={{ marginTop: forgotPASS ? "130px" : "77px" }}
              >
                {forgotPASS === true || afterForgotPASS === true
                  ? "Forgot Password"
                  : "Login"}
              </Typography>
              <Typography
                className={classes.usernameHeading}
                // variant="h6"
              >
                {forgotPASS === true || afterForgotPASS === true
                  ? "Enter the Username associated with your account"
                  : "Username"}
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  // marginRight: "87.23px",
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={forgotPASS ? "Username" : null}
              />
              <Typography
                className={classes.pswrdHeading}
                // variant="h6"
                hidden={forgotPASS ? true : false}
              >
                Password
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  display: forgotPASS ? "none" : "flex",
                }}
                type={showPassword ? "text" : "password"}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <div
                className={classes.submitBtnWrapper}
                style={{
                  marginTop: "72px",
                  display: forgotPASS ? "none" : "block",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    username.length === 0 ||
                    passwordValue.length === 0 ||
                    isLoading
                  }
                  onClick={() =>
                    fnHandleLogin(
                      username,
                      passwordValue,
                      setError,
                      setIsLoading,
                      props.history,
                      setSecurityQuestion,
                      setQuestionList,
                      setLoginResponse,
                      setTwoFactorAuth,
                      setOTPdetails,
                      setEnableResendCode,
                      setloginLogoSrc,
                      setloginSloganSrc,
                      loadImage,
                      isImage,
                      loadFavIcon
                    )
                  }
                >
                  Login
                </Button>
                {isLoading && (
                  <CircularProgress size={26} className={classes.loginLoader} />
                )}
              </div>
              <div
                className={classes.forgrBtnWrapper}
                onClick={() => {
                  // setShowForgotDialog(true);
                  setForgotPASS(true);
                }}
              >
                <Typography
                  className={classes.forgotPass}
                  hidden={forgotPASS ? true : false}
                >
                  Forgot Password?
                </Typography>
              </div>

              <div hidden={forgotPASS ? false : true}>
                <Grid container justify="flex-end" style={{ marginTop: "0px" }}>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? true
                        : false
                    }
                  >
                    <div
                      style={{
                        width: "71px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "30px",
                        marginTop: "15px",
                      }}
                      // onClick={this.handleClose}
                      onClick={() => {
                        setForgotPASS(false);
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go Back
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? true
                        : false
                    }
                  >
                    <div
                      style={{
                        width: "131px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#384089",
                        cursor: "pointer",
                        borderRadius: "5px",
                        marginTop: "15px",
                      }}
                      // onClick={this.onSave}
                      onClick={() => {
                        fnHandleResetBtn(username);
                        if (fnValidateEmailId(username)) {
                          setAfterForgotPASS(true);
                        }
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                        }}
                      >
                        Reset Password
                        {/* Done */}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      forgotPASS === true && afterForgotPASS === true
                        ? false
                        : true
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                        marginTop: "40px",
                        marginRight: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 300,
                          fontsize: "0.3rem",
                        }}
                      >
                        <img src={Password_Reset} alt="forgot-password-icon" />{" "}
                        Password reset request submitted. Please check your
                        email for further steps.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? false
                        : true
                    }
                  >
                    <div
                      style={{
                        width: "161px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: usernameForgotPASS ? "10px" : "100px",
                        marginTop: "20px",
                      }}
                      onClick={() => {
                        setForgotPASS(false);
                        setAfterForgotPASS(false);
                        setUserForgotPASS(false);
                        setUsername("");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go back to Login
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        )}

        {securityQuestion.open === true ? (
          <DialogSecurityQuestion
            data={securityQuestion.list}
            verifyAnsers={securityQuestion.verifyAnswers}
            visible={securityQuestion.open}
            onClose={() => {
              setSecurityQuestion({
                open: false,
                list: [],
                verifyAnswers: false,
                email: "",
              });
            }}
            onValid={() => {
              console.log("****username****", username);
              setResetDialog(true);
              setSecurityQuestion({
                open: false,
                list: [],
                verifyAnswers: false,
                email: securityQuestion.email,
              });
            }}
            onOk={_saveQuestions}
          />
        ) : null}

        {showResetDialog ? (
          <DialogResetPassword
            data={questionList}
            visible={showResetDialog}
            onClose={() => {
              setResetDialog(false);
            }}
            onOk={_resetPassword}
          />
        ) : null}

        {error ? (
          <CustomSnackbar
            variant={error.variant ? error.variant : "error"}
            message={error.message}
            open={error.status}
            onClose={() => {
              setError(null);
            }}
          />
        ) : null}
        {fullLoader && <CustomLoader showLoader={fullLoader} />}
      </Grid>

      {/* small screen view */}
      <Grid container component="main" className={classes.rootSmall}>
        {twoFactorAuth ? (
          <Paper className={classes.cardRoot}>
            <div className={classes.inputRootContainer}>
              <Typography
                className={classes.loginTitle}
                style={{ marginTop: forgotPASS ? "130px" : "77px" }}
              >
                {"Two Factor Authentication"}
              </Typography>
              <Typography
                className={classes.usernameHeading}
                // variant="h6"
              >
                A 6 digit code has been sent to your email address <br />{" "}
                <b>{username}.</b> <br /> The code may take a few minutes to
                arrive.
              </Typography>
              <Typography
                className={classes.usernameHeading}
                style={{
                  marginTop: "50px",
                  fontSize: "1rem",
                  color: "#FD2D2D",
                  visibility: !isInvalidCode ? "hidden" : "",
                }}
              >
                Invalid Security Code
              </Typography>
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                {"Security Code"}
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  // marginRight: "87.23px",
                }}
                value={securityCode}
                onChange={(e) => {
                  setSecurityCode(e.target.value);
                  setInvalidCode(false);
                }}
              />
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                The Code expires in 10 minutes.
              </Typography>

              <div>
                <Grid container justify="flex-end" style={{ marginTop: "0px" }}>
                  <Grid item>
                    <div
                      style={{
                        width: "71px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "30px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        setTwoFactorAuth(false);
                        setPasswordValue("");
                        setEnableResendCode(false);
                        setInvalidCode(false);
                        setSecurityCode("");
                        setIsLoading(false);
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go Back
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item>
                    <div
                      style={{
                        width: "131px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#384089",
                        cursor: "pointer",
                        borderRadius: "5px",
                        marginTop: "15px",
                      }}
                      onClick={() => {
                        fnValidateOTP(
                          username,
                          reqOTPdetails,
                          securityCode,
                          props.history,
                          setTwoFactorAuth,
                          setFullLoader,
                          setError,
                          setInvalidCode,
                          setPasswordValue,
                          setSecurityCode,
                          setIsLoading
                        );
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                        }}
                      >
                        Verify
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div>
                <Grid item style={{ marginTop: "30px" }}>
                  <div
                    style={{
                      width: "90px",
                      height: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: enableResendCode
                        ? ""
                        : "rgba(0,0,0,0.25)",
                      border: enableResendCode
                        ? "0.5px solid #384089"
                        : "0.5px solid rgba(0,0,0,0.3)",
                      borderRadius: "5px",
                      cursor: enableResendCode ? "pointer" : "not-allowed",
                      marginRight: "30px",
                      marginTop: "15px",
                    }}
                    onClick={() => {
                      if (enableResendCode) {
                        let payload = {
                          username: username,
                          session_token: reqOTPdetails.sessionToken,
                        };
                        fnGenerateOTP(
                          payload,
                          setEnableResendCode,
                          setFullLoader
                        );
                        setInvalidCode(false);
                        setSecurityCode("");
                      }
                    }}
                  >
                    <Typography
                      style={{
                        color: enableResendCode ? "#384089" : "rgba(0,0,0,0.5)",
                        // fontWeight: 600,
                        fontSize: "12px",
                      }}
                    >
                      Resend Code
                    </Typography>
                  </div>
                </Grid>
              </div>
              <Typography
                className={classes.usernameHeading}
                style={{ marginTop: "10px" }}
                // variant="h6"
              >
                If you cannot access your email, contact Administrator.
              </Typography>
            </div>
          </Paper>
        ) : (
          <Paper className={classes.cardRoot}>
            <div className={classes.inputRootContainer}>
              {imageDimensions && Object.keys(imageDimensions).length !== 0 ? (
                <img
                  className={classes.logoSmall}
                  style={{ marginTop: "10px" }}
                  // src={LOGO}
                  src={loginLogoSrc}
                  onError={() => {
                    setloginLogoSrc(LOGO);
                  }}
                  alt="k4-logo"
                />
              ) : null}
              <Typography
                // variant="h5"
                className={classes.loginTitle}
                // color="primary"
                //style={{ marginTop: forgotPASS ? "130px" : "77px" }}
                style={{ marginTop: forgotPASS ? "40px" : "20px" }}
              >
                {forgotPASS === true || afterForgotPASS === true
                  ? "Forgot Password"
                  : "Login"}
              </Typography>
              <Typography
                className={classes.usernameHeading}
                // variant="h6"
              >
                {forgotPASS === true || afterForgotPASS === true
                  ? "Enter the Username associated with your account"
                  : "Username"}
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  // marginRight: "87.23px",
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={forgotPASS ? "Username" : null}
              />
              <Typography
                className={classes.pswrdHeading}
                // variant="h6"
                hidden={forgotPASS ? true : false}
              >
                Password
              </Typography>
              <Input
                inputProps={{ "aria-label": "username" }}
                style={{
                  width: "323px",
                  height: "35px",
                  marginTop: "3px",
                  display: forgotPASS ? "none" : "flex",
                }}
                type={showPassword ? "text" : "password"}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <div
                className={classes.submitBtnWrapper}
                style={{
                  marginTop: "72px",
                  display: forgotPASS ? "none" : "block",
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={
                    username.length === 0 ||
                    passwordValue.length === 0 ||
                    isLoading
                  }
                  onClick={() =>
                    fnHandleLogin(
                      username,
                      passwordValue,
                      setError,
                      setIsLoading,
                      props.history,
                      setSecurityQuestion,
                      setQuestionList,
                      setLoginResponse,
                      setTwoFactorAuth,
                      setOTPdetails,
                      setEnableResendCode,
                      setloginLogoSrc,
                      setloginSloganSrc,
                      loadImage,
                      isImage,
                      loadFavIcon
                    )
                  }
                >
                  Login
                </Button>
                {isLoading && (
                  <CircularProgress size={26} className={classes.loginLoader} />
                )}
              </div>
              <div
                className={classes.forgrBtnWrapper}
                onClick={() => {
                  // setShowForgotDialog(true);
                  setForgotPASS(true);
                }}
              >
                <Typography
                  className={classes.forgotPass}
                  hidden={forgotPASS ? true : false}
                >
                  Forgot Password?
                </Typography>
              </div>

              <div hidden={forgotPASS ? false : true}>
                <Grid container justify="flex-end" style={{ marginTop: "0px" }}>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? true
                        : false
                    }
                  >
                    <div
                      style={{
                        width: "71px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: "30px",
                        marginTop: "15px",
                      }}
                      // onClick={this.handleClose}
                      onClick={() => {
                        setForgotPASS(false);
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go Back
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? true
                        : false
                    }
                  >
                    <div
                      style={{
                        width: "131px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#384089",
                        cursor: "pointer",
                        borderRadius: "5px",
                        marginTop: "15px",
                      }}
                      // onClick={this.onSave}
                      onClick={() => {
                        fnHandleResetBtn(username);
                        if (fnValidateEmailId(username)) {
                          setAfterForgotPASS(true);
                        }
                      }}
                    >
                      <Typography
                        style={{
                          color: "white",
                        }}
                      >
                        Reset Password
                        {/* Done */}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      forgotPASS === true && afterForgotPASS === true
                        ? false
                        : true
                    }
                  >
                    <div
                      style={{
                        width: "320px",
                        marginTop: "40px",
                        marginRight: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 300,
                          fontsize: "0.3rem",
                        }}
                      >
                        <img src={Password_Reset} alt="forgot-password-icon" />{" "}
                        Password reset request submitted. Please check your
                        email for further steps.
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    hidden={
                      (forgotPASS === true && afterForgotPASS === true) ||
                      usernameForgotPASS === true
                        ? false
                        : true
                    }
                  >
                    <div
                      style={{
                        width: "161px",
                        height: "33px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "#384089",
                        border: "1.5px solid #384089",
                        borderRadius: "5px",
                        cursor: "pointer",
                        marginRight: usernameForgotPASS ? "10px" : "100px",
                        marginTop: "20px",
                      }}
                      onClick={() => {
                        setForgotPASS(false);
                        setAfterForgotPASS(false);
                        setUserForgotPASS(false);
                        setUsername("");
                      }}
                    >
                      <Typography
                        style={{
                          color: "#384089",
                          fontWeight: 600,
                          fontsize: "1rem",
                        }}
                      >
                        Go back to Login
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Typography
                className={classes.copyrightSmall}
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  //backgroundColor: "yellow",
                  paddingBottom: "5px",
                  //position: "absolute",
                  //bottom: "10px",
                  //alignSelf: "flex-end",
                }}
              >
                {`Captive Portal Build ${releaseNo}`}
              </Typography>
            </div>
          </Paper>
        )}

        {securityQuestion.open === true ? (
          <DialogSecurityQuestion
            data={securityQuestion.list}
            verifyAnsers={securityQuestion.verifyAnswers}
            visible={securityQuestion.open}
            onClose={() => {
              setSecurityQuestion({
                open: false,
                list: [],
                verifyAnswers: false,
                email: "",
              });
            }}
            onValid={() => {
              console.log("****username****", username);
              setResetDialog(true);
              setSecurityQuestion({
                open: false,
                list: [],
                verifyAnswers: false,
                email: securityQuestion.email,
              });
            }}
            onOk={_saveQuestions}
          />
        ) : null}

        {showResetDialog ? (
          <DialogResetPassword
            data={questionList}
            visible={showResetDialog}
            onClose={() => {
              setResetDialog(false);
            }}
            onOk={_resetPassword}
          />
        ) : null}

        {error ? (
          <CustomSnackbar
            variant={error.variant ? error.variant : "error"}
            message={error.message}
            open={error.status}
            onClose={() => {
              setError(null);
            }}
          />
        ) : null}
        {fullLoader && <CustomLoader showLoader={fullLoader} />}
      </Grid>
    </>
  );
}

export default withRouter(Login);
