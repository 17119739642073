import { withStyles } from "@material-ui/styles";

export default withStyles((theme) => ({
  // root: {
  //   // margin: "44px 37px",
  // },
  root1: {
    // width: "100%",
    padding: "20px 15px",
    // paddingLeft: "9px",
    // paddingRight: "9px",
    // paddingBottom: "6px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    margin: "30px 0px",
    // height: "330px",
    // overflowY: "auto"
    // position: "relative",
  },
  paper: {
    minWidth: "450px",
    maxWidth: "534px",
    "@media (max-width:450px)": {
      minWidth: "300px",
      maxWidth: "400px",
    },
    // minHeight: "258px",
  },
  dialogBody: {
    padding: "10px 15px",
    height: "180px",
    overflowY: "auto",
    // textAlign: "center",
  },
  content: {
    flexGrow: 1,
    // width: `calc(100vw - 240px)`,
    height: "100vh",
    overflow: "hidden",
  },
  cardItems: {
    color: "#000000",
    fontWeight: 600,
    fontSize: "0.7rem",
  },
  value: { color: "#595959", fontWeight: 600, fontSize: "0.7rem" },
  contentShift: {
    // width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "@media (max-width:450px)": {
      display: "flex",
      justifyContent: "center",
      overflowY: "auto",
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  plansContainer: {
    background: "#FFFFFF",
    //padding: "20px 40px",
    padding: "10px 10px",
    // height: "77vh",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
    },
  },
  tableCellNoPaddingTop: {
    padding: "0 16px",
  },
  TooltipText: {
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.8)",
  },
  tableRowBody: {
    height: "35px",
  },
  tableBodyCellText: {
    fontSize: "12px",
  },
  headerTableCellText: {
    fontSize: "12px",
  },
  headerTableRow: {
    height: "40px",
  },
  headerTableCell: {
    backgroundColor: "#767BAE",
    color: "#ffffff",
    // minWidth: "135px",
    padding: "0px 5px",
    border: "1px solid #FFFFFF",
  },
  // headingBlock: {
  //   borderBottom: "1px solid #008FBDF2",
  //   marginBottom: "23px",
  //   display: "flex",
  //   alignItems: "flex-end",
  //   justifyContent: "space-between",
  // },
  heading: {
    color: "#384089",
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: "6px",
  },
  historyTextContainer: {
    background: "#B5DFEC",
    padding: "9px 18px",
  },
  historyTextContainerMobileView: {
    background: "#B5DFEC",
    padding: "6px 10px",
  },
  historyText: {
    fontSize: "12px",
  },
  tableContainer: {
    marginBottom: "20px",
  },
  scrollContainer: {
    overflowY: "auto",
    height: "92vh",
  },
  downloadIcon: {
    cursor: "pointer",
    marginBottom: "10px",
    width: "21px",
    height: "21px",
    boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 30%)",
    borderRadius: "3px",
  },
  scrollContPlanDetails: {
    paddingTop: "24px",
    paddingRight: "40px",
    paddingLeft: "40px",
    paddingBottom: "80px",
    overflowY: "auto",
    height: "92vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
      paddingRight: "10px",
      paddingLeft: "10px",
      paddingBottom: "20px",
      overflowY: "auto",
      height: "92vh",
    },
  },
  addButton: {
    border: "1px solid #384089",
    borderRadius: "5px",
    padding: "10px 16px",
    cursor: "pointer",
  },
  addButtonText: {
    color: "#384089",
    fontSize: "14px",
  },
  breadCrumbText: {
    color: "#0F4A82",
    fontSize: "14px",
  },
  usersListContainer: {
    background: "#FFFFFF",
    padding: "10px 7px",
  },
  headingBlock: {
    borderBottom: "1px solid #008FBDF2",
    marginBottom: "23px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingBlockMobile: {
    // borderBottom: "1px solid #008FBDF2",
    marginBottom: "5px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  downloadIcon: {
    cursor: "pointer",
    marginBottom: "14px",
    marginTop: "4px",
    width: "21px",
    height: "21px",
    boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 30%)",
    borderRadius: "3px",
  },
  heading: {
    color: "#384089",
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: "6px",
  },
  actionItems: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  actionIcon: {
    cursor: "pointer",
    marginLeft: "8px",
    width: "18px",
    height: "18px",
    boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 30%)",
    borderRadius: "3px",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
  },
  filterByInputContainer: {
    border: "1px solid #CECECE",
    borderRadius: "4px",
    backgroundColor: "#fff",
    padding: "0px 9px 0px 8px",
    height: "27px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "2px",
    alignSelf: "baseline",
  },
  icon: {
    cursor: "pointer",
    width: "21px",
    boxShadow: "0px 2px 5px 0px rgb(0 0 0 / 30%)",
    borderRadius: "3px",
  },
  innerHeaderTableCell: {
    backgroundColor: "#FAFCFF",
    padding: "0px 5px",
    border: "1px solid #FFFFFF",
  },
  innerHeaderTableCellText: {
    color: "#4EA7C3",
    fontWeight: 600,
    fontSize: "12px",
  },
  innerTableBodyRow: {
    background: "#FAFCFF",
    height: "40px",
  },
  innerTableBodyCell: {
    padding: "0 16px",
    borderBottom: "1px solid #FFFFFF",
    fontSize: "12px",
  },
  statusCompleted: {
    width: "8px",
    height: "8px",
    background: "#59a747",
    boxShadow: "0 0 3px #59a747",
    borderRadius: "40%",
    marginRight: "10px",
  },
  statusInProgress: {
    width: "8px",
    height: "8px",
    background: "#daa06a",
    boxShadow: "0 0 3px #daa06a",
    borderRadius: "40%",
    marginRight: "10px",
  },
  statusFailed: {
    width: "8px",
    height: "8px",
    background: "#BD0000",
    boxShadow: "0 0 3px #BD0000",
    borderRadius: "40%",
    marginRight: "10px",
  },
  addNewPlanButton: {
    width: "150px",
    marginLeft: "15px",
    padding: "5px 10px", // Adding padding around the button title
    [theme.breakpoints.down("sm")]: {
      width: "125px",
      marginLeft: "5px",
      padding: "5px 5px", // Adding padding around the button title
    },
  },
  cellNotClickOnSmallScreen: {
    [theme.breakpoints.down("sm")]: {
      cursor: "not-allowed",
    },
  },
  tabelCellLightToolTipBigScreen: {
    display: "table-cell",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      padding: "0 16px",
    },
  },
  tabelCellLightToolTipSmallScreen: {
    display: "none",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      display: "table-cell",
      padding: "0 16px",
    },
  },

  headerTableCellMetaData: {
    backgroundColor: "#767BAE",
    color: "#ffffff",
    // minWidth: "135px",
    padding: "0px 5px",
    border: "1px solid #FFFFFF",
    display: "table-cell",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // tabelCellLightToolTip: {
  //   display
  //   [theme.breakpoints.down("sm")]: {
  //     cursor: "not-allowed",
  //   },
  // },
}));
