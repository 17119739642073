export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const ADMIN_REC_ID = "ADMIN_REC_ID";
export const SHOW_QUOTE = "SHOW_QUOTE";
export const USER_DATA = "USER_DATA";
export const IS_ADMIN = "IS_ADMIN";
export const FORM_DATA = "FORM_DATA";
export const ACTIVE_PLAN_STATUS = "ACTIVE_PLAN_STATUS";

export const ONWER_LIST_DATA = "ONWER_LIST_DATA";

export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const ACTIVE_PLAN_DATA = "ACTIVE_PLAN_DATA";

export const SELECTED_TAB = "SELECTED_TAB";
export const REBOOT_PENDING = "REBOOT_PENDING";
export const SELECTED_TIME_ZONE = "TIME_ZONE";
export const MAINTAINANCE_MODE = "MAINTAINANCE_MODE";

// for common release
export const BOX_TYPE = "BOX";

export const OB = "OneBox";
export const ZB = "ZeroBox";
export const EO = "EdgeOne";
export const EOS = "EdgeOneServer";
