export const saveSessionData = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getSessionData = (key) => {
  let data = localStorage.getItem(key);
  console.log('data', data)
  return JSON.parse(data);
};

export const clearData = (key) => {
  localStorage.removeItem(key);
  return "";
};

export const clearSession = () => {
  var adminLoginDpid = localStorage.getItem('ADMIN_DP_ID') ? localStorage.getItem('ADMIN_DP_ID') : null;
  var userLoginDpid = localStorage.getItem('USER_LOGIN_DP_ID') ? localStorage.getItem('USER_LOGIN_DP_ID') : null;
  var _canUserSignUP = localStorage.getItem('SELF_SIGN_UP_ACCESS') ? localStorage.getItem('SELF_SIGN_UP_ACCESS') : null;
  localStorage.clear();
  if (adminLoginDpid) localStorage.setItem('ADMIN_DP_ID',adminLoginDpid);
  else if (userLoginDpid) localStorage.setItem('USER_LOGIN_DP_ID',userLoginDpid);
  if (_canUserSignUP) localStorage.getItem('SELF_SIGN_UP_ACCESS')
  return localStorage;
};
