import { Home as HomeIcon } from "@material-ui/icons";
import asyncComponent from "../components/AsyncComponent"
// const AddUserComponent = asyncComponent(() => import("../components/private/AddUserComponent"));
const PlanListComponent = asyncComponent(() => import("../components/private/PlanListComponent"));
const MainComponent = asyncComponent(() => import("../components/private/PortalMainComponent"));

// import AddUserComponent from "../components/private/AddUserComponent";
// import PlanListComponent from "../components/private/PlanListComponent";
// import MainComponent from "../components/private/PortalMainComponent";

const routes = [
  {
    path: "/portal",
    exact: true,
    name: "Administration Portal",
    component: MainComponent,
    //side bar
    id: 0,
    label: "Administration Portal",
    link: "/portal",
    icon: HomeIcon,
  },
  {
    path: "/plans",
    exact: true,
    name: "Plans",
    component: PlanListComponent,
    //side bar
    id: 0,
    label: "Plans",
    link: "/plans",
    icon: HomeIcon,
  },
  // {
  //   path: "/users",
  //   exact: true,
  //   name: "Users",
  //   component: AddUserComponent,
  //   //side bar
  //   id: 0,
  //   label: "Users",
  //   link: "/users",
  //   icon: HomeIcon,
  // },
];

export default routes;
